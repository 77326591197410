import { getAllKnifes } from '@/apis/paiban'
import {
  isSideMachineHole,
  isSideMachineSlot,
  toDecimal,
} from '@/util/commonFuncs'
import { getPlateKnifeDiameter } from '@/util/dealPaibanData'
import {
  dealEdgeInfoChange,
  dealFormerEdgeChange,
} from '@/util/plankCommonFuncs'
import { cloneDeep } from 'lodash'

import store from '../store'
import {
  allocationHoleSlot,
  generatorSideHoleSlot,
  keepDecimalNumBySetting, // layoutGap,
  // plankEdgeOff,
  cutKnifeRadius as layFunCutKnifeRadius,
  ncSettings,
  noRepeatPlankNum,
  specialSymbol,
  startPosition,
} from './LayoutFuncs.js'
import { labelSources } from './commonFun'
import { dealPlankHoleSlotCSide } from './dealPaibanData.js'

var roomName = ''
var roomID = ''
var ncConfig = {}
var plankWidth = ''
var plankHeight = ''

export { ncConfig }
let layoutGap, plankEdgeOff, cutKnifeRadius
// 初始的拉直器样式2‘
const initSymbol = [
  'STRENTCHTWO',
  'MORTISE',
  'CCBTSlot',
  'noSymbol',
  'lightSlot',
  'normalSlot',
  'gratingSlot',
  'BP',
  'CCSlot',
  'exSlot',
]

// 初始化要用到的NC数据
function initSetting(nc_setting) {
  layoutGap = nc_setting.panelSize.layoutGap
  plankEdgeOff = nc_setting.panelSize.plankEdgeOff
  // 暂时注释 有问题好恢复
  // cutKnifeRadius = Number(nc_setting.knife.diameter) / 2
  // if (nc_setting.glass_setting) cutKnifeRadius = 0
  cutKnifeRadius = layFunCutKnifeRadius
}

//计算酒隔板形状
function deepCopy(obj) {
  var result = Array.isArray(obj) ? [] : {}
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object') {
        result[key] = deepCopy(obj[key]) //递归复制
      } else {
        result[key] = obj[key]
      }
    }
  }
  return result
}

function calPlanks(pthick, pspacing, width, height, deepOffset, widthOffset) {
  pthick *= 1
  pspacing *= 1
  width *= 1
  height *= 1
  deepOffset *= 1
  widthOffset *= 1
  function isInteger(obj) {
    return obj % 1 === 0
  }
  var cellNum, slotNum
  var slotDepth = width / 2 + deepOffset

  var polygon = [
    { x: 0, y: 0 },
    { x: width, y: 0 },

    { x: width, y: height },

    { x: 0, y: height },
  ]
  var kk = height / (pspacing + pthick)
  kk = Math.ceil(kk)

  for (var i = 1; i <= kk; i++) {
    var mk = i
    var mb = (height - mk * pspacing) / pthick
    var delta = Math.abs(mk - mb)
    if (delta === 1 && mb >= 0) {
      cellNum = mk
      slotNum = cellNum - 1
      var mslot_0 = []
      if (cellNum > 1) {
        //首尾能盖住别的板
        if (mb == slotNum + 2) {
          mslot_0 = [
            { x: width, y: pthick + pspacing - widthOffset },
            { x: width - slotDepth, y: pthick + pspacing - widthOffset },
            {
              x: width - slotDepth,
              y: pthick + pspacing + pthick + widthOffset,
            },
            { x: width, y: pthick + pspacing + pthick + widthOffset },
          ]
        }
        //                    //首尾被别的板夹住
        else if (mb == slotNum) {
          mslot_0 = [
            { x: width, y: pspacing - widthOffset },
            { x: width - slotDepth, y: pspacing - widthOffset },
            { x: width - slotDepth, y: pspacing + pthick + widthOffset },
            { x: width, y: pspacing + pthick + widthOffset },
          ]
        }
        var arr = deepCopy(mslot_0)
        for (var aa = 1; aa < slotNum; aa++) {
          var mslot_aa = []
          for (var idx = 0; idx < mslot_0.length; idx++) {
            var o = mslot_0[idx]
            var str = JSON.stringify(o)
            // var spt = libGG.parseJson(str);
            var spt = JSON.parse(str)
            spt.y += (pspacing + pthick) * aa
            mslot_aa.push(spt)
          }

          arr = arr.ggConcat(mslot_aa)
        }

        for (var i = 0; i < arr.length; i++) {
          var pt = arr[i]
          polygon.splice(2 + i, 0, pt)
        }
      }
    }
  }

  return polygon
}

//排版之前数据预处理
function preProcess(
  pack,
  projectInfo,
  roomInfo,
  isAutoRoll,
  throughTowSideToCut
) {
  if (roomInfo.hasOwnProperty('projectInfo')) {
    projectInfo = roomInfo.projectInfo
    roomInfo['customer_name'] = projectInfo['customer_name']
  }
  var packObj = {}
  if (typeof pack === 'string') {
    packObj = jjFromUnit(pack)
  } else if (typeof pack === 'object') {
    packObj = pack
  }
  var planks = []
  var extras = []
  if (!packObj) {
    return []
  }
  iGatherPlanks(packObj.root, planks, extras)
  // 获取房间名称
  roomName = roomInfo['name']
  roomID = roomInfo['id']
  // 生成板件列表
  var items = []
  for (var i in planks) {
    var p = planks[i]

    var it = TBFZ.classifyPlank(
      p,
      roomName,
      null,
      roomID,
      isAutoRoll,
      throughTowSideToCut,
      xyReverse
    )
    if (it === undefined) {
      continue
    }

    items.push(it)
  }

  // 特殊处理部分
  extras.forEach(function (extra) {
    if (extra.type === 'WLineBottom') {
      var isCut = extra['desc']['BFZ']['isCut']
      var isPolygon = extra['desc']['BFZ']['isPolygon']
      if (!isCut && isPolygon) {
        var gid = extra['gid']
        var loc = extra['loc'] ?? extra['wardrobeName']
        var name = extra['name']
        var plankID = extra['desc']['BFZ']['plankID']
        var partName = extra['partName']
        var size = extra['desc']['BFZ']['size']

        var thick = extra['desc']['BFZ']['thick']
        var matCode = extra['desc']['BFZ']['matCode']
        var texture = extra['desc']['BFZ']['texture']
        var matRotatable = false
        var plankNum = extra['desc']['BFZ']['plankNum']
        var oriPlankNum = extra['desc']['BFZ']['oriPlankNum']
        var edgeThick = extra['desc']['BFZ']['edgeThick']
        var typeName = extra.type

        if (partName.indexOf('踢脚线') != -1) {
          size.height *= 1
          // size.height += 100;//踢脚线少了100，暂未向上追踪原因
          size.height -= edgeThick * 2
          size.width -= edgeThick * 2
        }

        var obj = TBFZ.classifyPlank(
          extra,
          roomName,
          null,
          roomID,
          isAutoRoll,
          throughTowSideToCut,
          xyReverse
        )
        items.push({
          gid: gid,
          loc: loc,
          name: name,
          role: 'Plank',
          plankID: plankID,
          partName: partName,
          rect: Qt.rect(0, 0, size.width, size.height),
          oRect: Qt.rect(
            0,
            0,
            size.width + 2 * edgeThick,
            size.height + 2 * edgeThick
          ),
          realRect: Qt.rect(0, 0, size.width, size.height),
          fullSize: Qt.size(
            size.width + 2 * edgeThick,
            size.height + 2 * edgeThick
          ),
          thick: jjFixed2(thick),
          texture: obj.texture, //genSafeTexture(textureMap, texture),
          matRotatable: matRotatable,
          roomName: roomName,
          plankNum: plankNum,
          matCode: matCode,
          oriPlankNum: oriPlankNum,
          edgeInfo:
            '←' +
            edgeThick +
            '↓' +
            edgeThick +
            '→' +
            edgeThick +
            '↑' +
            edgeThick,
          sholeInfo: '',
          sslotInfo: '',
          holes: [],
          sholes: [],
          slots: [],
          sslots: [],
          typeName: typeName,
          item: extra,
        })
      }
    } else if (
      extra.type === 'SingleDoor' &&
      extra.hasOwnProperty('desc') &&
      extra.desc.hasOwnProperty('BFZ') &&
      extra.desc.BFZ.hasOwnProperty('style')
    ) {
      var isDoorPlank = extra['desc']['BFZ']['style'] === '板式门'
      if (isDoorPlank) {
        var gid = extra['gid']
        var loc = extra['loc'] ?? extra['wardrobeName']
        var name = extra['name']
        var plankID = extra['desc']['BFZ']['plankID']
        var partName = extra['partName']
        var size = extra['desc']['BFZ']['size']
        var thick = extra['desc']['BFZ']['thick']
        var matCode = extra['desc']['BFZ']['matCode']
        var texture = extra['desc']['BFZ']['texture']
        var plankNum = extra['desc']['BFZ']['plankNum']
        var oriPlankNum = extra['desc']['BFZ']['oriPlankNum']
        var edgeThick = extra['desc']['BFZ']['edgeThick']
        var texDir = extra['desc']['BFZ']['texDir']
        var openDir = extra['desc']['BFZ']['openDir']
        var typeName = extra.type
        var matRotatable = extra['matRotatable']

        var obj = TBFZ.classifyPlank(
          extra,
          roomName,
          null,
          roomID,
          isAutoRoll,
          throughTowSideToCut,
          xyReverse
        )

        var textureId = extra['desc']['BFZ']['textureId']
        var nick = ''
        if (textureId) {
          nick = libTexture.getNameByUidAndId(textureId)
        }

        if (size) {
          var objx = {
            gid: gid,
            loc: loc,
            name: name,
            role: 'DoorPlank',
            plankID: plankID,
            partName: partName,
            rect: Qt.rect(0, 0, size.width, size.height),
            oRect: Qt.rect(
              0,
              0,
              size.width + 2 * edgeThick,
              size.height + 2 * edgeThick
            ),
            realRect: Qt.rect(0, 0, size.width, size.height),
            fullSize: Qt.size(
              size.width + 2 * edgeThick,
              size.height + 2 * edgeThick
            ),
            thick: jjFixed2(thick),
            texture: nick,
            matRotatable: matRotatable,
            roomName: roomName,
            plankNum: plankNum,
            matCode: matCode,
            oriPlankNum: oriPlankNum,
            edgeInfo:
              '←' +
              edgeThick +
              '↓' +
              edgeThick +
              '→' +
              edgeThick +
              '↑' +
              edgeThick,
            sholeInfo: '',
            sslotInfo: '',
            holes: obj['holes'],
            sholes: [],
            slots: obj['slots'],
            sslots: [],
            texDir: texDir,
            openDir: openDir,
            typeName: typeName,
            item: extra,
            //                        "path":obj.path,
            //                        "oPath":obj.oPath
          }

          if (
            obj.hasOwnProperty('oPath') &&
            obj.oPath &&
            obj.oPath.length > 0
          ) {
            objx.path = obj.path
            objx.oPath = obj.oPath
          }
          items.push(objx)
        }
      }
    }
  })

  // 附加额外的信息
  items = matchExtraInfo(items, projectInfo, roomInfo)
  return items
}

function genSafeTexture(textureMap, key) {
  if (textureMap && textureMap.hasOwnProperty(key)) {
    return textureMap[key].slice(0, -4)
  }
  return 'NOCOLOR'
}

function iGatherPlanks(pack, planks, extras) {
  var holes = []
  var slots = []
  var handleSlopes = []
  var flag = true
  jjForEachFields(pack, ['children', 'subitems'], function (c) {
    if (c.isInvisible) return
    if (c.type === 'SingleDoor') {
      flag = false
      extras.push(c)
    }
    if (c.type === 'WLineBottom') extras.push(c) //主要处理异形踢脚线

    if (c.type === 'Plank') {
      if (c.hasOwnProperty('parent') && c.parent.type != 'SingleDoor') {
        planks.push(c)
      }
    }
    if (c.type === 'Hole') {
      if (holes.indexOf(c) < 0) {
        holes.push(c)
      }
    }
    if (c.type === 'Slot') {
      if (slots.indexOf(c) < 0) {
        slots.push(c)
      }
    }
  })

  holes.forEach(function (hole) {
    if (hole.referItem !== hole.parent) {
      if (hole.referItem === undefined) {
        xWarnning$('未知的孔', hole.parent.name)
      } else {
        if (hole.referItem.children.indexOf(hole) == -1) {
          hole.referItem.children.push(hole)
        }
      }
    }
  })

  slots.forEach(function (slot) {
    if (slot.referItem !== slot.parent) {
      if (slot.referItem.children.indexOf(slot) == -1) {
        slot.referItem.children.push(slot)
      }
    }
  })

  handleSlopes.forEach(function (slot) {
    if (slot.referItem !== slot.parent) {
      if (slot.referItem.children.indexOf(slot) == -1) {
        slot.referItem.children.push(slot)
      }
    }
  })
}

function matchExtraInfo(planks, projectInfo, roomInfo) {
  var remark = projectInfo['remark']
  var orderNo = projectInfo['order_code']
  var address = projectInfo['buyer_address']
  var customer_name = projectInfo['customer_name']

  var userName = projectInfo['name']
  var createTime = projectInfo['create_time']
  if (projectInfo === undefined || Object.keys(projectInfo).length == 0) {
    address = roomInfo['address']
    customer_name = roomInfo['customer_name']

    orderNo = roomInfo['orderCode']
  }

  //获取房间名称
  roomName = roomInfo['name']

  function fetchPlateGGID(plates, uniqueId) {
    for (var i in plates) {
      var plate = plates[i]
      if (plate['unique_id'] === uniqueId) {
        return plate['ggid']
      }
    }
    return ''
  }
  function fetchPlateGGIDST(plates, uniqueId) {
    for (var i in plates) {
      var plate = plates[i]
      if (plate['unique_id'] === uniqueId) {
        return plate['ggid_short']
      }
    }
    return ''
  }

  function fetchInfo(plates, uniqueId, keys) {
    var retObj = {}
    if (keys && keys.length) {
      for (var i in plates) {
        var plate = plates[i]
        if (plate['unique_id'] === uniqueId) {
          for (var index in keys) {
            var key = keys[index]
            retObj[key] = plate[key]
          }
        }
      }
    }
    return retObj
  }

  function fetchPlatesMap(projectInfo, ri) {
    var map = {}

    var id = ri['id']
    var name = ri['name']

    var rooms = projectInfo['room']

    for (var j in rooms) {
      var room = rooms[j]
      if (room['id'] === id) {
        if (!room['plate']) {
          room['plate'] = []
        }
        if (!room['door']) {
          room['door'] = []
        }
        map[name] = room['plate'].ggConcat(room['door'])
        break
      }
    }

    return map
  }
  var platesMap = fetchPlatesMap(projectInfo, roomInfo)

  for (var i in planks) {
    var part = planks[i]
    var item = part['item']
    if (item) {
      var keys = ['ggid', 'ggid_short', 'color']
      var values = fetchInfo(platesMap[roomName], item['uniqueId'], keys)
      var ggid = values['ggid']
      var ggid_short = values['ggid_short']
      var color = values['color']
      part['ggid'] = ggid
      part['ggid_short'] = ggid_short
      part['remark'] = remark
      part['address'] = address

      part['customer_name'] = customer_name

      part['orderNo'] = orderNo
      part['userName'] = userName
      part['createTime'] = createTime
      part['texDir'] = item['texDir']
      if (!part['texture'] || part['texture'] === 'NOCOLOR') {
        part['texture'] = color
      }
    }
  }

  return planks
}

//排版之后数据处理
// key为排版接口返回的板件分类键名
// retmap为排版接口返回的板件分类键值
// oriData为预处理之后的数据
// colorRes颜色列表
// 后面的参数暂时不用管
async function partialProcess(
  isPerLoad,
  key,
  retMap,
  oriData,
  colorRes,
  isMeticulous,
  bigPlankWidth,
  bigPlankHeight,
  mStockNum
) {
  var result = {}
  var parts = retMap['data']

  var thick = String(key).split(':')[2]
  var texture = String(key).split(':')[0]
  var matCode = String(key).split(':')[1]

  var dataLst = []
  //处理正常排版板件
  for (var i in parts) {
    var tmpPart = parts[i]
    var tmpData = {}
    for (var ind in tmpPart) {
      if (isPerLoad) {
        await new Promise((resolve) => setTimeout(resolve))
      }
      var dstPart = tmpPart[ind]
      await comparePart(
        thick,
        texture,
        matCode,
        dstPart,
        oriData,
        tmpData,
        isMeticulous,
        bigPlankWidth,
        bigPlankHeight,
        mStockNum,
        colorRes
      )
    }
    dataLst.push(tmpData)
  }
  result['data'] = dataLst
  result['rollCount'] = retMap['rollCount']
  result['used_rate'] = retMap['used_rate']
  result['plank_remarks'] = retMap['plank_remarks']

  //处理挖洞
  if (retMap.hasOwnProperty('holes')) {
    var holeinfo = {}
    var holes = retMap['holes']

    for (var k in holes) {
      var holePart = holes[k]
      var hole = holePart['holes']
      var stockNum = holePart['stockNum']
      var stockKey = texture + ':' + matCode + ':' + thick + ':' + stockNum

      if (!holeinfo.hasOwnProperty(stockKey)) {
        holeinfo[stockKey] = {
          thick: thick,
          matCode: matCode,
          texture: texture,
          stockNum: stockNum,
          stockKey: stockKey,
          parts: [],
        }
      }

      hole.forEach(function (part) {
        var x = part['x']
        var y = part['y']
        var width = part['width']
        var height = part['height']

        // 调整排版原点
        if (startPosition === '左上角') {
        } else if (startPosition === '左下角') {
          part['y'] = plankHeight - (y + height)
        } else if (startPosition === '右下角') {
          part['x'] = plankWidth - (x + width)
          part['y'] = plankHeight - (y + height)
        } else if (startPosition === '右上角') {
          part['x'] = plankWidth - (x + width)
        }
      })

      holeinfo[stockKey]['holes'] = hole
    }
    result['holeInfo'] = holeinfo
  }
  return result
}

export async function afterProcess(
  oriResult,
  oriData,
  config,
  isPerLoad,
  colorRes
) {
  // 初始化一些数据
  initSetting(config)
  ncConfig = config
  // layoutGap = config.panelSize.layoutGap
  // plankEdgeOff = config.panelSize.plankEdgeOff
  var container = {}

  for (var key in oriResult) {
    container[key] = {}
    var retMap = oriResult[key]
    // 多材质下料刀可能有不同的刀径
    const diameter = +getPlateKnifeDiameter(key, config)
    // 满足使用锯切时 优先用锯切刀
    cutKnifeRadius = oriData.every((item) => item.isUseSaw)
      ? layFunCutKnifeRadius
      : diameter / 2
    container[key] = await partialProcess(
      isPerLoad,
      key,
      retMap,
      oriData,
      colorRes
    )
    // 将孔槽按照比例进行分配
    if (
      store.state.isResetPaibanAllocation &&
      !store.state.paibanInfo?.isSpecialShape
    ) {
      container[key].data.forEach((it) => {
        Object.values(it).forEach((plank) => {
          allocationHoleSlot(plank)
        })
      })
    }
  }
  return container
}

//处理单个板件
function comparePart(
  thick,
  texture,
  matCode,
  dstPart,
  originData,
  container,
  isMeticulous,
  bigPlankWidth,
  bigPlankHeight,
  mStockNum,
  colorRes
) {
  for (var j in originData) {
    var srcPart = originData[j]
    srcPart = cloneDeep(srcPart)
    // 添加对应颜色
    srcPart.colorRes = {}
    if (JSON.stringify(colorRes) == '{}') {
      srcPart.colorRes = null
    } else {
      for (let item of labelSources) {
        if (colorRes[item]) {
          srcPart.colorRes[item] =
            colorRes[item][(srcPart[item] ?? '')?.toString()]
        } else {
          srcPart.colorRes[item] = 'rgb(255, 255, 255)'
        }
      }
    }
    // 如果是高光板，需要给matCode加上高光标识,在开料清单中matCode不再跟随高光属性改变而改变
    if (srcPart['is_high_gloss_plank']) {
      srcPart['matCode'] = srcPart['matCode'].includes('高光_')
        ? srcPart['matCode']
        : `高光_${srcPart['matCode']}`
    }
    if (
      Math.abs(thick - parseFloat(srcPart['thick'])) < 0.001 &&
      texture == srcPart['texture'] &&
      matCode == srcPart['matCode']
    ) {
      var rect = srcPart['rect']
      srcPart['rect'] = {
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
      }
      if (dstPart['index'] == srcPart['rectInd']) {
        let flag = (function (a, b) {
          var aProps = Object.keys(a)
          var bProps = Object.keys(b)

          if (aProps.length != bProps.length) {
            return false
          }

          for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i]
            var propA = a[propName]
            var propB = b[propName]
            if (propA != propB) {
              return false
            }
          }
          return true
        })(dstPart['rect'], srcPart['expandRect'] ?? srcPart['rect'])

        if (flag) {
          dstPart['rect'] = srcPart['rect']
          var stockNum = dstPart['stockNum']
          if (mStockNum) {
            stockNum = mStockNum
          }

          var surplusInfo = dstPart['surplusInfo']
          var otherPlate = dstPart['otherPlate']
          if (otherPlate && Object.keys(otherPlate).length > 0) {
            surplusInfo = otherPlate
            surplusInfo.isNotSurplus = true
            if (store.state.specialPlankParams.length) {
              store.state.specialPlankParams.forEach((item) => {
                if (
                  item.matCode == srcPart.matCode &&
                  item.color == srcPart.texture &&
                  item.thick == srcPart.thick &&
                  item.height == otherPlate.height &&
                  item.width == otherPlate.width
                ) {
                  otherPlate.trim_edge = item.trim_edge
                  otherPlate.id = item.id
                }
              })
            }
          }
          if (surplusInfo) {
            var surplusId = surplusInfo['id']
          }
          var stockKey = texture + ':' + matCode + ':' + thick + ':' + stockNum
          var curKey = texture + ':' + matCode + ':' + thick

          //处理大板的宽高
          plankWidth =
            store.state.selectStandardPlank &&
            Object.keys(store.state.selectStandardPlank).length
              ? store.state.selectStandardPlank.plankWidth
              : srcPart['plankWidth']
          plankHeight =
            store.state.selectStandardPlank &&
            Object.keys(store.state.selectStandardPlank).length
              ? store.state.selectStandardPlank.plankHeight
              : srcPart['plankHeight']
          if (surplusInfo && Object.keys(surplusInfo).length > 0) {
            if (surplusInfo['width'] != undefined) {
              bigPlankWidth = surplusInfo['width']
              bigPlankHeight = surplusInfo['height']
            } else {
              var surplusWidth = 0
              var surplusHeight = 0
              if (surplusInfo.hasOwnProperty('plankWidth')) {
                surplusWidth = surplusInfo['plankWidth']
                surplusHeight = surplusInfo['plankHeight']
              } else {
                surplusWidth = surplusInfo['x5']
                surplusHeight = surplusInfo['y3']
              }
              surplusInfo['width'] = surplusWidth
              surplusInfo['height'] = surplusHeight
              bigPlankWidth = surplusWidth
              bigPlankHeight = surplusHeight
            }
          }
          var isSurplus = false
          var surplusMargin = ncConfig.panelSize.surplusMargin
          if (surplusInfo && surplusInfo.hasOwnProperty('isSurplus')) {
            isSurplus = surplusInfo['isSurplus']
          }

          var rotatePart = {}

          var isRotate = dstPart['rotate']
          if (isRotate) {
            switch (isRotate) {
              case 180:
                rotatePart = rotate180Part(srcPart)
                break
              case 270:
                rotatePart = rotate(rotate180Part(srcPart))
                break
              default:
                rotatePart = rotate(srcPart)
                break
            }
          }

          dstPart['rect'] = isRotate
            ? rotatePart['rect']
            : srcPart['rect']
            ? srcPart['rect']
            : ''
          dstPart['oRect'] = isRotate
            ? rotatePart['oRect']
            : srcPart['oRect']
            ? srcPart['oRect']
            : ''

          dstPart['rect']['width'] = toDecimal(
            dstPart['rect']['width'],
            store.state.ncSetting.decimal
          )
          dstPart['rect']['height'] = toDecimal(
            dstPart['rect']['height'],
            store.state.ncSetting.decimal
          )
          dstPart['oRect']['width'] = toDecimal(
            dstPart['oRect']['width'],
            store.state.ncSetting.decimal
          )
          dstPart['oRect']['height'] = toDecimal(
            dstPart['oRect']['height'],
            store.state.ncSetting.decimal
          )
          var startX = dstPart['startX']
          var startY = dstPart['startY']
          var rectWidth = dstPart['rect']['width']
          var rectHeight = dstPart['rect']['height']

          var tmpValue =
            layoutGap -
            ((otherPlate && Object.keys(otherPlate).length
              ? otherPlate.trim_edge
              : store.state.selectStandardPlank.plankEdgeOff) -
              cutKnifeRadius) *
              2
          var tmpValue2 = 0

          if (!isMeticulous) {
            if (isSurplus && !surplusMargin) {
              tmpValue = -(otherPlate && Object.keys(otherPlate).length
                ? otherPlate.trim_edge
                : store.state.selectStandardPlank.plankEdgeOff)
              tmpValue2 = -(otherPlate && Object.keys(otherPlate).length
                ? otherPlate.trim_edge
                : store.state.selectStandardPlank.plankEdgeOff)
            }
            tmpValue = -cutKnifeRadius
            tmpValue2 = -cutKnifeRadius
            // if(isYunLayout) {
            // } else if (!isTestLayoutPlank) {
            //     tmpValue = layoutGap;
            // }
          }

          if (!bigPlankWidth || !bigPlankHeight) {
            bigPlankWidth = plankWidth
            bigPlankHeight = plankHeight
          }

          // dstPart["startY"] = keepDecimalNumBySetting(ncConfig, dstPart["startY"])
          // dstPart["startX"] = keepDecimalNumBySetting(ncConfig, dstPart["startX"])

          // 柜门历史进入，从存入的板件中取字段
          let guimenKeys = store.state.guimenDataKeys
          if (
            sessionStorage.getItem('thinkerx_material') &&
            sessionStorage.getItem('isHistory')
          ) {
            guimenKeys = Object.keys(srcPart)
          }
          const guimenIgnoreKeys = ['oRect', 'rect', 'startX', 'startY']
          for (let ii = 0; ii < guimenKeys.length; ++ii) {
            let guimenKey = guimenKeys[ii]
            if (!guimenIgnoreKeys.includes(guimenKey)) {
              dstPart[guimenKey] = srcPart[guimenKey]
            }
          }
          // for(let ii = 0; ii < store.state.guimenDataKeys.length; ++ii){
          //   let guimenKey = store.state.guimenDataKeys[ii]
          //   if (guimenKey != 'oRect' && guimenKey != 'rect') {
          //     dstPart[guimenKey] = srcPart[guimenKey]
          //   }
          // }

          if (srcPart['manualTrunOver'] != undefined) {
            dstPart['manualTrunOver'] = srcPart['manualTrunOver']
          }
          if (srcPart['changedTexDir'] != undefined) {
            dstPart['changedTexDir'] = srcPart['changedTexDir']
          }
          if (srcPart['preRect'] != undefined) {
            dstPart['preRect'] = srcPart['preRect']
          }

          if (srcPart['holeError']) {
            dstPart['holeError'] = srcPart['holeError']
          }
          if (srcPart['srcTexDir']) {
            dstPart['srcTexDir'] = srcPart['srcTexDir']
          }
          dstPart['oriPartUniqueId'] =
            srcPart['oriPartUniqueId'] ?? srcPart['partUniqueId']
          dstPart['texDir'] = srcPart['texDir']
          dstPart['openDir'] = srcPart['openDir']
          dstPart['gid'] = srcPart['gid']
          dstPart['loc'] = srcPart['loc'] ?? srcPart['wardrobeName']
          dstPart['name'] = srcPart['name']
          dstPart['plankID'] = srcPart['plankID']
          dstPart['partName'] = srcPart['partName']
          dstPart['item'] = srcPart['item']
          dstPart['curveHoles'] = isRotate
            ? rotatePart['curveHoles']
            : srcPart['curveHoles']
          dstPart['millInfo'] = isRotate
            ? rotatePart['millInfo']
            : srcPart['millInfo']
          dstPart['oriCurveHoles'] = isRotate
            ? rotatePart['oriCurveHoles']
            : srcPart['oriCurveHoles']
          dstPart['plankType'] = srcPart['plankType']
          dstPart['path'] = isRotate ? rotatePart['path'] : srcPart['path']
          dstPart['oPath'] = isRotate ? rotatePart['oPath'] : srcPart['oPath']
          dstPart['realCurve'] = isRotate
            ? rotatePart['realCurve']
            : srcPart['realCurve']
          dstPart['realCurveEx'] = isRotate
            ? rotatePart['realCurveEx']
            : srcPart['realCurveEx']
          dstPart['cutCurve'] = isRotate
            ? rotatePart['cutCurve']
            : srcPart['cutCurve']
          dstPart['cutCurveEx'] = isRotate
            ? rotatePart['cutCurveEx']
            : srcPart['cutCurveEx']
          dstPart['thick'] = srcPart['thick']
          dstPart['texture'] = srcPart['texture']
          dstPart['renderUrl'] = srcPart['renderUrl']
          dstPart['from'] = srcPart['from']
          dstPart['matRotatable'] = srcPart['matRotatable']
          dstPart['roomName'] = srcPart['roomName']
          dstPart['roomID'] = srcPart['roomID']
          dstPart['designer'] = srcPart['designer']
          dstPart['holes'] = isRotate ? rotatePart['holes'] : srcPart['holes']
          dstPart['hingeInfo'] = srcPart['hingeInfo']
            ? srcPart['hingeInfo']
            : []
          dstPart['slots'] = isRotate ? rotatePart['slots'] : srcPart['slots']
          dstPart['handleSlopes'] = isRotate
            ? rotatePart['handleSlopes']
            : srcPart['handleSlopes']
          dstPart['sholes'] = isRotate
            ? rotatePart['sholes']
            : srcPart['sholes']
          dstPart['sslots'] = isRotate
            ? rotatePart['sslots']
            : srcPart['sslots']
          dstPart['plankNum'] = srcPart['plankNum']
          dstPart['matCode'] = srcPart['matCode']
          dstPart['oriPlankNum'] = srcPart['oriPlankNum']
          dstPart['hsInfo'] = srcPart['hsInfo'] ? srcPart['hsInfo'] : ''
          dstPart['handle'] = srcPart['handle'] ? srcPart['handle'] : null
          dstPart['partsChildren'] = srcPart['partsChildren']
            ? srcPart['partsChildren']
            : null
          dstPart['remarks'] = srcPart['remarks']
          // 门窗过备注
          dstPart['remark1'] = srcPart['remark1'] ? srcPart['remark1'] : ''
          dstPart['remark2'] = srcPart['remark2'] ? srcPart['remark2'] : ''
          dstPart['remark3'] = srcPart['remark3'] ? srcPart['remark3'] : ''
          dstPart['installNum'] = srcPart['installNum']
            ? srcPart['installNum']
            : ''
          dstPart['edge_length_info'] = srcPart['edge_length_info']
          //正面没有孔槽表示  用于标签中不生成条码
          dstPart['hasOriPlankNum'] = hasRevPlankHoleOrSlot(srcPart, 1)
          //判断是否有反面条码
          if (hasRevPlankHoleOrSlot(srcPart, -1)) {
            dstPart['oriPlankNumF'] = srcPart['plankNum'] + 'K'
            dstPart['simplePlankNumF'] = srcPart['simplePlankNum'] + 'K'
          }
          dstPart['plankNumType2'] = srcPart['plankNumType2']
          dstPart['realRect'] = isRotate
            ? rotatePart['realRect']
            : srcPart['realRect']
          dstPart['fullSize'] = srcPart['fullSize']
          dstPart['edgeInfo'] = isRotate
            ? rotatePart['edgeInfo']
            : srcPart['edgeInfo']
          dstPart['sholeInfo'] = isRotate
            ? rotatePart['sholeInfo']
            : srcPart['sholeInfo']
          dstPart['sslotInfo'] = isRotate
            ? rotatePart['sslotInfo']
            : srcPart['sslotInfo']
          dstPart['ggid'] = srcPart['ggid']
          dstPart['ggid_short'] = srcPart['ggid_short']
          dstPart['remark'] = srcPart['remark']
          dstPart['remarks'] = srcPart['remarks']
          // dstPart['customer_name'] =
          //   srcPart['customer'] ?? srcPart['customer_name']
          // 这个地方是为了老板良才这样处理的
          dstPart['customer_name'] = srcPart['customer_name']
          dstPart['address'] = srcPart['address']
          dstPart['orderNo'] = srcPart['orderNo']
          dstPart['orderId'] = srcPart['orderId']
          dstPart['needRoll'] = srcPart['needRoll']
          dstPart['oriHoles'] = srcPart.hasOwnProperty('oriHoles')
            ? srcPart['oriHoles']
            : srcPart['holes']
          dstPart['oriSlots'] = srcPart.hasOwnProperty('oriSlots')
            ? srcPart['oriSlots']
            : srcPart['slots']
          dstPart['oriSholes'] = srcPart.hasOwnProperty('oriSholes')
            ? srcPart['oriSholes']
            : srcPart['sholes']
          dstPart['oriSslots'] = srcPart.hasOwnProperty('oriSslots')
            ? srcPart['oriSslots']
            : srcPart['sslots']
          dstPart['oriCurveHoles'] = srcPart.hasOwnProperty('oriCurveHoles')
            ? srcPart['oriCurveHoles']
            : srcPart['curveHoles']
          dstPart['stockKey'] = stockKey
          dstPart['labelId'] = srcPart['labelId']
          dstPart['plankWidth'] = bigPlankWidth
          dstPart['plankHeight'] = bigPlankHeight
          dstPart['drawerStyle'] = srcPart['drawerStyle']
          dstPart['depth'] = srcPart['depth']
          dstPart['is_high_gloss_plank'] = srcPart['is_high_gloss_plank']
          dstPart['high_gloss_side'] = srcPart['high_gloss_side']
          dstPart['model_front_edge'] = isRotate
            ? rotatePart['model_front_edge']
            : srcPart['model_front_edge']
          dstPart['lightEdgeInfo'] = isRotate
            ? rotatePart['lightEdgeInfo']
            : srcPart['lightEdgeInfo']
          //简易标签条码显示
          dstPart['simplePlankNum'] = srcPart['simplePlankNum']
          dstPart['quantity'] = 1
          dstPart['specWidth'] = +(srcPart['specWidth'] ?? 0)
          dstPart['specHeight'] = +(srcPart['specHeight'] ?? 0)
          dstPart['checklistID'] = srcPart['checklistID']
          dstPart['extraParts'] = srcPart['extraParts']
          dstPart['isFrontPlank'] = srcPart['isFrontPlank']
          dstPart['doorOpenSide'] = isRotate
            ? rotatePart['doorOpenSide']
            : srcPart['doorOpenSide']
          dstPart['specialFlag'] = srcPart['specialFlag']
          if (srcPart['manualAdd']) {
            dstPart['manualAdd'] = srcPart['manualAdd']
          }
          if (srcPart['isManual']) {
            dstPart['isManual'] = srcPart['isManual']
          }
          if (srcPart['type']) {
            dstPart['type'] = srcPart['type']
          }
          if (srcPart['specialType']) {
            dstPart['specialType'] = srcPart['specialType']
          }
          if (srcPart['surplusIndex']) {
            dstPart['surplusIndex'] = srcPart['surplusIndex']
          }
          if (srcPart['surplusPath']) {
            dstPart['surplusPath'] = srcPart['surplusPath']
          }
          if (srcPart['isSave']) {
            dstPart['isSave'] = srcPart['isSave']
          }
          if (srcPart['surplus_id']) {
            dstPart['surplus_id'] = srcPart['surplus_id']
          }
          dstPart['partUniqueId'] = srcPart['partUniqueId']
          dstPart['outItemPanelId'] = srcPart['outItemPanelId']
          dstPart['plank_remarks'] = srcPart['plank_remarks']
          dstPart['process_label'] = srcPart['process_label']
          dstPart['startY'] = Number(
            Number(dstPart['startY']).toFixed(store.state.ncSetting.decimal)
          )
          // 给板件上增加连接方式字段
          dstPart['connection_types'] = srcPart['connection_types']
          dstPart['_isBujian'] = srcPart['_isBujian']
          dstPart['_isAwaitStorePlank'] = srcPart['_isAwaitStorePlank']
          dstPart['_awaitStoreId'] = srcPart['_awaitStoreId']
          dstPart['_bujianStoreId'] = srcPart['_bujianStoreId']
          dstPart['_isNewTableBujian'] = srcPart['_isNewTableBujian']
          dstPart['colorRes'] = srcPart['colorRes']
          dstPart['isUseSaw'] = srcPart['isUseSaw']
          dstPart['isRotateFrontEdge'] = srcPart['isRotateFrontEdge']
          var slots = dstPart['slots']

          const isNeedRotate =
            dstPart['rotate'] && [90, 270].includes(dstPart['rotate'])

          const finalExtendDisMap = dealExpandSlot(
            dstPart,
            store.state.ncSetting,
            cutKnifeRadius
          )
          if (finalExtendDisMap) {
            for (const key in finalExtendDisMap) {
              const extendDis = finalExtendDisMap[key]
              switch (key) {
                case 'left':
                  dstPart['startX'] += extendDis
                  break
                case 'bottom':
                  dstPart['startY'] += extendDis
                  break
              }
            }
          }
          changePositionCutOrigin(
            dstPart,
            tmpValue,
            tmpValue2,
            bigPlankWidth,
            bigPlankHeight
          )

          // TODO 靠边板槽 暂时留下代码
          // if (false) {
          //   // 异形板件不进行处理
          //   if (ncConfig.sideBoardPlacing == 'outside' && !dstPart.path) {
          //     let needRota = changeSlotsPositionByNCsetting(
          //       dstPart,
          //       dstPart.plankWidth,
          //       dstPart.plankHeight
          //     )
          //     if (needRota) {
          //       rotatePlankWhenSlotAtTheEdge(
          //         dstPart,
          //         dstPart.plankWidth,
          //         dstPart.plankHeight
          //       )
          //       rotatePartInternal(dstPart)
          //       rotatePartInternal(dstPart)
          //     }
          //   } else if (ncConfig.sideBoardPlacing == 'inside' && !dstPart.path) {
          //     // 异形板件不进行处理
          //     rotatePlankWhenSlotAtTheEdge(
          //       dstPart,
          //       dstPart.plankWidth,
          //       dstPart.plankHeight
          //     )
          //   }
          // }

          if (!container.hasOwnProperty(stockKey)) {
            container[stockKey] = {
              thick: thick,
              matCode: matCode,
              texture: texture,
              stockNum: stockNum,
              surplusInfo: surplusInfo,
              stockKey: stockKey,
              parts: [],
              plankWidth: bigPlankWidth,
              plankHeight: bigPlankHeight,
              otherPlate: otherPlate,
              margin:
                otherPlate && Object.keys(otherPlate).length
                  ? otherPlate.trim_edge
                  : store.state.selectStandardPlank.plankEdgeOff,
              standardPlankId:
                store.state.selectStandardPlank.standard_plank_id,
            }
          }
          dstPart['startX'] = toDecimal(
            dstPart['startX'],
            store.state.ncSetting.decimal
          )
          container[stockKey]['parts'].push(dstPart)
          break
        }
      }
    }
  }
}

export function dealExpandSlot(part, ncSettings, cutKnifeRadius) {
  let extendDis = 0
  const expandDisMap = {
    left: [],
    bottom: [],
    right: [],
    top: [],
    tb: [],
    lr: [],
  }

  const slots = part['slots']
  const isPreLayout = ncSettings.isPreLayout
  const layoutGap = ncSettings.panelSize.layoutGap
  // 铣底圆弧获取指定的刀
  const allKnifes = isPreLayout ? null : ncSettings && ncSettings.knives
  if (!allKnifes) return
  for (const index in slots) {
    var slot = slots[index]
    /** 指定刀具 */
    const selectknife = slot?.knifeName

    const { realWidth, nearSide, type } = getSlotPositionInfo(slot, part)

    const isNeedExpand = nearSide && realWidth > layoutGap + cutKnifeRadius * 2
    // 判断槽是横槽还是竖槽，靠哪边
    slot.nearSide = nearSide
    const selectKnifeDiameter = Number(
      allKnifes[selectknife]?.diameter ?? realWidth
    )

    if (isNeedExpand) {
      if (selectknife) {
        extendDis =
          (specialSymbol.includes(slot.symbol)
            ? realWidth
            : selectKnifeDiameter / 2) -
          (layoutGap + cutKnifeRadius * 2)
      } else {
        /** 全部刀 */
        const allKnifeList = Object.values(ncSettings.knives)
        /** 全部拉槽刀 */
        const allBroKnifeList = allKnifeList.filter((k) => k.slotable)
        /** 全部铣槽刀 */
        const allMillKnifeList = allKnifeList.filter((k) => k.millableSlot)
        if (allBroKnifeList.find((k) => k.diameter == realWidth)) {
          extendDis =
            (specialSymbol.includes(slot.symbol) ? realWidth : realWidth / 2) -
            (layoutGap + cutKnifeRadius * 2)
        } else {
          const isThrough = nearSide == 5
          if (ncSettings.z_shape_processed_through_slot && isThrough) {
            const allMillKinfeArr = allMillKnifeList.filter(
              (k) => k.diameter < realWidth
            )
            const diaArr = allMillKinfeArr.map((k) => k.diameter)
            const millKnife = allMillKinfeArr.find(
              (k) => k.diameter == Math.max(...diaArr)
            )
            if (millKnife) {
              const extend =
                millKnife.diameter / 2 - (layoutGap + cutKnifeRadius * 2)
              extendDis = extend > 0 ? extend : 0
            }
          }
        }
      }
      switch (nearSide) {
        case 1:
          expandDisMap.left.push(extendDis)
          break
        case 2:
          expandDisMap.bottom.push(extendDis)
          break
        case 3:
          expandDisMap.right.push(extendDis)
          break
        case 4:
          expandDisMap.top.push(extendDis)
          break
        default:
          if (type == 'horizontal') {
            expandDisMap.lr.push(extendDis * 2)
          } else {
            expandDisMap.tb.push(extendDis * 2)
          }
          break
      }
    }

    // if (slot['width'] > layoutGap + cutKnifeRadius * 2) {
    //   extendDis = slot['width'] - (layoutGap + cutKnifeRadius * 2)
    //   if (slot['pt1']['x'] === slot['pt2']['x']) {
    //     const slotHeight = Math.abs(
    //       slot['pt2']['y'] - slot['pt1']['y']
    //     )
    //     const height = srcPart['realRect']['height']
    //     const isThroughSlot =
    //       (slot['symbol'] === 'CCSlot' ||
    //         slot['symbol'] === 'exSlot') &&
    //       slotHeight < height
    //     if (isThroughSlot) extendDis = 0
    //     dstPart['rect']['height'] = toDecimal(
    //       (isNeedRotate
    //         ? srcPart['rect']['width']
    //         : srcPart['rect']['height']) -
    //         extendDis * 2,
    //       store.state.ncSetting.decimal
    //     )

    //     dstPart['startY'] = dstPart['startY'] + extendDis
    //   }
    //   if (slot['pt1']['y'] === slot['pt2']['y']) {
    //     const slotWidth = Math.abs(
    //       slot['pt2']['x'] - slot['pt1']['x']
    //     )
    //     const width = srcPart['realRect']['width']
    //     const isThroughSlot =
    //       (slot['symbol'] === 'CCSlot' ||
    //         slot['symbol'] === 'exSlot') &&
    //       slotWidth < width
    //     if (isThroughSlot) extendDis = 0
    //     dstPart['rect']['width'] =
    //       (isNeedRotate
    //         ? srcPart['rect']['height']
    //         : srcPart['rect']['width']) -
    //       extendDis * 2
    //     dstPart['startX'] = dstPart['startX'] + extendDis
    //   }
    // }
  }
  function dealDefaultVal(arr) {
    return arr.length ? Math.max(...arr) : 0
  }
  /** 四周扩板最大值计算 */
  const maxExtandDisMap = {
    left: dealDefaultVal(expandDisMap.left),
    bottom: dealDefaultVal(expandDisMap.bottom),
    right: dealDefaultVal(expandDisMap.right),
    top: dealDefaultVal(expandDisMap.top),
    tb: dealDefaultVal(expandDisMap.tb),
    lr: dealDefaultVal(expandDisMap.lr),
  }
  const finalExtendDisMap = {
    left: toDecimal(
      maxExtandDisMap.left > maxExtandDisMap.lr / 2
        ? maxExtandDisMap.left
        : maxExtandDisMap.lr / 2,
      2
    ),
    bottom: toDecimal(
      maxExtandDisMap.bottom > maxExtandDisMap.tb / 2
        ? maxExtandDisMap.bottom
        : maxExtandDisMap.tb / 2,
      2
    ),
    right: toDecimal(
      maxExtandDisMap.right > maxExtandDisMap.lr / 2
        ? maxExtandDisMap.right
        : maxExtandDisMap.lr / 2,
      2
    ),
    top: toDecimal(
      maxExtandDisMap.top > maxExtandDisMap.tb / 2
        ? maxExtandDisMap.top
        : maxExtandDisMap.tb / 2,
      2
    ),
  }

  return finalExtendDisMap
}

export function getSlotPositionInfo(slot, part) {
  const { opt1, opt2, width } = slot
  const { oRect } = part
  const slotW = Math.abs(opt1.x - opt2.x)
  const slotH = Math.abs(opt1.y - opt2.y)
  /** 横：horizontal  竖：vertical */
  let type = 'horizontal'
  let realWidth = width
  let realLong = width
  if (!slotW) {
    if (slotH < width) {
      type = 'horizontal'
      realWidth = slotH
    } else {
      type = 'vertical'
      realLong = slotH
    }
  } else {
    if (slotW < width) {
      type = 'vertical'
      realWidth = slotW
    } else {
      type = 'horizontal'
      realLong = slotW
    }
  }

  realLong = Number(Number(realLong).toFixed(4))
  let nearSide = 0
  const nearSideList = []
  if (type == 'horizontal') {
    const minX = Math.min(opt1.x, opt2.x)
    const maxX = Math.max(opt1.x, opt2.x)
    if (minX <= 0.01) {
      nearSideList.push(1)
    }
    if (maxX >= oRect.width - 0.01) {
      nearSideList.push(3)
    }
  } else {
    const minY = Math.min(opt1.y, opt2.y)
    const maxY = Math.max(opt1.y, opt2.y)
    if (minY < 0.01) {
      nearSideList.push(4)
    }
    if (maxY >= oRect.height - 0.01) {
      nearSideList.push(2)
    }
  }

  if (nearSideList.length == 2) {
    nearSide = 5
  } else if (nearSideList.length) {
    nearSide = nearSideList[0]
  }

  return {
    realWidth,
    type,
    nearSide,
    realLong,
  }
}

// 根据nc设置处理槽摆放位置
export function changeSlotsPositionByNCsetting(
  dstPart,
  plankWidth,
  plankHeight
) {
  let needRota = false
  // 异形板件不进行处理
  if (ncConfig.sideBoardPlacing == 'outside' && !dstPart.path) {
    let safeDis = 100
    let startX = dstPart.startX
    let startY = dstPart.startY
    let slots = dstPart.slots
    for (var i in slots) {
      var slot = slots[i]
      var pt1 = slot.pt1
      var pt2 = slot.pt2
      if (Math.abs(pt1.x - pt2.x) <= 0.000001) {
        if (startX + pt1.x < safeDis) {
          needRota = true
          break
        } else if (
          startX + dstPart.rect.width + 20 > plankWidth &&
          startX + pt1.x < plankWidth - dstPart.rect.width / 2
        ) {
          needRota = true
          break
        } else if (startX <= 0) {
          needRota = true
          break
        }
      } else if (Math.abs(pt1.y - pt2.y) <= 0.000001) {
        if (startY + pt1.y < safeDis) {
          needRota = true
          break
        } else if (
          startY + dstPart.rect.height + 20 > plankHeight &&
          startY + pt1.y < plankHeight - dstPart.rect.height / 2
        ) {
          needRota = true
          break
        } else if (startY <= 0) {
          needRota = true
          break
        }
      }
    }
  }
  // else if(ncConfig.sideBoardPlacing == 'inside'){
  //   // rotatePlankWhenSlotAtTheEdge(dstPart, dstPart.plankWidth, dstPart.plankHeight)
  // }
  return needRota
}
//调整原点
function changePositionCutOrigin(
  dstPart,
  tmpValue,
  tmpValue2,
  plankWidth,
  plankHeight
) {
  var startX = dstPart['startX']
  var startY = dstPart['startY']
  var rectWidth = dstPart['rect']['width']
  var rectHeight = dstPart['rect']['height']

  var curStartPositon = startPosition || store.state.ncSetting.startPosition
  // 调整排版原点
  if (curStartPositon === '左上角') {
    dstPart['startY'] += tmpValue2
    dstPart['startX'] += tmpValue2
  } else if (curStartPositon === '左下角') {
    dstPart['startY'] = plankHeight - (startY + rectHeight) - tmpValue
    dstPart['startX'] += tmpValue2
  } else if (curStartPositon === '右下角') {
    dstPart['startX'] = plankWidth - (startX + rectWidth) - tmpValue
    dstPart['startY'] = plankHeight - (startY + rectHeight) - tmpValue
  } else if (curStartPositon === '右上角') {
    dstPart['startX'] = plankWidth - (startX + rectWidth) - tmpValue
    dstPart['startY'] += tmpValue2
  }
}

function rotate(part) {
  function crudeCopy(src) {
    // return jjFromUnit(jjSaveUnit(src));
    if (src) {
      return JSON.parse(JSON.stringify(src))
    } else {
      return src
    }
  }

  var rect = crudeCopy(part['rect'])
  var oRect = crudeCopy(part['oRect'])
  var paths = crudeCopy(part['path'])
  var oPaths = crudeCopy(part['oPath'])
  var holes = crudeCopy(part['holes'])
  var slots = crudeCopy(part['slots'])
  var handleSlopes = crudeCopy(part['handleSlopes'])
  var cutCurve = crudeCopy(part['cutCurve'])
  var cutCurveEx = crudeCopy(part['cutCurveEx'])
  var realCurve = crudeCopy(part['realCurve'])
  var realCurveEx = crudeCopy(part['realCurveEx'])
  var sholes = crudeCopy(part['sholes'])
  var sslots = crudeCopy(part['sslots'])
  var realRect = crudeCopy(part['realRect'])
  var edgeInfo = crudeCopy(part['edgeInfo'])
  var sholeInfo = crudeCopy(part['sholeInfo'])
  var sholeInfoF = crudeCopy(part['sholeInfoF'])
  var sslotInfo = crudeCopy(part['sslotInfo'])
  var sslotInfoF = crudeCopy(part['sslotInfoF'])
  var curveHoles = crudeCopy(part['curveHoles'])
  let millInfo = crudeCopy(part['millInfo'])
  var oriCurveHoles = crudeCopy(part['oriCurveHoles'])
  let lightEdgeInfo = part['lightEdgeInfo']
  let model_front_edge = part['model_front_edge']
  let doorOpenSide = part['doorOpenSide']

  //TO DO 临时解决，重新设计
  const isPreLayout = store.state.ncSetting.isPreLayout
  // 指定刀直径
  let selectKnifeDiameter = isPreLayout ? cutKnifeRadius * 2 : 0
  // 铣底圆弧获取指定的刀
  const allKnifes = isPreLayout
    ? null
    : store.state.ncSetting && store.state.ncSetting.knives
  let extendDis
  // for (var index in slots) {
  //   var slot = slots[index]
  //   const selectknife = slot?.knifeName
  //   // 这里旋转后会把板子变小
  //   if (specialSymbol.includes(slot['symbol'])) {
  //     if (
  //       slot.processingSequence === 'firstMillSlot' ||
  //       (slot.symbol === 'arcStraightSlot' && slot?.knifeName)
  //     ) {
  //       // 铣底圆弧指定刀
  //       if (!!selectknife && !isPreLayout && allKnifes[selectknife].diameter) {
  //         selectKnifeDiameter = Number(allKnifes[selectknife]?.diameter)
  //       }
  //       if (selectKnifeDiameter > layoutGap + cutKnifeRadius * 2) {
  //         const extendis =
  //           selectKnifeDiameter - (layoutGap + cutKnifeRadius * 2)
  //         if (slot['pt1']['x'] === slot['pt2']['x']) {
  //           rect['height'] = rect['height'] - extendis * 2
  //         }
  //         if (slot['pt1']['y'] === slot['pt2']['y']) {
  //           rect['width'] = rect['height'] - extendis * 2
  //         }
  //       }
  //     } else if (initSymbol.includes(slot['symbol'])) {
  //       if (slot['width'] > layoutGap + cutKnifeRadius * 2) {
  //         extendDis = slot['width'] - (layoutGap + cutKnifeRadius * 2)
  //         if (slot['pt1']['x'] === slot['pt2']['x']) {
  //           const slotHeight = Math.abs(slot['pt2']['y'] - slot['pt1']['y'])
  //           const height = realRect['height']
  //           const isThroughSlot =
  //             (slot['symbol'] === 'CCSlot' || slot['symbol'] === 'exSlot') &&
  //             slotHeight < height
  //           if (isThroughSlot) extendDis = 0
  //           rect['height'] = rect['height'] - extendDis * 2
  //         }
  //         if (slot['pt1']['y'] === slot['pt2']['y']) {
  //           const slotHeight = Math.abs(slot['pt2']['x'] - slot['pt1']['x'])
  //           const width = realRect['width']
  //           const isThroughSlot =
  //             (slot['symbol'] === 'CCSlot' || slot['symbol'] === 'exSlot') &&
  //             slotHeight < width
  //           if (isThroughSlot) extendDis = 0
  //           rect['width'] = rect['width'] - extendDis * 2
  //         }
  //       }
  //     }
  //     break
  //   }
  // }

  // 板件中心点
  // var o = Qt.point(rect.width/2, rect.height/2);

  var o = {
    x: rect.width / 2,
    y: rect.height / 2,
  }
  // var oo = Qt.point(oRect.width/2, oRect.height/2)
  var oo = {
    x: oRect.width / 2,
    y: oRect.height / 2,
  }

  // rect
  // rect = Qt.rect(0, 0, rect.height, rect.width);
  rect = {
    x: 0,
    y: 0,
    width: rect.height,
    height: rect.width,
  }
  // oRect = Qt.rect(0, 0, oRect.height, oRect.width);
  oRect = {
    x: 0,
    y: 0,
    width: oRect.height,
    height: oRect.width,
  }
  // realRect = Qt.rect(0, 0, realRect.height, realRect.width);
  realRect = {
    x: 0,
    y: 0,
    width: realRect.height,
    height: realRect.width,
  }

  // paths
  for (var i in paths) {
    var path = paths[i]
    for (var j in path) {
      var p = path[j]

      var x = p['x'] - o.x
      var y = p['y'] - o.y

      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }

  for (var i in oPaths) {
    var oPath = oPaths[i]
    for (var j in oPath) {
      var p = oPath[j]

      var x = p['x'] - oo.x
      var y = p['y'] - oo.y

      p['x'] = -y + oo.y
      p['y'] = x + oo.x
    }
  }

  for (var i in cutCurve) {
    var p = cutCurve[i]

    var x = p['x'] - o.x
    var y = p['y'] - o.y

    p['x'] = -y + o.y
    p['y'] = x + o.x
  }

  for (var i in cutCurveEx) {
    var path = cutCurveEx[i]
    for (var j in path) {
      var p = path[j]

      var x = p['x'] - o.x
      var y = p['y'] - o.y

      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }

  //curveHoles
  for (var i in curveHoles) {
    var path = curveHoles[i]['path']
    for (var j in path) {
      var p = path[j]

      var x = p['x'] - o.x
      var y = p['y'] - o.y

      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }

  if (millInfo) {
    for (let i in millInfo) {
      const shape = millInfo[i]['shape']
      for (let j in shape) {
        const p = shape[j]
        const x = p['x'] - oo.x
        const y = p['y'] - oo.y
        p['x'] = -y + oo.y
        p['y'] = x + oo.x
      }
    }
  }

  //oriCurveHoles
  for (var i in oriCurveHoles) {
    var path = oriCurveHoles[i]['path']
    for (var j in path) {
      var p = path[j]

      var x = p['x'] - o.x
      var y = p['y'] - o.y

      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }

  //realCurve
  var minx = -999999,
    miny,
    maxx,
    maxy
  for (var i in realCurve) {
    if (minx === -999999) {
      minx = maxx = realCurve[i].x
      miny = maxy = realCurve[i].y
    } else {
      minx = Math.min(minx, realCurve[i].x)
      miny = Math.min(miny, realCurve[i].y)
      maxx = Math.max(maxx, realCurve[i].x)
      maxy = Math.max(maxy, realCurve[i].y)
    }
  }
  var rw = maxx - minx
  var rh = maxy - miny
  var ro = {
    x: (maxx + minx) / 2,
    y: (maxy + miny) / 2,
  }

  for (var i in realCurve) {
    var p = realCurve[i]

    var x = p['x'] - oo.x
    var y = p['y'] - oo.y

    p['x'] = -y + oo.y
    p['y'] = x + oo.x
  }

  for (i in realCurveEx) {
    var ex = realCurveEx[i]
    for (j in ex) {
      p = ex[j]

      x = p['x'] - oo.x
      y = p['y'] - oo.y

      p['x'] = -y + oo.y
      p['y'] = x + oo.x
    }
  }

  // holes
  for (var h in holes) {
    var hole = holes[h]
    var center = hole['center']
    var ocenter = hole['ocenter']

    var x = center['x'] - o.x
    var y = center['y'] - o.y
    center['x'] = -y + o.y
    center['y'] = x + o.x

    x = ocenter['x'] - oo.x
    y = ocenter['y'] - oo.y
    ocenter['x'] = -y + oo.y
    ocenter['y'] = x + oo.x
    // 顺时针旋转
    const newHole = adjustHoleDirection(hole, 90 * 1)
    Object.assign(holes[h], newHole)
  }

  // slots
  for (var s in slots) {
    var slot = slots[s]
    var pt1 = slot['pt1']
    var pt2 = slot['pt2']
    var opt1 = slot['opt1']
    var opt2 = slot['opt2']

    var x1 = pt1['x'] - o.x
    var y1 = pt1['y'] - o.y
    var x2 = pt2['x'] - o.x
    var y2 = pt2['y'] - o.y

    pt1['x'] = -y1 + o.y
    pt1['y'] = x1 + o.x
    pt2['x'] = -y2 + o.y
    pt2['y'] = x2 + o.x

    x1 = opt1['x'] - oo.x
    y1 = opt1['y'] - oo.y
    x2 = opt2['x'] - oo.x
    y2 = opt2['y'] - oo.y

    opt1['x'] = -y1 + oo.y
    opt1['y'] = x1 + oo.x
    opt2['x'] = -y2 + oo.y
    opt2['y'] = x2 + oo.x
  }

  // handleSlopes
  for (var s in handleSlopes) {
    var slope = handleSlopes[s]
    var pt1 = slope['pt1']
    var pt2 = slope['pt2']
    var opt1 = slope['opt1']
    var opt2 = slope['opt2']

    var x1 = pt1['x'] - o.x
    var y1 = pt1['y'] - o.y
    var x2 = pt2['x'] - o.x
    var y2 = pt2['y'] - o.y

    pt1['x'] = -y1 + o.y
    pt1['y'] = x1 + o.x
    pt2['x'] = -y2 + o.y
    pt2['y'] = x2 + o.x

    x1 = opt1['x'] - oo.x
    y1 = opt1['y'] - oo.y
    x2 = opt2['x'] - oo.x
    y2 = opt2['y'] - oo.y

    opt1['x'] = -y1 + oo.y
    opt1['y'] = x1 + oo.x
    opt2['x'] = -y2 + oo.y
    opt2['y'] = x2 + oo.x
  }

  // sholes
  for (var k in sholes) {
    var shole = sholes[k]
    var side = shole['side']
    var ocenter = shole['ocenter']

    x = ocenter['x'] - oo.x
    y = ocenter['y'] - oo.y
    ocenter['x'] = -y + oo.y
    ocenter['y'] = x + oo.x

    side = parseInt(side) - 1
    if (side <= 0) {
      side = 4
    }

    shole['side'] = side
  }

  // sslots
  for (var j in sslots) {
    var sslot = sslots[j]
    var side = sslot['side']
    var opt1 = sslot['opt1']
    var opt2 = sslot['opt2']

    x1 = opt1['x'] - oo.x
    y1 = opt1['y'] - oo.y
    x2 = opt2['x'] - oo.x
    y2 = opt2['y'] - oo.y

    opt1['x'] = -y1 + oo.y
    opt1['y'] = x1 + oo.x
    opt2['x'] = -y2 + oo.y
    opt2['y'] = x2 + oo.x

    side = parseInt(side) - 1
    if (side <= 0) {
      side = 4
    }

    sslot['side'] = side
  }

  // sholeInfo
  sholeInfo = generatorSideHoleSlot([
    ...sholes,
    ...holes.filter((hole) => isSideMachineHole(hole) && hole.side == 1),
  ])
  sholeInfoF = generatorSideHoleSlot(
    holes.filter((hole) => isSideMachineHole(hole) && hole.side == -1),
    'upDownFlip'
  )
  sslotInfo = generatorSideHoleSlot([
    ...sslots,
    ...slots.filter((slot) => isSideMachineSlot(slot) && slot.side == 1),
  ])
  sslotInfoF = generatorSideHoleSlot(
    slots.filter((slot) => isSideMachineSlot(slot) && slot.side == -1),
    'upDownFlip'
  )

  // edgeInfo
  var left = edgeInfo.substring(
    edgeInfo.indexOf('←') + 1,
    edgeInfo.indexOf('↓')
  )
  var bottom = edgeInfo.substring(
    edgeInfo.indexOf('↓') + 1,
    edgeInfo.indexOf('→')
  )
  var right = edgeInfo.substring(
    edgeInfo.indexOf('→') + 1,
    edgeInfo.indexOf('↑')
  )
  var top = edgeInfo.substring(edgeInfo.indexOf('↑') + 1)
  edgeInfo = '←' + bottom + '↓' + right + '→' + top + '↑' + left
  lightEdgeInfo = dealEdgeInfoChange(lightEdgeInfo, 'rotate90')
  model_front_edge = dealFormerEdgeChange(
    model_front_edge,
    'rotate90',
    'former'
  )
  doorOpenSide = dealFormerEdgeChange(doorOpenSide, 'rotate90', 'doorOpenSide')
  var obj = {}

  if (paths) {
    obj['path'] = paths
  }
  if (oPaths) {
    obj['oPath'] = oPaths
  }
  obj['rect'] = rect
  obj['oRect'] = oRect
  obj['holes'] = holes
  obj['slots'] = slots
  obj['sholes'] = sholes
  obj['sslots'] = sslots
  obj['realRect'] = realRect
  obj['edgeInfo'] = edgeInfo
  obj['sholeInfo'] = sholeInfo
  obj['sholeInfoF'] = sholeInfoF
  obj['sslotInfo'] = sslotInfo
  obj['sslotInfoF'] = sslotInfoF
  obj['cutCurve'] = cutCurve
  obj['cutCurveEx'] = cutCurveEx
  obj['realCurve'] = realCurve
  obj['realCurveEx'] = realCurveEx
  obj['curveHoles'] = curveHoles
  obj['millInfo'] = millInfo
  obj['oriCurveHoles'] = oriCurveHoles
  obj['lightEdgeInfo'] = lightEdgeInfo
  obj['model_front_edge'] = model_front_edge
  obj['doorOpenSide'] = doorOpenSide
  obj['handleSlopes'] = handleSlopes

  return obj
}

// 板件旋转180
export function rotate180Part(part) {
  if (!part) {
    return
  }
  var rect = part['rect']
  var oRect = part['oRect']
  var startX = part['startX']
  var startY = part['startY']
  var paths = part['path']
  var oPaths = part['oPath']
  var cutCurve = part['cutCurve']
  var cutCurveEx = part['cutCurveEx']
  var realCurve = part['realCurve']
  var realCurveEx = part['realCurveEx']
  var holes = part['holes']
  var slots = part['slots']
  var handleSlopes = part['handleSlopes']
  var sholes = part['sholes']
  var sslots = part['sslots']
  var realRect = part['realRect']
  var edgeInfo = part['edgeInfo']
  var sholeInfo = part['sholeInfo']
  var sholeInfoF = part['sholeInfoF']
  var sslotInfo = part['sslotInfo']
  var sslotInfoF = part['sslotInfoF']
  var curveHoles = part['curveHoles']
  var millInfo = part['millInfo']
  var oriCurveHoles = part['oriCurveHoles']
  let lightEdgeInfo = part['lightEdgeInfo']
  let model_front_edge = part['model_front_edge']
  let doorOpenSide = part['doorOpenSide']

  // paths
  for (var i in paths) {
    var path = paths[i]
    for (var j in path) {
      path[j].x = rect.width - path[j].x
      path[j].y = rect.height - path[j].y
    }
  }

  // oPaths
  for (var i in oPaths) {
    var path = oPaths[i]
    for (var j in path) {
      path[j].x = oRect.width - path[j].x
      path[j].y = oRect.height - path[j].y
    }
  }

  for (var i in curveHoles) {
    var path = curveHoles[i]['path']
    for (var j in path) {
      var p = path[j]
      p.x = rect.width - p.x
      p.y = rect.height - p.y
    }
  }
  if (millInfo) {
    for (let i in millInfo) {
      const shape = millInfo[i]['shape']
      for (let j in shape) {
        const p = shape[j]
        p.x = oRect.width - p.x
        p.y = oRect.height - p.y
      }
    }
  }

  for (var i in oriCurveHoles) {
    var path = oriCurveHoles[i]['path']
    for (var j in path) {
      var p = path[j]
      p.x = rect.width - p.x
      p.y = rect.height - p.y
    }
  }

  // cutCurve
  for (var i in cutCurve) {
    var p = cutCurve[i]
    p.x = rect.width - p['x']
    p.y = rect.height - p['y']
  }

  // cutCurveEx
  for (var i in cutCurveEx) {
    path = cutCurveEx[i]
    for (var j in path) {
      path[j].x = rect.width - path[j].x
      path[j].y = rect.height - path[j].y
    }
  }

  // realCurve
  for (var i in realCurve) {
    var p = realCurve[i]

    p['x'] = oRect.width - p['x']
    p['y'] = oRect.height - p['y']
  }

  // realCurveEx
  for (var i in realCurveEx) {
    var path = realCurveEx[i]
    for (var j in path) {
      path[j].x = oRect.width - path[j].x
      path[j].y = oRect.height - path[j].y
    }
  }

  // holes
  for (var h in holes) {
    var center = holes[h].center
    center.x = rect.width - center.x
    center.y = rect.height - center.y
    var ocenter = holes[h].ocenter
    ocenter.x = oRect.width - ocenter.x
    ocenter.y = oRect.height - ocenter.y
    const newHole = adjustHoleDirection(holes[h], 180 * 1)
    Object.assign(holes[h], newHole)
  }

  // slots
  for (var s in slots) {
    var slot = slots[s]
    var pt1 = slot['pt1']
    var pt2 = slot['pt2']

    pt1.x = rect.width - pt1.x
    pt1.y = rect.height - pt1.y

    pt2.x = rect.width - pt2.x
    pt2.y = rect.height - pt2.y

    var opt1 = slot['opt1']
    var opt2 = slot['opt2']

    opt1.x = oRect.width - opt1.x
    opt1.y = oRect.height - opt1.y

    opt2.x = oRect.width - opt2.x
    opt2.y = oRect.height - opt2.y
  }

  // handleSlopes
  for (var s in handleSlopes) {
    var slope = handleSlopes[s]
    var pt1 = slope['pt1']
    var pt2 = slope['pt2']

    pt1.x = rect.width - pt1.x
    pt1.y = rect.height - pt1.y

    pt2.x = rect.width - pt2.x
    pt2.y = rect.height - pt2.y

    var opt1 = slope['opt1']
    var opt2 = slope['opt2']

    opt1.x = oRect.width - opt1.x
    opt1.y = oRect.height - opt1.y

    opt2.x = oRect.width - opt2.x
    opt2.y = oRect.height - opt2.y
  }

  // sholes
  for (var k in sholes) {
    var shole = sholes[k]
    var side = shole['side']

    var ocenter = sholes[k].ocenter
    ocenter.x = oRect.width - ocenter.x
    ocenter.y = oRect.height - ocenter.y

    if (side == 2) {
      side = 4
    } else if (side == 1) {
      side = 3
    } else {
      side = parseInt(side) - 2
    }

    shole['side'] = side
  }

  // sslots
  for (var j in sslots) {
    var sslot = sslots[j]
    var side = sslot['side']

    if (side == 2) {
      side = 4
    } else if (side == 1) {
      side = 3
    } else {
      side = parseInt(side) - 2
    }

    sslot['side'] = side

    var opt1 = sslot['opt1']
    var opt2 = sslot['opt2']

    opt1.x = oRect.width - opt1['x']
    opt1.y = oRect.height - opt1['y']
    opt2.x = oRect.width - opt2['x']
    opt2.y = oRect.height - opt2['y']
  }

  sholeInfo = generatorSideHoleSlot([
    ...sholes,
    ...holes.filter((hole) => isSideMachineHole(hole) && hole.side == 1),
  ])
  sholeInfoF = generatorSideHoleSlot(
    holes.filter((hole) => isSideMachineHole(hole) && hole.side == -1),
    'upDownFlip'
  )
  sslotInfo = generatorSideHoleSlot([
    ...sslots,
    ...slots.filter((slot) => isSideMachineSlot(slot) && slot.side == 1),
  ])
  sslotInfoF = generatorSideHoleSlot(
    slots.filter((slot) => isSideMachineSlot(slot) && slot.side == -1),
    'upDownFlip'
  )

  // edgeInfo
  var left = edgeInfo.substring(
    edgeInfo.indexOf('←') + 1,
    edgeInfo.indexOf('↓')
  )
  var bottom = edgeInfo.substring(
    edgeInfo.indexOf('↓') + 1,
    edgeInfo.indexOf('→')
  )
  var right = edgeInfo.substring(
    edgeInfo.indexOf('→') + 1,
    edgeInfo.indexOf('↑')
  )
  var top = edgeInfo.substring(edgeInfo.indexOf('↑') + 1)
  edgeInfo = '←' + right + '↓' + top + '→' + left + '↑' + bottom
  lightEdgeInfo = dealEdgeInfoChange(lightEdgeInfo, 'rotate180')
  model_front_edge = dealFormerEdgeChange(
    model_front_edge,
    'rotate180',
    'former'
  )
  doorOpenSide = dealFormerEdgeChange(doorOpenSide, 'rotate180', 'doorOpenSide')

  part['rect'] = rect
  part['oRect'] = oRect
  part['startX'] = startX
  part['startY'] = startY
  part['path'] = paths
  part['oPath'] = oPaths
  part['cutCurve'] = cutCurve
  part['cutCurveEx'] = cutCurveEx
  part['realCurve'] = realCurve
  part['realCurveEx'] = realCurveEx
  part['holes'] = holes
  part['slots'] = slots
  part['sholes'] = sholes
  part['sslots'] = sslots
  part['realRect'] = realRect
  part['edgeInfo'] = edgeInfo
  part['sholeInfo'] = sholeInfo
  part['sholeInfoF'] = sholeInfoF
  part['sslotInfoF'] = sslotInfoF
  part['sslotInfo'] = sslotInfo
  part['curveHoles'] = curveHoles
  part['millInfo'] = millInfo
  part['oriCurveHoles'] = oriCurveHoles
  part['lightEdgeInfo'] = lightEdgeInfo
  part['model_front_edge'] = model_front_edge
  part['doorOpenSide'] = doorOpenSide
  part['handleSlopes'] = handleSlopes
  return part
}

// 旋转板件

export function rotatePart(part) {
  if (!part) {
    return
  }

  var rect = part['rect']
  var oRect = part['oRect']
  var startX = part['startX']
  var startY = part['startY']
  var paths = part['path']
  var oPaths = part['oPath']
  var cutCurve = part['cutCurve']
  var cutCurveEx = part['cutCurveEx']
  var realCurve = part['realCurve']
  var realCurveEx = part['realCurveEx']
  var holes = part['holes']
  var slots = part['slots']
  var handleSlopes = part['handleSlopes']
  var sholes = part['sholes']
  var sslots = part['sslots']
  var realRect = part['realRect']
  var edgeInfo = part['edgeInfo']
  var sholeInfo = part['sholeInfo']
  var sholeInfoF = part['sholeInfoF']
  var sslotInfoF = part['sslotInfoF']
  var sslotInfo = part['sslotInfo']
  var curveHoles = part['curveHoles']
  var millInfo = part['millInfo']
  var oriCurveHoles = part['oriCurveHoles']
  let lightEdgeInfo = part['lightEdgeInfo']
  let model_front_edge = part['model_front_edge']
  let doorOpenSide = part['doorOpenSide']

  // 顺时针旋转

  // 板件中心点
  // var o = Qt.point(rect.width / 2, rect.height / 2);
  var o = {
    x: rect.width / 2,
    y: rect.height / 2,
  }

  // var oo = Qt.point(oRect.width / 2, oRect.height / 2);
  var oo = {
    x: oRect.width / 2,
    y: oRect.height / 2,
  }

  // startX\startY
  startX = startX + o.x - rect.height / 2
  startY = startY + o.y - rect.width / 2
  // rect
  // rect = Qt.rect(0, 0, rect.height, rect.width);
  rect = {
    x: 0,
    y: 0,
    width: rect.height,
    height: rect.width,
  }

  oRect = {
    x: 0,
    y: 0,
    width: oRect.height,
    height: oRect.width,
  }

  // realRect = Qt.rect(0, 0, realRect.height, realRect.width);
  realRect = {
    x: 0,
    y: 0,
    width: realRect.height,
    height: realRect.width,
  }

  // paths
  for (var i in paths) {
    var path = paths[i]
    for (var j in path) {
      var p = path[j]
      var x = p['x'] - o.x
      var y = p['y'] - o.y

      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }

  // oPaths
  for (var i in oPaths) {
    var path = oPaths[i]
    for (var j in path) {
      var p = path[j]
      var x = p['x'] - oo.x
      var y = p['y'] - oo.y

      p['x'] = -y + oo.y
      p['y'] = x + oo.x
    }
  }

  for (var i in cutCurve) {
    p = cutCurve[i]
    x = p['x'] - o.x
    y = p['y'] - o.y

    p['x'] = -y + o.y
    p['y'] = x + o.x
  }

  for (var i in cutCurveEx) {
    path = cutCurveEx[i]
    for (var j in path) {
      p = path[j]
      x = p['x'] - o.x
      y = p['y'] - o.y
      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }
  for (var i in curveHoles) {
    path = curveHoles[i]
    for (var j in path.path) {
      p = path.path[j]
      x = p['x'] - o.x
      y = p['y'] - o.y
      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }
  if (millInfo) {
    for (let i in millInfo) {
      const { shape } = millInfo[i]
      for (let j in shape) {
        p = shape[j]
        x = p['x'] - oo.x
        y = p['y'] - oo.y
        p['x'] = -y + oo.y
        p['y'] = x + oo.x
      }
    }
  }

  for (var i in oriCurveHoles) {
    path = oriCurveHoles[i]
    for (var j in path.path) {
      p = path.path[j]
      x = p['x'] - o.x
      y = p['y'] - o.y
      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }

  for (var i in realCurve) {
    p = realCurve[i]

    x = p['x'] - oo.x
    y = p['y'] - oo.y

    p['x'] = -y + oo.y
    p['y'] = x + oo.x
  }

  for (var i in realCurveEx) {
    path = realCurveEx[i]
    for (var j in path) {
      p = path[j]

      x = p['x'] - oo.x
      y = p['y'] - oo.y

      p['x'] = -y + oo.y
      p['y'] = x + oo.x
    }
  }

  // holes
  for (var h in holes) {
    var hole = holes[h]
    var center = hole['center']
    var ocenter = hole['ocenter']

    var x = center['x'] - o.x
    var y = center['y'] - o.y
    var xx = ocenter['x'] - oo.x
    var yy = ocenter['y'] - oo.y

    center['x'] = -y + o.y
    center['y'] = x + o.x

    ocenter['x'] = -yy + oo.y
    ocenter['y'] = xx + oo.x
    const newHole = adjustHoleDirection(hole, 90 * 1)
    Object.assign(holes[h], newHole)
  }

  // slots
  for (var s in slots) {
    var slot = slots[s]
    var pt1 = slot['pt1']
    var pt2 = slot['pt2']
    var opt1 = slot['opt1']
    var opt2 = slot['opt2']

    var x1 = pt1['x'] - o.x
    var y1 = pt1['y'] - o.y
    var x2 = pt2['x'] - o.x
    var y2 = pt2['y'] - o.y

    var xx1 = opt1['x'] - oo.x
    var yy1 = opt1['y'] - oo.y
    var xx2 = opt2['x'] - oo.x
    var yy2 = opt2['y'] - oo.y

    pt1['x'] = -y1 + o.y
    pt1['y'] = x1 + o.x
    pt2['x'] = -y2 + o.y
    pt2['y'] = x2 + o.x

    opt1['x'] = -yy1 + oo.y
    opt1['y'] = xx1 + oo.x
    opt2['x'] = -yy2 + oo.y
    opt2['y'] = xx2 + oo.x
  }

  // handleSlopes
  for (var s in handleSlopes) {
    var slope = handleSlopes[s]
    var pt1 = slope['pt1']
    var pt2 = slope['pt2']
    var opt1 = slope['opt1']
    var opt2 = slope['opt2']

    var x1 = pt1['x'] - o.x
    var y1 = pt1['y'] - o.y
    var x2 = pt2['x'] - o.x
    var y2 = pt2['y'] - o.y

    var xx1 = opt1['x'] - oo.x
    var yy1 = opt1['y'] - oo.y
    var xx2 = opt2['x'] - oo.x
    var yy2 = opt2['y'] - oo.y

    pt1['x'] = -y1 + o.y
    pt1['y'] = x1 + o.x
    pt2['x'] = -y2 + o.y
    pt2['y'] = x2 + o.x

    opt1['x'] = -yy1 + oo.y
    opt1['y'] = xx1 + oo.x
    opt2['x'] = -yy2 + oo.y
    opt2['y'] = xx2 + oo.x
  }

  // sholes
  for (var k in sholes) {
    var shole = sholes[k]
    var side = shole['side']
    var ocenter = shole['ocenter']
    x = ocenter['x'] - oo.x
    y = ocenter['y'] - oo.y
    ocenter['x'] = -y + oo.y
    ocenter['y'] = x + oo.x

    if (ncSettings.xyReverse) {
      side = parseInt(side) - 1
    } else {
      side = parseInt(side) - 1
    }
    if (side <= 0) {
      side = 4
    }
    if (side >= 5) {
      side = 1
    }

    shole['side'] = side
  }

  // sslots
  for (var j in sslots) {
    var sslot = sslots[j]
    var side = sslot['side']
    var opt1 = sslot['opt1']
    var opt2 = sslot['opt2']

    x1 = opt1['x'] - oo.x
    y1 = opt1['y'] - oo.y
    x2 = opt2['x'] - oo.x
    y2 = opt2['y'] - oo.y

    opt1['x'] = -y1 + oo.y
    opt1['y'] = x1 + oo.x
    opt2['x'] = -y2 + oo.y
    opt2['y'] = x2 + oo.x

    if (ncSettings.xyReverse) {
      side = parseInt(side) - 1
    } else {
      side = parseInt(side) - 1
    }
    if (side <= 0) {
      side = 4
    }
    if (side >= 5) {
      side = 1
    }

    sslot['side'] = side
  }

  sholeInfo = generatorSideHoleSlot([
    ...sholes,
    ...holes.filter((hole) => isSideMachineHole(hole) && hole.side == 1),
  ])
  sholeInfoF = generatorSideHoleSlot(
    holes.filter((hole) => isSideMachineHole(hole) && hole.side == -1),
    'upDownFlip'
  )
  sslotInfo = generatorSideHoleSlot([
    ...sslots,
    ...slots.filter((slot) => isSideMachineSlot(slot) && slot.side == 1),
  ])
  sslotInfoF = generatorSideHoleSlot(
    slots.filter((slot) => isSideMachineSlot(slot) && slot.side == -1),
    'upDownFlip'
  )

  // edgeInfo
  var left = edgeInfo.substring(
    edgeInfo.indexOf('←') + 1,
    edgeInfo.indexOf('↓')
  )
  var bottom = edgeInfo.substring(
    edgeInfo.indexOf('↓') + 1,
    edgeInfo.indexOf('→')
  )
  var right = edgeInfo.substring(
    edgeInfo.indexOf('→') + 1,
    edgeInfo.indexOf('↑')
  )
  var top = edgeInfo.substring(edgeInfo.indexOf('↑') + 1)
  edgeInfo = '←' + bottom + '↓' + right + '→' + top + '↑' + left
  lightEdgeInfo = dealEdgeInfoChange(lightEdgeInfo, 'rotate90')
  model_front_edge = dealFormerEdgeChange(
    model_front_edge,
    'rotate90',
    'former'
  )
  doorOpenSide = dealFormerEdgeChange(doorOpenSide, 'rotate90', 'doorOpenSide')

  part['rect'] = rect
  part['oRect'] = oRect
  part['startX'] = startX
  part['startY'] = startY
  part['path'] = paths
  part['oPath'] = oPaths
  part['cutCurve'] = cutCurve
  part['cutCurveEx'] = cutCurveEx
  part['realCurve'] = realCurve
  part['realCurveEx'] = realCurveEx
  part['holes'] = holes
  part['slots'] = slots
  part['sholes'] = sholes
  part['sslots'] = sslots
  part['realRect'] = realRect
  part['edgeInfo'] = edgeInfo
  part['sholeInfo'] = sholeInfo
  part['sholeInfoF'] = sholeInfoF
  part['sslotInfoF'] = sslotInfoF
  part['sslotInfo'] = sslotInfo
  part['curveHoles'] = curveHoles
  part['millInfo'] = millInfo
  part['oriCurveHoles'] = oriCurveHoles
  part['lightEdgeInfo'] = lightEdgeInfo
  part['model_front_edge'] = model_front_edge
  part['doorOpenSide'] = doorOpenSide

  // if (userChangedList.indexOf(part['plankNum']) === -1)
  //   userChangedList.push(part['plankNum'])

  part = dealPlankHoleSlotCSide([part])[0]

  sholeInfo = generatorSideHoleSlot([
    ...sholes,
    ...holes.filter((hole) => isSideMachineHole(hole) && hole.side == 1),
  ])
  sholeInfoF = generatorSideHoleSlot(
    holes.filter((hole) => isSideMachineHole(hole) && hole.side == -1),
    'upDownFlip'
  )
  sslotInfo = generatorSideHoleSlot([
    ...sslots,
    ...slots.filter((slot) => isSideMachineSlot(slot) && slot.side == 1),
  ])
  sslotInfoF = generatorSideHoleSlot(
    slots.filter((slot) => isSideMachineSlot(slot) && slot.side == -1),
    'upDownFlip'
  )

  part['sholeInfo'] = sholeInfo
  part['sholeInfoF'] = sholeInfoF
  part['sslotInfoF'] = sslotInfoF
  part['sslotInfo'] = sslotInfo
}
export function rotatePartInternal(part) {
  if (!part) {
    return
  }

  var rect = part['rect']
  var oRect = part['oRect']
  var startX = part['startX']
  var startY = part['startY']
  var paths = part['path']
  var cutCurve = part['cutCurve']
  var cutCurveEx = part['cutCurveEx']
  var realCurve = part['realCurve']
  var realCurveEx = part['realCurveEx']
  var holes = part['holes']
  var slots = part['slots']
  var sholes = part['sholes']
  var sslots = part['sslots']
  var realRect = part['realRect']
  var edgeInfo = part['edgeInfo']
  var sholeInfo = part['sholeInfo']
  var sholeInfoF = part['sholeInfoF']
  var sslotInfoF = part['sslotInfoF']
  var sslotInfo = part['sslotInfo']
  var oPaths = part['oPath']
  var curveHoles = part['curveHoles']
  var millInfo = part['millInfo']
  var oriCurveHoles = part['oriCurveHoles']
  let lightEdgeInfo = part['lightEdgeInfo']
  let model_front_edge = part['model_front_edge']
  let doorOpenSide = part['doorOpenSide']

  // 顺时针旋转

  // 板件中心点
  // var o = Qt.point(rect.width / 2, rect.height / 2);
  var o = {
    x: rect.width / 2,
    y: rect.height / 2,
  }

  // var oo = Qt.point(oRect.width / 2, oRect.height / 2);
  var oo = {
    x: oRect.width / 2,
    y: oRect.height / 2,
  }

  // startX\startY
  startX = startX + o.x - rect.height / 2
  startY = startY + o.y - rect.width / 2
  // rect
  // rect = Qt.rect(0, 0, rect.height, rect.width);
  rect = {
    x: 0,
    y: 0,
    width: rect.height,
    height: rect.width,
  }

  oRect = {
    x: 0,
    y: 0,
    width: oRect.height,
    height: oRect.width,
  }

  // realRect = Qt.rect(0, 0, realRect.height, realRect.width);
  realRect = {
    x: 0,
    y: 0,
    width: realRect.height,
    height: realRect.width,
  }

  // paths
  for (var i in paths) {
    var path = paths[i]
    for (var j in path) {
      var p = path[j]
      var x = p['x'] - o.x
      var y = p['y'] - o.y

      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }

  // paths
  for (var i in oPaths) {
    var path = oPaths[i]
    for (var j in path) {
      var p = path[j]
      var x = p['x'] - oo.x
      var y = p['y'] - oo.y

      p['x'] = -y + oo.y
      p['y'] = x + oo.x
    }
  }

  for (var i in curveHoles) {
    var path = curveHoles[i]['path']
    for (var j in path) {
      var p = path[j]
      var x = p['x'] - o.x
      var y = p['y'] - o.y

      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }
  if (millInfo) {
    for (let i in millInfo) {
      const shape = millInfo[i]['shape']
      for (let j in shape) {
        const p = shape[j]
        const x = p['x'] - oo.x
        const y = p['y'] - oo.y

        p['x'] = -y + oo.y
        p['y'] = x + oo.x
      }
    }
  }

  for (var i in oriCurveHoles) {
    var path = oriCurveHoles[i]['path']
    for (var j in path) {
      var p = path[j]
      var x = p['x'] - o.x
      var y = p['y'] - o.y

      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }

  for (var i in cutCurve) {
    p = cutCurve[i]
    x = p['x'] - o.x
    y = p['y'] - o.y

    p['x'] = -y + o.y
    p['y'] = x + o.x
  }

  for (var i in cutCurveEx) {
    path = cutCurveEx[i]
    for (var j in path) {
      p = path[j]
      x = p['x'] - o.x
      y = p['y'] - o.y
      p['x'] = -y + o.y
      p['y'] = x + o.x
    }
  }

  for (var i in realCurve) {
    p = realCurve[i]

    x = p['x'] - oo.x
    y = p['y'] - oo.y

    p['x'] = -y + oo.y
    p['y'] = x + oo.x
  }

  for (var i in realCurveEx) {
    path = realCurveEx[i]
    for (var j in path) {
      p = path[j]

      x = p['x'] - oo.x
      y = p['y'] - oo.y

      p['x'] = -y + oo.y
      p['y'] = x + oo.x
    }
  }

  // holes

  for (var h in holes) {
    var hole = holes[h]
    var center = hole['center']
    var ocenter = hole['ocenter']

    var x = center['x'] - o.x
    var y = center['y'] - o.y

    center['x'] = -y + o.y
    center['y'] = x + o.x

    var x = ocenter['x'] - oo.x
    var y = ocenter['y'] - oo.y

    ocenter['x'] = -y + oo.y
    ocenter['y'] = x + oo.x
    const newHole = adjustHoleDirection(hole, 90 * 1)
    Object.assign(holes[h], newHole)
  }

  // slots
  for (var s in slots) {
    var slot = slots[s]
    var pt1 = slot['pt1']
    var pt2 = slot['pt2']

    var x1 = pt1['x'] - o.x
    var y1 = pt1['y'] - o.y
    var x2 = pt2['x'] - o.x
    var y2 = pt2['y'] - o.y

    pt1['x'] = -y1 + o.y
    pt1['y'] = x1 + o.x
    pt2['x'] = -y2 + o.y
    pt2['y'] = x2 + o.x

    var opt1 = slot['opt1']
    var opt2 = slot['opt2']

    var x1 = opt1['x'] - oo.x
    var y1 = opt1['y'] - oo.y
    var x2 = opt2['x'] - oo.x
    var y2 = opt2['y'] - oo.y

    opt1['x'] = -y1 + oo.y
    opt1['y'] = x1 + oo.x
    opt2['x'] = -y2 + oo.y
    opt2['y'] = x2 + oo.x
  }

  // sholes
  for (var k in sholes) {
    var shole = sholes[k]
    var side = shole['side']
    var ocenter = shole['ocenter']

    x = ocenter['x'] - oo.x
    y = ocenter['y'] - oo.y
    ocenter['x'] = -y + oo.y
    ocenter['y'] = x + oo.x

    side = parseInt(side) - 1
    if (side <= 0) {
      side = 4
    }

    shole['side'] = side
  }

  // sslots
  for (var j in sslots) {
    var sslot = sslots[j]
    var side = sslot['side']
    var opt1 = sslot['opt1']
    var opt2 = sslot['opt2']

    x1 = opt1['x'] - oo.x
    y1 = opt1['y'] - oo.y
    x2 = opt2['x'] - oo.x
    y2 = opt2['y'] - oo.y

    opt1['x'] = -y1 + oo.y
    opt1['y'] = x1 + oo.x
    opt2['x'] = -y2 + oo.y
    opt2['y'] = x2 + oo.x
    side = parseInt(side) - 1
    if (side <= 0) {
      side = 4
    }

    sslot['side'] = side
  }

  sholeInfo = generatorSideHoleSlot([
    ...sholes,
    ...holes.filter((hole) => isSideMachineHole(hole) && hole.side == 1),
  ])
  sholeInfoF = generatorSideHoleSlot(
    holes.filter((hole) => isSideMachineHole(hole) && hole.side == -1),
    'upDownFlip'
  )
  sslotInfo = generatorSideHoleSlot([
    ...sslots,
    ...slots.filter((slot) => isSideMachineSlot(slot) && slot.side == 1),
  ])
  sslotInfoF = generatorSideHoleSlot(
    slots.filter((slot) => isSideMachineSlot(slot) && slot.side == -1),
    'upDownFlip'
  )

  // edgeInfo
  var left = edgeInfo.substring(
    edgeInfo.indexOf('←') + 1,
    edgeInfo.indexOf('↓')
  )
  var bottom = edgeInfo.substring(
    edgeInfo.indexOf('↓') + 1,
    edgeInfo.indexOf('→')
  )
  var right = edgeInfo.substring(
    edgeInfo.indexOf('→') + 1,
    edgeInfo.indexOf('↑')
  )
  var top = edgeInfo.substring(edgeInfo.indexOf('↑') + 1)
  edgeInfo = '←' + bottom + '↓' + right + '→' + top + '↑' + left
  lightEdgeInfo = dealEdgeInfoChange(lightEdgeInfo, 'rotate90')
  model_front_edge = dealFormerEdgeChange(
    model_front_edge,
    'rotate90',
    'former'
  )
  doorOpenSide = dealFormerEdgeChange(doorOpenSide, 'rotate90', 'doorOpenSide')

  part['rect'] = rect
  part['oRect'] = oRect
  part['startX'] = startX
  part['startY'] = startY
  part['path'] = paths
  part['oPath'] = oPaths
  part['cutCurve'] = cutCurve
  part['cutCurveEx'] = cutCurveEx
  part['realCurve'] = realCurve
  part['realCurveEx'] = realCurveEx
  part['holes'] = holes
  part['slots'] = slots
  part['sholes'] = sholes
  part['sslots'] = sslots
  part['realRect'] = realRect
  part['edgeInfo'] = edgeInfo
  part['sholeInfo'] = sholeInfo
  part['sholeInfoF'] = sholeInfoF
  part['sslotInfoF'] = sslotInfoF
  part['sslotInfo'] = sslotInfo
  part['curveHoles'] = curveHoles
  part['millInfo'] = millInfo
  part['oriCurveHoles'] = oriCurveHoles
  part['lightEdgeInfo'] = lightEdgeInfo
  part['model_front_edge'] = model_front_edge
  part['doorOpenSide'] = doorOpenSide
  // if (userChangedList.indexOf(part['plankNum']) === -1)
  //   userChangedList.push(part['plankNum'])
}

function handleLayoutPlans(index) {
  var result = []
  var tmpValue = {}
  var tmpResult = {}
  if (index < 2) {
    tmpResult = layoutPlans[0]
  } else {
    if (layoutPlans.length > 1) {
      tmpResult = layoutPlans[Math.floor(index / 2)]
      index = index % 2
    }
  }

  for (var key in tmpResult) {
    result = result.ggConcat(handlePartialResult(tmpResult[key], index))
  }

  return result
}

function handlePartialResult(partialResult, index) {
  var tmpValue = {}
  var holeInfo = partialResult['holeInfo']
  var dataLst = partialResult['data']
  if (dataLst.length < index + 1) {
    return []
  } else {
    tmpValue = dataLst[index]
    for (var tmpKey in holeInfo) {
      if (tmpValue.hasOwnProperty(tmpKey)) {
        tmpValue[tmpKey]['holes'] = holeInfo[tmpKey]['holes']
      } else {
        tmpValue[tmpKey] = holeInfo[tmpKey]
      }
    }
    if (partialResult['rollCount'])
      rollCount += partialResult['rollCount'][index]
  }
  var retValues = []
  for (var key in tmpValue) {
    retValues.push(tmpValue[key])
  }

  return retValues
}

function getRollCount(layoutResult) {
  var rollCount = 0

  for (var i in layoutResult) {
    var stock = layoutResult[i]
    var parts = stock['parts']

    if (isNeedRoll(parts)) {
      rollCount++
    }
  }

  return rollCount
}

function isNeedRoll(parts) {
  var sp = 0,
    sn = 0
  for (var p in parts) {
    var part = parts[p]
    var holes = part['holes']
    var slots = part['slots']
    var sslots = part['sslots']

    for (var j in holes) {
      var hole = holes[j]
      var hDeep = hole['deep']
      var hSide = hole['side']

      if (hDeep > 0) {
        if (hSide == 1) {
          sp++
        } else {
          sn++
        }
      }
    }

    for (var j in sslots) {
      var sslot = sslots[j]
      var ssDeep = sslot['deep']
      var ssSide = sslot['side']

      if (ssDeep > 0) {
        if (ssSide == 1) {
          sp++
        } else {
          sn++
        }
      }
    }

    for (var j in slots) {
      var slot = slots[j]
      var sDeep = slot['deep']
      var sSide = slot['side']

      if (sDeep > 0) {
        if (sSide == 1) {
          sp++
        } else {
          sn++
        }
      }
    }
  }

  if (sp * sn > 0) {
    return true
  }
  return false
}

function sortTheStock(layoutResult) {
  var allStock = {}
  var curKeys = []

  for (var i in layoutResult) {
    var stock = layoutResult[i]
    var parts = stock['parts']
    var curPlankWidth = stock['plankWidth']
    var curPlankHeight = stock['plankHeight']
    var curKey =
      stock['texture'] + ':' + stock['matCode'] + ':' + stock['thick']

    var area = 0
    for (var p in parts) {
      var part = parts[p]
      var realRect = part['realRect']
      var realW = realRect.width
      var realH = realRect.height

      area += realW * realH
    }
    if (isNeedRoll(parts)) {
      stock['needRoll'] = true
    } else {
      stock['needRoll'] = false
    }

    stock['utilization'] = (area * 100) / (curPlankWidth * curPlankHeight)
    var tmpStocks = []
    if (allStock.hasOwnProperty(curKey)) {
      tmpStocks = allStock[curKey]
      tmpStocks.push(stock)
    } else {
      tmpStocks = []
      tmpStocks.push(stock)
      curKeys.push(curKey)
    }
    allStock[curKey] = tmpStocks
  }
  if (curKeys instanceof Array && curKeys.length > 0) {
    var curLength = curKeys.length
    var sameArr = true
    var tmpcurKeys = globalJSTool.mergeSort(curKeys, 'plankThick', null) //板件分类按厚度排序
    for (var i in curKeys) {
      if (tmpcurKeys.indexOf(curKeys[i]) === -1) {
        sameArr = false
        break
      }
    }
    if (
      tmpcurKeys instanceof Array &&
      tmpcurKeys.length == curKeys.length &&
      sameArr
    ) {
      curKeys = deepCopy(tmpcurKeys)
      var sKey = 0
      var sThick = Number(curKeys[0].split(':')[2])
      for (var j in curKeys) {
        if (
          Number(j) === curKeys.length - 1 ||
          sThick > Number(curKeys[Number(j)].split(':')[2])
        ) {
          var sliceEnd =
            Number(j) === curKeys.length - 1 &&
            sThick === Number(curKeys[Number(j)].split(':')[2])
              ? Number(j) + 1
              : Number(j)
          sThick = Number(curKeys[Number(j)].split(':')[2])
          if (sliceEnd - sKey > 1) {
            var mergeResult = globalJSTool.mergeSort(
              curKeys.slice(sKey, sliceEnd),
              'stockCount',
              allStock
            ) //对已经按厚度排序的板件分类，同一厚度的再按数量排序
            var k = 0
            while (k < sliceEnd - sKey) {
              curKeys.splice(sKey + k, 1, mergeResult[k])
              k++
            }
          }
          sKey = sliceEnd
        }
      }
    } else {
      console.error(
        '---------------------------注意：板件按板厚数量排序失败，恢复初始排序-------------------------'
      )
    }
  }
  layoutResult = []
  purchaseList = []
  for (var i in curKeys) {
    var stocks = allStock[curKeys[i]]
    stocks.sort(function (firstStock, secondStock) {
      var needRollA = firstStock['needRoll']
      var utilizationA = firstStock['utilization']

      var needRollB = secondStock['needRoll']
      var utilizationB = secondStock['utilization']
      if (needRollA === needRollB) {
        return utilizationB - utilizationA
      } else {
        if (needRollA == true) {
          return -1
        }
        return 1
      }
    })

    var paramArr = curKeys[i].split(':')
    var param = {
      //采购单大板信息获取
      thick: paramArr[2],
      color: paramArr[0],
      material: paramArr[1],
      count: stocks.length,
    }

    for (var j in stocks) {
      var needRollA = stocks[j]['needRoll']
      var utilizationA = stocks[j]['utilization']
      layoutResult.push(stocks[j])
      if (stocks[j]['surplusInfo']) {
        param['count'] -= 1
      } else {
        param['spec'] = stocks[j]['plankWidth'] + '*' + stocks[j]['plankHeight']
      }
    }
    purchaseList.push(param)
  }
  return layoutResult
}

//当槽靠在大板的边上时旋转板件，避免拉槽时损坏板件
export function rotatePlankWhenSlotAtTheEdge(part, plankWidth, plankHeight) {
  var holes = part['holes']
  var slots = part['slots']
  var path = part['path']
  var oPath = part['oPath']
  var realCurve = part['realCurve']
  var realCurveEx = part['realCurveEx']
  var cutCurve = part['cutCurve']
  var cutCurveEx = part['cutCurveEx']
  var rect = part['rect']
  var oRect = part['oRect']
  var startX = part['startX']
  var startY = part['startY']
  var sholes = part['sholes']
  var sslots = part['sslots']
  var width = rect['width']
  var height = rect['height']
  var oWidth = oRect['width']
  var oHeight = oRect['height']
  var sholeInfo = part['sholeInfo']
  var sholeInfoF = part['sholeInfoF']
  var sslotInfo = part['sslotInfo']
  var sslotInfoF = part['sslotInfoF']
  var edgeInfo = part['edgeInfo']
  var curveHoles = part['curveHoles']
  var millInfo = part['millInfo']
  var oriCurveHoles = part['oriCurveHoles']
  let lightEdgeInfo = part['lightEdgeInfo']
  let model_front_edge = part['model_front_edge']
  let doorOpenSide = part['doorOpenSide']

  var needRota = false

  var safeDis = 50

  for (var i in slots) {
    var slot = slots[i]
    var pt1 = slot['pt1']
    var pt2 = slot['pt2']
    if (Math.abs(pt1.x - pt2.x) <= 0.000001) {
      if (startX + pt1.x < safeDis) {
        needRota = true
        break
      } else if (startX + pt1.x + safeDis > plankWidth) {
        needRota = true
        break
      }
    } else if (Math.abs(pt1.y - pt2.y) <= 0.000001) {
      if (startY + pt1.y < safeDis) {
        needRota = true
        break
      } else if (startY + pt1.y + safeDis > plankHeight) {
        needRota = true
        break
      }
    }
  }

  if (needRota) {
    if (path) {
      for (var pInd in path) {
        var itemPath = path[pInd]
        for (var iPath in itemPath) {
          var pathPt = itemPath[iPath]
          var pathX = pathPt.x
          var pathY = pathPt.y

          var newX = width - pathX
          var newY = height - pathY
          var newPt = {
            x: newX,
            y: newY,
          }
          itemPath[iPath] = newPt
        }
        path[pInd] = itemPath
      }
      part['path'] = path
    }
    if (curveHoles) {
      for (var hole in curveHoles) {
        var holePath = curveHoles[hole]['path']
        for (var point in holePath) {
          var p = holePath[point]
          var px = p.x
          var py = p.y

          var newx = width - px
          var newy = height - py
          p.x = newx
          p.y = newy
        }
      }
    }
    if (millInfo) {
      for (var idx in millInfo) {
        const slotPath = millInfo[idx]['shape']
        for (let point in slotPath) {
          const p = slotPath[point]
          const px = p.x
          const py = p.y

          const newx = oWidth - px
          const newy = oHeight - py
          p.x = newx
          p.y = newy
        }
      }
    }

    if (oriCurveHoles) {
      for (var hole in oriCurveHoles) {
        var holePath = oriCurveHoles[hole]['path']
        for (var point in holePath) {
          var p = holePath[point]
          var px = p.x
          var py = p.y

          var newx = width - px
          var newy = height - py
          p.x = newx
          p.y = newy
        }
      }
    }

    function rotateCurve(curve, width, height) {
      var lastB = -1000
      curve.reverse()
      for (iPath in curve) {
        pathPt = curve[iPath]
        pathX = pathPt.x
        pathY = pathPt.y

        newX = width - pathX
        newY = height - pathY
        pathPt.x = newX
        pathPt.y = newY
        curve[iPath] = pathPt

        var tmpB = lastB
        if (pathPt.hasOwnProperty('b')) {
          lastB = pathPt['b']
        } else {
          lastB = -1000
        }
        if (tmpB !== -1000) {
          pathPt['b'] = -tmpB
        } else {
          delete pathPt['b']
        }
      }
      if (lastB !== -1000) {
        curve[0]['b'] = -lastB
      }
      return curve
    }

    function rotateCurveEx(curve, width, height) {
      for (opInd in curve) {
        itemOPath = curve[opInd]
        itemOPath.reverse()
        var lastB = -1000
        for (iOPath in itemOPath) {
          oPathPt = itemOPath[iOPath]
          oPathX = oPathPt.x
          oPathY = oPathPt.y

          newX = width - oPathX
          newY = height - oPathY
          oPathPt.x = newX
          oPathPt.y = newY
          itemOPath[iOPath] = oPathPt

          var tmpB = lastB
          if (oPathPt.hasOwnProperty('b')) {
            lastB = oPathPt['b']
          } else {
            lastB = -1000
          }
          if (tmpB !== -1000) {
            oPathPt['b'] = -tmpB
          } else {
            delete oPathPt['b']
          }
        }
        if (lastB !== -1000) {
          itemOPath[0]['b'] = -lastB
        }
        curve[opInd] = itemOPath
      }
      return curve
    }

    if (realCurve) {
      part['realCurve'] = rotateCurve(realCurve, oWidth, oHeight)
    }

    if (realCurveEx) {
      part['realCurveEx'] = rotateCurveEx(realCurveEx, oWidth, oHeight)
    }

    if (cutCurve) {
      part['cutCurve'] = rotateCurve(cutCurve, width, height)
    }

    if (cutCurveEx) {
      part['cutCurveEx'] = rotateCurveEx(cutCurveEx, width, height)
    }

    if (oPath) {
      for (var opInd in oPath) {
        var itemOPath = oPath[opInd]
        for (var iOPath in itemOPath) {
          var oPathPt = itemOPath[iOPath]
          var oPathX = oPathPt.x
          var oPathY = oPathPt.y

          newX = oWidth - oPathX
          newY = oHeight - oPathY
          newPt = {
            x: newX,
            y: newY,
          }
          itemOPath[iOPath] = newPt
        }
        oPath[opInd] = itemOPath
      }
      part['oPath'] = oPath
    }

    for (var hInd in holes) {
      var hole = holes[hInd]
      var center = hole['center']
      center['x'] = width - center['x']
      center['y'] = height - center['y']
      hole['center'] = center

      var ocenter = hole['ocenter']
      ocenter['x'] = oWidth - ocenter['x']
      ocenter['y'] = oHeight - ocenter['y']
      hole['ocenter'] = ocenter

      holes[hInd] = hole
    }
    part['holes'] = holes

    for (var sInd in slots) {
      var slot = slots[sInd]
      var pt1 = slot['pt1']
      var pt2 = slot['pt2']
      pt1['x'] = width - pt1['x']
      pt2['x'] = width - pt2['x']
      pt1['y'] = height - pt1['y']
      pt2['y'] = height - pt2['y']
      slot['pt2'] = pt2
      slot['pt1'] = pt1

      var opt1 = slot['opt1']
      var opt2 = slot['opt2']
      opt1['x'] = oWidth - opt1['x']
      opt2['x'] = oWidth - opt2['x']
      opt1['y'] = oHeight - opt1['y']
      opt2['y'] = oHeight - opt2['y']
      slot['opt2'] = opt2
      slot['opt1'] = opt1

      slots[sInd] = slot
    }
    part['slots'] = slots

    // sholes
    for (var k in sholes) {
      var shole = sholes[k]
      var side = shole['side']
      var ocenter = shole['ocenter']

      ocenter['x'] = oWidth - ocenter['x']
      ocenter['y'] = oHeight - ocenter['y']
      shole['ocenter'] = ocenter

      side = parseInt(side) - 1
      if (side <= 0) {
        side = 4
      }
      side = parseInt(side) - 1
      if (side <= 0) {
        side = 4
      }
      shole['side'] = side
    }

    // sslots
    for (var j in sslots) {
      var sslot = sslots[j]
      var opt1 = sslot['opt1']
      var opt2 = sslot['opt2']
      var side = sslot['side']

      opt1['x'] = oWidth - opt1['x']
      opt2['x'] = oWidth - opt2['x']
      opt1['y'] = oHeight - opt1['y']
      opt2['y'] = oHeight - opt2['y']
      sslot['opt2'] = opt2
      sslot['opt1'] = opt1

      side = parseInt(side) - 1
      if (side <= 0) {
        side = 4
      }
      side = parseInt(side) - 1
      if (side <= 0) {
        side = 4
      }
      sslot['side'] = side
    }

    sholeInfo = generatorSideHoleSlot([
      ...sholes,
      ...holes.filter((hole) => isSideMachineHole(hole) && hole.side == 1),
    ])
    sholeInfoF = generatorSideHoleSlot(
      holes.filter((hole) => isSideMachineHole(hole) && hole.side == -1),
      'upDownFlip'
    )
    sslotInfo = generatorSideHoleSlot([
      ...sslots,
      ...slots.filter((slot) => isSideMachineSlot(slot) && slot.side == 1),
    ])
    sslotInfoF = generatorSideHoleSlot(
      slots.filter((slot) => isSideMachineSlot(slot) && slot.side == -1),
      'upDownFlip'
    )

    part['sholes'] = sholes
    part['sslots'] = sslots
    part['sholeInfo'] = sholeInfo
    part['sholeInfoF'] = sholeInfoF
    part['sslotInfo'] = sslotInfo
    part['sslotInfoF'] = sslotInfoF

    var left = edgeInfo.substring(
      edgeInfo.indexOf('←') + 1,
      edgeInfo.indexOf('↓')
    )
    var bottom = edgeInfo.substring(
      edgeInfo.indexOf('↓') + 1,
      edgeInfo.indexOf('→')
    )
    var right = edgeInfo.substring(
      edgeInfo.indexOf('→') + 1,
      edgeInfo.indexOf('↑')
    )
    var top = edgeInfo.substring(edgeInfo.indexOf('↑') + 1)
    edgeInfo = '←' + right + '↓' + top + '→' + left + '↑' + bottom
    lightEdgeInfo = dealEdgeInfoChange(lightEdgeInfo, 'rotate180')
    model_front_edge = dealFormerEdgeChange(
      model_front_edge,
      'rotate180',
      'former'
    )
    doorOpenSide = dealFormerEdgeChange(
      doorOpenSide,
      'rotate180',
      'doorOpenSide'
    )

    part['edgeInfo'] = edgeInfo
    part['lightEdgeInfo'] = lightEdgeInfo
    part['model_front_edge'] = model_front_edge
    part['doorOpenSide'] = doorOpenSide
  }
}

function getYunLayoutPercent(oKey, lResult, yResult) {
  var resultData = yResult['result_data']
  if (!resultData) return
  var partsData = resultData[oKey]
  var usedRate = partsData['used_rate']
  var lData = lResult['data']
  for (var i in lData) {
    var part = lData[i]
    var ttt = Object.keys(part)
  }

  for (var u in usedRate) {
    var rates = usedRate[u]
    var stockNum = rates['stock_num']
    var rate = rates['rate']

    var pKey = oKey + ':' + stockNum

    for (var i in lData) {
      var part = lData[i]
      if (part.hasOwnProperty(pKey)) {
        part[pKey]['usedRate'] = rate
        lData[i] = part
        lResult['data'] = lData
      }
    }
  }
}

//判断正/反面是否有孔槽
function hasRevPlankHoleOrSlot(plk, value) {
  var flag = false
  var holes = plk['holes']
  for (var i in holes) {
    var hole = holes[i]
    var side = hole['side']
    if (side === value) {
      flag = true
      break
    }
  }
  if (!flag) {
    var slots = plk['slots']
    for (var i in slots) {
      var slot = slots[i]
      var side = slot['side']
      if (side === value) {
        flag = true
        break
      }
    }
  }
  return flag
}

// 顺时针
const CLOCK_WISE = 1
// 逆时针
const ANTI_CLOCK_WISE = -1

// 顺时针对应的关系
const clockwiseMap = new Map([
  [1, 4],
  [2, 1],
  [3, 2],
  [4, 3],
])
// 逆时针对应的关系
const anticlockwiseMap = new Map([
  [1, 2],
  [2, 3],
  [3, 4],
  [4, 1],
])

// 不同孔方向在旋转时的对应关系
const directionMap = {
  [CLOCK_WISE]: clockwiseMap, // 顺时针
  [ANTI_CLOCK_WISE]: anticlockwiseMap, // 逆时针
}

// 旋转孔的方向
export function adjustHoleDirection(hole, angle = 90) {
  // 如果角度为0或不是90的倍数,或没有others属性,直接返回
  if (angle === 0 || angle % 90 !== 0 || !hole?.others) {
    return hole
  }

  const { direction } = hole.others
  const rotationType = angle > 0 ? 1 : -1 // 1表示顺时针,-1表示逆时针

  // 计算需要旋转多少次90度
  const rotationCount = Math.abs(angle / 90) % 4

  // 多次旋转direction
  let newDirection = direction
  for (let i = 0; i < rotationCount; i++) {
    newDirection = directionMap[rotationType].get(newDirection)
  }

  return {
    ...hole,
    others: {
      ...hole.others,
      direction: newDirection,
    },
  }
}
