import {
  getPreferencesSetting,
  savePreferencesSetting,
} from '@/apis/materialList'
import store from '@/store'
import { TaskStatus, createTask } from '@/store/modules/progressNotice'
import { EventBusKey } from '@/util/bus'
import EventBus from '@/util/bus'
import { translate } from '@/util/commonFun'
import { message } from 'ant-design-vue'
import { debounce } from 'lodash'
import type { Module } from 'vuex'

import type { IRootState } from '../../types'
import type { IStore } from './types'

const preferencesSetting: Module<IStore, IRootState> = {
  namespaced: true,
  state: {
    setting: {
      isDeductionBujian: false,
      isDeductionPaibanStore: false,
      isDeductionSurplus: false,
      isDeductionBaseMaterial: true,
      genSmallPlankLabel: true,
      labelSortWay: 'default',
    },
    isLoading: true,
    isLoadDone: false,
  },
  mutations: {
    setSetting(state, val) {
      state.setting = val
    },
    setIsLoading(state, val) {
      state.isLoading = val
    },
    setIsLoadDone(state, val) {
      state.isLoadDone = val
    },
  },
  actions: {
    async updateSetting({ state }) {
      if (!state.setting || !Object.keys(state).length) return
      await savePreferencesSetting(state.setting as any)
    },
    /** 此方法不可轻易调用 */
    async getSetting({ commit, state }) {
      const { task, switchTaskStatus, delTask } = createTask(
        {
          taskTitle: translate('common.preferenceLoadTip'),
          isAutoClose: false,
          errorMsg: translate('common.preferenceLoadErrTip'),
        },
        1
      )
      try {
        commit('setIsLoading', true)
        const result = await getPreferencesSetting(
          window.sessionStorage.getItem('thinkerx_material') as any,
          store.state.userInfo.platform
        )
        commit('setIsLoading', false)
        if (result.status !== 1) {
          switchTaskStatus(TaskStatus.rejected)
          return
        }
        commit('setIsLoadDone', true)
        const setting = result.data
        const init = (key: keyof IStore['setting'], value: any) => {
          setting[key] = value as never
        }
        // 部分值的初始化
        init('isBujianRecommend', !!setting.isBujianRecommend)
        init('isPaibanStoreRecommend', !!setting.isPaibanStoreRecommend)
        init('isSurplusRecommend', !!setting.isSurplusRecommend)
        init('isDeductionBaseMaterial', !!setting.isDeductionBaseMaterial)
        init('isDeductionBujian', !!setting.isDeductionBujian)
        init('isDeductionPaibanStore', !!setting.isDeductionPaibanStore)
        init('isDeductionSurplus', !!setting.isDeductionSurplus)
        init('genSmallPlankLabel', !!setting.genSmallPlankLabel)
        init('exPaibanTipShow', !!setting.exPaibanTipShow)
        commit('setSetting', setting)
        task(1)
        // 任务完成后清理列表
        delTask()
        EventBus.$emit(EventBusKey.PREFERENCES_UPDATE, {
          data: state.setting,
          isErr: false,
        })
      } catch (error) {
        commit('setIsLoading', false)
        switchTaskStatus(TaskStatus.rejected)
        EventBus.$emit(EventBusKey.PREFERENCES_UPDATE, {
          data: null,
          isErr: true,
        })
      }
    },
  },
}

type PrefCb = (payload: {
  data: IStore['setting'] | undefined
  isErr: boolean
  err?: any
}) => void

/**
 * @description 防抖的更新
 */
const updateServe = debounce(async (cb?: PrefCb) => {
  try {
    // 更新完成后直接更新服务器上的设置
    await store.dispatch('preferencesSetting/updateSetting')
    // 执行一些需要的回调函数
    cb &&
      cb({
        data: store.state.preferencesSetting?.setting,
        isErr: false,
      })
  } catch (error) {
    cb &&
      cb({
        data: undefined,
        isErr: true,
        err: error,
      })
    message.error(translate('common.preferenceSaveErr'))
  }
}, 150)
/**
 * @description 更新本地偏好设置，请不要重复的请求偏好设置接口，当偏好设置保存时请将保存的数据调用一次此方法，用于更新本地数据同时更新远程服务器数据,可只传入想要更新的内部自动会填充之前的数据
 * 这样做的原因：历史遗留问题，偏好设置之前被滥用，导致数据在多个页面多次不同的获取，为了保证改动最小则采用了本地页面更新
 * @param data 需要更新的数据
 * @param isUpdateServe 是否更新远程服务器上的数据,默认更新(留下这个兼容，但基本没有需求会有更新本地但不更新远程)
 */
export function updateLocalPreference(
  data: IStore['setting'],
  cb?: PrefCb,
  isUpdateServe = true
) {
  store.commit('preferencesSetting/setSetting', {
    ...store.state.preferencesSetting?.setting,
    ...data,
  })
  isUpdateServe && updateServe(cb)
}

export default preferencesSetting
