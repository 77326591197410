export default {
  common: {
    checkPlankIsConflict: '正在检测板件状态',
    preferenceSaveErr: '偏好设置保存异常',
    guimen: '柜门',
    preferenceLoadTip: '正在加载您的偏好设置',
    preferenceLoadErrTip:
      '偏好设置加载失败,请刷新重试,避免造成您的偏好设置错误！',
    localFileIsBeingWrite: '本地文件写入中',
    taskMonitor: '任务监控',
    downloadByType: '下载{type}',
    slotKnifeErr: '缺失槽指定加工刀具，请检查生产线设置！【{msg}】',
    anewChooseCatalog: '重新选择目录',
    downloadSetting: '下载设置',
    attention: '注意',
    unit: '块',
    defaultUse: '默认使用',
    defaultChooseUse: '默认选用',
    requestFail: '请求失败',
    downloadDoneByType: '{type}下载完成',
    sysTip: '系统提示',
    pleaseChoose: '请选择',
    confirmDelete: '确认删除',
    notDataTipByType: '暂无数据，请先{msg}',
    paginationTotalTip: '第 {current}-{page} 条/总共 {total} 条',
    chooseAmountData: '已选 {number} 条数据',
    moreOperate: '更多',
    transferByType: '转移{type}',
    clickCopy: '点击可复制{type}',
    currentLine: '当前行',
    remark: '备注',
    customFunction: '定制功能',
    limitedTimeTrial: '限免',
    startDate: '开始日期',
    endDate: '结束日期',
    startTime: '开始时间',
    endTime: '结束时间',
    lastMonth: '最近一个月',
    lastSixMonth: '最近半年',
    lastYear: '最近一年',
    searchByType: '请输入{type}查询',
    addTime: '添加时间',
    startArrange: '开始排版',
    select: '选择',
    name: '名称',
    plankNo: '板号',
    plankName: '板件名称',
    orderNo: '订单号',
    order: '订单',
    plankRemark: '板件备注',
    room: '房间',
    orderAddress: '项目地址',
    matCode: '材质',
    matCode2: '材料',
    plankMatCode: '板材材质',
    count: '数量',
    customer: '客户',
    customerInfo: '客户资料',
    factoryOrderNo: '工厂单号',
    roomName: '房间名称',
    color: '颜色',
    plankColor: '板材颜色',
    plankThick: '板材厚度',
    plankHighGloss: '高光属性',
    thick: '厚度',
    front: '正面',
    back: '反面',
    slot: '槽',
    hole: '孔',
    hsInfo: '{hole}孔{slot}槽',
    diameter: '直径',
    finishedWidth: '成品宽度',
    finishedHeight: '成品长度',
    texture: '纹理',
    normal: '竖纹',
    reverse: '横纹',
    notCare: '无纹理',
    holeSlotsStastic: '孔槽数量',
    edge: {
      title: '封边',
      info: '封边信息',
      front: '前封边',
      back: '后封边',
      left: '左封边',
      right: '右封边',
      up: '上封边',
      down: '下封边',
    },
    isSpecial: '异形',
    notSpecial: '非异形',
    isDoorPlank: '门板',
    notDoorPlank: '非门板',
    width: '宽度',
    long: '长度',
    plankWidth: '板材宽度',
    plankHeight: '板材长度',
    shortLong: '短边长度',
    shortWidth: '短边宽度',
    plankShape: '板材形状',
    detail: '详情',
    cancel: '取消',
    confirm: '确认',
    deep: '深度',
    other: '其他',
    heighGloss: '高光',
    heighGlossPlank: '高光板',
    notHeighGloss: '非高光',
    notHeighGlossPlank: '非高光板',
    saveSuccess: '保存成功',
    deleteSuccess: '删除成功',
    addSuccess: '添加成功',
    editSuccess: '修改成功',
    engraving: '雕刻机',
    electronicSaw: '电子锯',
    glassEquip: '玻璃切割机',
    labelingMachine: '贴标机',
    copy: '复制',
    paste: '粘贴',
    undo: '撤销',
    move: '移动',
    PTP: 'PTP',
    dock56FDrill: '五六面钻',
    sideHoleDrill: '侧孔机',
    combination: '组合设备',
    integration: '一体机',
    standardPlank: '标准大板',
    standardMaterial: '标准板材',
    specialMaterial: '特殊板材',
    specialPlank: '特殊大板',
    singlePlank: '单面板',
    previous: '上一个',
    plankInfo: '大板详情',
    moreResult: '更多排版方案',
    moreResultTip: '大板中有贴边加工的槽,大板将不会参与重新排版',
    ok: '确定',
    dispersePaibanTip:
      '检测到部分大板无法进行分散排版，您可以手动调整或切换排版方案，避免造成跑板！',
    next: '下一个',
    rate: '利用率',
    paibanBatch: '排版批次',
    return: '返回',
    FGNo: '合同号',
    refresh: '刷新',
    inde: '序号',
    productionLine: '生产线',
    operation: '操作',
    colorPlaceHolder: '按回车自动创建色卡',
    saveAndContinue: '保存并继续',
    reset: '重置',
    check: '查询',
    inputPlaceholder: '请输入',
    filter: '筛选',
    all: '全部',
    exportPDF: '导出PDF',
    exportPic: '导出图片',
    add: '添加',
    edit: '编辑',
    delete: '删除',
    save: '保存',
    saveSetting: '保存设置',
    isHeightGloss: '请选择是否是高光板',
    noSync: '不同步',
    sync: '同步',
    formatSuccess: '{type}成功',
    dataLoading: '数据正在加载中......',
    warmTip: '温馨提示',
    wellcome: '欢迎使用云排版',
    timeOut: '排版超时, 是否重新排版',
    warning: '警告',
    export: '导出',
    mark: '标记',
    noMark: '不标记',
    sawArrange: '锯切排版',
    directArrange: '直切排版',
    area: '面积',
    plankNum: '板件条码',
    loc: '柜体',
    cutHeight: '下料长度',
    cutWidth: '下料宽度',
    defaultProduction: '默认生产线',
    tip: '提示',
    history: '历史记录',
    hinge: '铰链孔',
    hingeScrew: '铰链门板引孔',
    CCPXHole: '三合一偏心孔',
    stretchHinge: '拉直器槽和铰链杯孔',
    stretch: '拉直器',
    stretcher: '拉直器槽',
    clothole: '衣通孔',
    backPlank: '背板槽',
    ALS: '暗拉手槽',
    doorSlot: '门板金属槽',
    drawerSlot: '抽屉金属槽',
    lkSlot: '乐扣槽',
    KC: '裤抽孔',
    stealth: '隐形件',
    lightSlot: '灯槽',
    allHole: '异形板上所有孔',
    allSlot: '异形板上所有槽',
    millInfo: '牛角拉手',
    FSDrill: '五/六面钻',
    engraving2: '开料机',
    xyReverse: 'XY轴互换',
    rect: '矩形',
    Ltype: 'L形',
    archive: 'Archive',
    tellPlaceholder: '请输入手机号',
    knivesStore: '刀库',
    roll: '板件翻面',
    pause: '暂停',
    editTrans: '编辑翻译',
    translateManager: '翻译管理',
    grating: '格栅',
    addressEditErr: '多订单暂不支持修改项目地址',
    customerEditErr: '多订单暂不支持修改客户资料',
    factoryEditErr: '多订单暂不支持修改工厂单号',
    roomNameEditErr: '多订单暂不支持修改房间名称',
    remarkEditErr: '多订单暂不支持修改订单备注',
    toMaterialList: '前往开料清单',
    deleteFail: '删除失败，请稍后重试！',
    batchDelete: '批量删除',
    workShop: '车间生产',
    FGAdrress: '项目地址/合同号',
    cuttingSize: '切割尺寸',
    finishedSize: '成品尺寸',
    labelImg: '贴标图片',
    layoutImg: '排版图',
    file: '文件',
    folder: '文件夹',
    reName: '重命名',
    to: '至',
    plankUnit: '张',
    tagTemp: '标签模板',
    roomRemark: '房间备注',
    noModificationPermission: '没有修改权限',
    modificationRecord: '修改记录',
    page: '页',
    strip: '条',
    jump: '跳',
    freeAgreement: '软件免责协议',
    preStep: '上一步',
    nextStep: '下一步',
    upload: '上传',
    uploading: '上传中',
    iAgree: '我同意',
    pleaseSelectPlank: '请至少选择一块板件',
    saveToStoreTip: '请至少选择一块非裁剪板件',
    fileIsNotExist: '文件不存在',
    selectAll: '全选',
    pdfTag: 'PDF标签',
    tagImage: '标签图片',
    projectName: '云排版',
    guigui: '柜柜',
    gratingSlot: '格栅槽',
    deleteTip: '删除该数据后不可恢复，是否确认删除该数据？',
    deleteError: '出现未知错误，删除失败',
    cancelDelete: '已取消删除',
    confirmDeleteTip:
      '删除后板件将进入页面右下角"板件待排版库-未入库"中，是否确认删除该数据?',
    deleteEmptyTip: '是否删除此大板',
    notCanDelete: '裁剪的余料不支持入库',
    new: '新增',
    innerBluneFailure: '内嵌云排版不生效此功能',
    deleteBigPlankTip: '当前材质颜色厚度下必须保留一块大板，无法删除！',
    continueExport: '忽略，继续输出文件',
    toCheck: '去检查',
    pleaseCheck: '当前排版结果存在孔槽冲突/飞出小板/深度异常，请检查！',
    clampHandCheck: '检测到您部分板件的孔槽无法避让夹手位置，请您手动调整',
    conflictPartDetail: '冲突的板件详情',
    colorNotUndefined: '颜色不能为空',
    materialNotUndefined: '材质不能为空',
    newSetting: '新功能',
    downImageErrTip:
      '导出图片失败，因开启贴标图片根据大板分为不同文件夹，需勾选小板标签一起导出',
    downNcErrTip:
      '下载NC失败，因开启贴标图片根据大板分为不同文件夹，需勾选小板标签一起导出',
    yes: '是',
    no: '否',
    checkColorPrint: '当前已开启彩色标签，请使用彩色打印机进行打印哦~',
    colorRules:
      '开启生成彩色标签后，订单/房间/柜体/门板都会按照颜色进行区分，目前彩色标签对同一类型的数据支持上限为12个，具体规则如下：',
    greyPart: '右边标签中灰色部分为',
    region: '区域',
    notSame: '不同{regionName}区域颜色不同',
    doorTip: '灰色为门板板件，白色为非门板板件',
    exist: '存在',
    limitNotEffect: '数据超过12个，彩色标签不生效',
    hoverTip: '当前为bmp后缀的图片，编码格式黑白，无法输出彩色标签图片',
    lock: '锁定',
    unlock: '解锁',
    batchLock: '批量锁定/解锁',
    surplusLock: '余料锁定',
    surplusUnlock: '余料解锁',
    is: '是',
    not: '否',
    savingHistory: '正在保存历史文件',
    saveHistorySuccess: '保存历史文件成功',
    saveHistoryFail: '保存历史文件失败',
    bw: '是否导出黑白标签',
    modular: '单元柜体',
    autoAdjust: '自动调整',
    cutOrderTip:
      '检测到您的【第{errorCutOrdersString}张大板】切割顺序存在错误！请进行调整后再下载NC文件',
    autoAdjustWarning: '当前排版结果不支持自动调整',
    checkNetwork: '请检查网络',
    moreInfo: '查看更多信息',
    oneCodeErrTip:
      '检测到标签模板的板件条码设置受板件条码唯一功能影响，可能导致扫码打包功能错误',
    continueTip: '是否继续输出',
    oneCodeErrModal:
      '此功能可能影响扫码打包，使用前请检查标签模板中的条码数据源是否为',
    oneCodeOnly: '板件条码（打包用唯一)',
  },
  home: {
    head: {
      production: '产品介绍',
      resolve: '解决方案',
      about: '关于我们',
      equipment: '机器设备',
      tell: '投诉电话',
      enter: '进入云排版',
    },
    swiper: {
      case1: {
        tag: '全新升级版',
        title: '云排版',
        subTitle: '机器的智能大脑',
        description: '改革行业的云端在线排版优化软件',
      },
      case2: {
        title: '柜体生产',
        subTitle: '技术革新',
        description: '支持多种贴标机、开料机、侧孔机、五面PTP、五/六面钻对接',
      },
      case3: {
        title: '工业大脑',
        subTitle: '解决方案',
        description: '智能排版算法获得板材更高利用率',
      },
    },
    statistics: {
      today: '今日排班量',
      toMounth: '当月排版量',
      outturn: '出材率',
      cumulative: '累计用户',
    },
    intro: {
      title: '企业级大数据 云端在线排版软件',
      subTitle:
        ' 洞察行业态势，聚焦客户需求，赋能企业数字化转型升级，推动互联网家具生产自动化产业变革 针对现有板式家具行业生产场景，推出AI辅助算法，持续为企业实现贡献新增长 ',
      menue: {
        case1: {
          title: '在线排版',
          subTitle: '任何时刻均可排版',
          description: '无需下载软件，任何时刻均可排版,远程操控不必依赖于电脑',
        },
        case2: {
          title: '多线生产',
          subTitle: '生产线快速切换',
          description: '支持多条生产线同时快速加工，生产线之间可以进行快速切换',
        },
        case3: {
          title: '精细生产',
          subTitle: '精细排版，精细参数设置',
          description: '精细排版，精细参数设置，让板件生产变得更加精确化',
        },
        case4: {
          title: '自定义生产',
          subTitle: '自定义标签、五六面钻、余料形状',
          description:
            '自定义标签，自定义五六面钻，自定义余料形状，组合设备加工',
        },
      },
    },
    fangan: {
      title: '行业专业解决方案',
      subTitle:
        ' 低成本，高产值，智能算法提高板件利用率，兼容多种设计软件，为用户提供最优生产方案 ',
      menue: {
        case1: {
          title: '线上接单生产一体化',
          description:
            ' 远程线上操作，跨越空间阻碍，随时输出订单同步工厂，设计生产同步完成 ',
        },
        case2: {
          title: '一键切换生产线',
          description: '自定义生产线，支持多生产线，多类型设备对接',
        },
        case3: {
          title: '智能算法提高板材利用率',
          description:
            '传统算法板材利用率低，智能算法精细参数设置，定制生产方案',
        },
        case4: {
          title: '专业切割方案',
          description: '优化切割顺序、下刀点，让路径更短切割更稳',
        },
      },
    },
    aboutUs: {
      title1: '新格尔人居科技',
      subTitle: '家具生产业变革创领者',
      description: '新格尔企业，专注实现传统家具生产业的互联网变革',
      culture: {
        case1: {
          title: '企业宗旨',
          subTitle: '帮助工厂，三倍增长',
        },
        case2: {
          title: '文化价值观',
          subTitle: '以客户为中心，以奋斗者为本，艰苦奋斗，造福人民',
        },
        case3: {
          title: '指导思想',
          subTitle: '群众路线，搭建具有销售力、产品力、组织力的精英团队',
        },
      },
      feature: {
        case1: '专业团队维护',
        case2: '持续更新迭代',
        case3: '一对一售后服务',
        case4: '功能定制服务',
      },
    },
    cooperation: {
      title: '精诚合作 共创未来',
      subTitle: ' 对接设备品牌覆盖90%国内共产常用设备，107554位商家与我们同行 ',
    },
    footer: {
      contact: {
        title: '联系我们',
        adrres: '地址：江苏省无锡市滨湖区绣溪50号',
        subAdrres: 'K-park服务中心1号楼16楼',
        tell: '电话',
      },
      business: {
        title: '新格尔核心业务',
        department1: '门窗软件事业部',
        department2: '家具软件事业部',
        department3: '装配建筑事业部',
        department4: '新格尔总部官网',
      },
      brand: {
        title: '新格尔旗下品牌',
        brand1: '材神金服',
        brand2: '门图网',
        brand3: '鼎鑫投资',
        brand4: '三张图科技',
        brand5: '上海粉墨科技',
      },
      organization: {
        title: '新格尔全球分支机构',
        organization1: '新格尔-华南局（佛山分公司）',
        organization2: '新格尔-北方局（临沂分公司）',
        organization3: '新格尔-西南局（成都分公司）',
        organization4: '新格尔-柜柜西部服务中心',
        organization5: '新格尔-柜柜东部服务中心',
        organization6: '新格尔-代理商集群',
        organization7: '新格尔-国际市场部',
      },
      follow: {
        title: '扫扫"关注柜柜"',
      },
      friendLink: {
        link1: '新格尔软件',
        link2: 'K秀导购软件',
        link3: '门图网',
        link4: '全铝柜软件',
        link5: 'ZOL软件下载',
        link6: '华军软件园',
        link7: '下载之家',
        link8: 'ZOL软件下载',
        link9: '绿色资源网',
        link10: '衣柜加盟',
        link11: '家居加盟',
        link12: '美标卫浴加盟',
      },
    },
  },
  login: {
    title: '登录',
    tell: '手机号',
    password: '密码',
    passwordPlaceholder: '请输入密码',
    forget: '忘记密码',
    registerAccount: '注册账号',
    backLog: '返回登录',
    verifyCode: '验证码',
    getVerifyCode: '获取验证码',
    confimPassword: '确认密码',
    confimPasswordPlaceholder: '请确认密码',
    editPassword: '修改密码',
    register: '注册',
    reSend: '后重新获取',
    loginLoadText: '正在登录中,请稍等...',
    loadingPageTextL: '登录成功,正在加载页面结构中...',
    loginSuccess: '登录成功',
    tellErr: '手机号码格式不正确',
    passwordErr: '请输入密码',
  },
  main: {
    head: {
      title: '云排版',
      subTitle: '省时，省料，省力气',
      task: '接任务',
      arrange: '排版',
      surplus: '余料',
      setting: '设置',
      find: '发现',
      upgrade: '立即升级',
      editNickName: '修改昵称',
      editPassword: '修改密码',
      logout: '退出登录',
      notice: '公告',
      enterOfficial: '前往官网',
      equipment: '机器设备',
      help: '帮助',
      enterThinkerOfficial: '进入新格尔官网',
      history: '保存历史',
      plankManager: '原片管理',
      tagManager: '标签管理',
      suplumentManager: '补件和待排版库',
      fileManager: '文件管理',
      productionLine: '设备对接',
      workeShop: '设备联机',
      useLesson: '使用教程',
      videoLesson: '视频教程',
      prolineManager: '生产线管理',
      materialManager: '板件管理',
    },
    sideBar: {
      title: '生产优化',
      materialList: '开料清单',
      paibanResult: '排版方案',
      printTag: '打印标签',
      history: '保存历史',
      limitDate: '限免',
      surplusManager: '余料管理',
      translateManager: '翻译管理',
      moreSettings: '更多设置',
    },
    allRead: '全部已读',
    learnMore: '了解详情',
    noticeEmpty: '暂无公告....',
    noticeErr: '获取详细公告内容错误',
    feedBack: {
      tab1: '提交反馈',
      tab2: '反馈记录',
      type: '意见类型',
      typePlaceholder: '请选择功能',
      description: '描述',
      descriptionPlaceholder:
        'hi～请填写不少于15字的详细使用场景描述，以便我们更好地为您提供帮助，感谢配合！',
      uploadPic: '添加图片，最多可添加6张，单张图片大小不超过10M）',
      tell: '联系电话',
      submit: '提交',
      admin: '云排版管理员：',
      picTip: '图片:(单击展开查看图片详情)',
      empty: '暂无反馈数据',
      picErr: '单张图片大小不超过10M',
      contentErr: '字数应在 15 到 300 之间',
      necessary: '该选项必填',
    },
  },
  taskPage: {
    tab1: '生产任务',
    tab2: '批量生产队列',

    filter: {
      title: '筛选',
      unArrange: '未排版',
      arranged: '已排版',
      allArranged: '全部已排版',
      preLayout: '预排版',
    },
    search: {
      placeholder: '请输入搜索内容',
      orderName: '订单名称',
      sender: '发送人',
    },
    importTask: '导入任务',
    refuseTask: '拒绝任务',
    refreshList: '刷新列表',
    refuseRecord: '拒绝记录',
    taskCard: {
      sender: '导入的料单',
      delivery: '交期',
      delete: '删除',
      addToBatch: '加入批量',
      save: '存档',
      arrange: '直接排版',
      deleteTitle: '删除任务',
      checkDetail: '点击查看详情',
      refuse: '拒绝',
      refuseTime: '拒绝时间',
      refuseReason: '拒绝原因',
      refuseTask: '拒收任务',
      refuseTaskPlaceholder: '请输入拒收原因',
      addErr: '添加失败,请稍后重试!',
    },
    batchNo: '批次号',
    batchArrange: '批量排版',
    clearBatch: '清空队列',
    taskCount: '当前任务数量:',
    checkAllTask: '查看所有料单',
    clearSuccess: '清除成功',
    record: {
      batchNo: '批次',
      saveDate: '保存时间',
      materialList: '料单',
      arrangedRecord: '排版方案',
      searchPlaceholder: '请输入批次号',
      tipText: '是否删除该条存档?',
    },
    errTip: '无可加工的任务数据,请前往任务列表添加!',
    emptyErr: '该任务订单没有板件数据，无法进行排版生产！',
    operationSuccess: '操作成功',
    operationFail: '操作失败,请稍后重试!',
    saveSuccess: '存档成功',
    plankMaxLimit: '小板数量超过最大排版上限',
    batchWorning: '请分批次进行生产',
    batchEmptyErr: '订单列表没有板件数据，无法进行批量生产！',
    noRoomTip: '不存在房间，无法进行批量生产！',
    refuseHistory: '拒绝历史',
  },
  materialPage: {
    edgeEmptyTip: '修边值不能为空',
    edgeNumberLimit: '修边值只能输入数字',
    chooseSurplusQuantity: '可用余料共 {total} 种，已选 {quantity} 种',
    noCanSaveInfo: '没有可以保存的信息',
    chooseBujianStoreQuantity:
      '共 {total} 块补件，可顺带补件板件共 {quantity} 块，已选 {chooseQuantity} 块',
    isConfirmPushToAwaitStore: '是否确认将所选板件添加到待排版库?',
    enterAutoCreateMatCode: '按回车将自动创建材质',
    chooseAwaitStoreQuantity:
      '共 {total} 块待排版库小板，可顺带排版的板件共 {quantity} 块，已选 {chooseQuantity} 块',
    defaultFold: '板件数据默认收起',
    startArrange: '开始排版',
    importMaterial: '导入开料清单',
    saveToHistory: '保存至历史',
    addPlank: '添加板件',
    deletePlank: '删除板件',
    batchEdit: '批量修改',
    moreSetting: '更多设置',
    plankSize: '板件尺寸',
    plankSize2: '尺寸(长*宽*厚)',
    searchPlaceholder: '搜索板件名称/板号',
    checkArranged: '查看排版',
    checkErr: '未进行排版, 无法查看!',
    downloadSheet: '下载样表',
    downloadSheetShowScientific: '下载样表（展示科学计数法）',
    exportHoleSlotPDF: '导出板件孔槽图',
    style1: '样式一',
    style2: '样式二',
    style3: '样式三',
    importNoCover: '料单导入不覆盖',
    openBujianPlankRecommend: '开启补件小板推荐',
    openAwaitStorePlankRecommend: '开启待排版库小板推荐',
    openSurplusPlankRecommend: '开启余料大板推荐',
    pushToAwaitStore: '存入待排版库',
    setTableHead: '设置开料清单表头',
    syncConfirm: '柜柜与云排版原片数据不一致！是否同步为柜柜原片数据？',
    handleSync: '注：可在原片管理中进行手动同步',
    noMoreTip: '不再提示',
    checkMore: '查看更多',
    noHistory: '暂无保存历史…',
    sameOrder: '存在已导出生产文件的订单，请注意重复生产问题！',
    markOrder: '是否在订单页面标记已生产？',
    editErrTip: '多订单暂不支持修改信息',
    autoSaveSuccess: '已自动保存至历史!',
    goAdd: '前往添加',
    noProductionLine: '该条记录的生产线已被删除，请前往开料清单重新排版',
    fileErr: '解析文件内容失败，请确认文件内容!',
    noCheckData: '没有查询到数据!',
    noArrangeTip: '无法直接查看排版方案,请点击开始排版！',
    tooOld: '五六面钻设置过老',
    contactUs: '请联系售后人员重新对接',
    pathErr: '板件内部存在异常数据（板号：{errorPathPlankID}），请检查！',
    sizeErr: '存在过大板件！小板尺寸：{width}-{height}',
    noKnife: '未设置下料刀直径, 请前往雕刻机设置刀具!',
    sendFactorySuccess: '发送至车间成功',
    sendFactoryErr: '发送车间失败，请联系售后人员请求帮助',
    createTaskCard: '是否创建对应的任务卡片?',
    surplusStatistics: '可用余料共',
    surplusStatistics2: '已选',
    suplementStatistic: '块补件，可顺带补件板件共',
    expand: '展开',
    packUp: '收起',
    importErr: '请选择正确的文件!',
    sendLoadingText: '正在处理并发送NC文件......',
    arrangeLoading: '共计',
    arrangeLoading2: '张小板正在优化排版中......',
    dealing: '正在处理中...',
    surplusHead: {
      shape: '形状',
      type: '类型',
      remark: '备注',
      area: '面积(m²)',
    },
    surplusTip: '暂无可用余料，您可以先去',
    creatSurplus: '新建余料',
    orderInfo: '订单信息',
    suplement: {
      statistic:
        '共{total}块补件，可顺带补件板件共{useable}块，已选{selected}块',
      exportSuplement: '导出补件清单',
      suplementLoading: '补件数据加载中',
      exportSuplementIng: '补件清单正在导出',
      noMatchSup: '未能匹配到可使用的补件数据',
      exportTip: '请至少选中一块补件再进行导出',
    },
    empty: '暂无板件数据',
    paibanSetting: {
      title: '排版设置',
      selectProduction: '生产线选择',
      arrangeWay: '排版方案选择',
      suitText: '适用于',
      suitEquipmentNormal: '常规雕刻机',
      suitEquipmentSpecial: '玻璃切割机、锯片切割机',
      lessRoll: '少翻版',
      computedSpecial: '计算异形',
      specialTip: '影响排版速度',
      irregularNesting: '异形嵌套',
      primaryNesting: '普通嵌套',
      specialShapedPanel: '异形嵌普通板',
      limitedTimeTrial: '限免',
      seniorShape: '高级嵌套',
      heteromorphicInlay: '异形嵌异形',
      autoCutSurplus: '余料自动裁剪',
      cutWay: '裁剪方式',
      maxRect: '裁剪为最大矩形',
      fixedRect: '裁剪为固定尺寸',
      minRectSetting: '裁剪最小尺寸',
      firstCutL: '裁剪优先生成L形余料',
      firstCutLTip: '开启后会优先将满足条件的矩形板组合裁剪为L形余料',

      shortLine: '短边大于',
      longLine: '长边大于',
      surplusTemplate: '余料常用模版',
      add: '添加',
      templateErr: '选择裁剪为常用尺寸，需要选择至少一个数据模板',
      cutLong: '裁剪长度',
      cutWidth: '裁剪宽度',
      baseInfo: '基本信息',
      // thick: '板材厚度',
      continue: '确认并继续',
      standardSelect: '标准大板选择',
      customPlankOrder: '自定义大板排版顺序',
      customPlankOrderDetail: '未排小板清单',
      customPlankOrderTip: '内嵌云排版不生效此功能',
      surplusPlank: '剩余小板清单',
      overSizePlank: '超尺板件清单',
      plankOrder: '排版顺序',
      plankType: '板材类型',
      plankSize: '板材尺寸',
      plankEdgeOff: '修边值',
      amount: '下料数量',
      amountMax: '下料数量最大',
      numAndOverSizeTip: '存在小板超尺且大板用量不足无法排版',
      numErrorTip: '大板用量不足无法排版',
      overSizeTip: '存在小板超尺无法排版',
      restPartList: '未排小板清单',
      layoutOrder: '排版顺序',
      arrangeAmount: '下料数量',
    },
    filter: {
      title: '板件筛选',
      search: '搜索',
      all: '全选',
      resetFilter: '取消筛选',
    },
    edit: {
      title: '批量修改',
      target: '修改对象',
      onlineSelectRow: '仅选中行',
      allRow: '全部行',
      editKey: '需修改内容',
      editValue: '修改为',
      warningTip: '注意 ：修改后将自动清除孔槽和异形',
      allEdge: '全部封边',
    },
    add: {
      title: '添加板件',
      continue: '确认并继续',
      matCodeErr: '材质不能为空',
      thickErr: '厚度不能为空',
      colorErr: '颜色不能为空',
      longErr: '成品长度不能为空',
      widthErr: '成品宽度不能为空',
      countErr: '数量至少为1',
    },
    delete: {
      tip: '删除该数据后不可恢复，是否确认删除该数据？',
      errTip: '请先至少选中一个板件再进行此操作!',
      errTip1: '请至少选中一个板件再进行排版',
      confirm: '确认删除',
    },
    save: {
      errTip: '没有可以保存的信息!',
      title: '保存方案',
      subTitle: '(注：历史仅保存一年)',
      name: '方案名称',
      historyName: '历史方案',
      successTip: '保存成功!',
      fieldTip: '保存失败!',
    },
    exportErr: '暂无板件,无法进行导出',
    exportPicErr: '导出板件孔槽图时遇到未知错误，请联系我们解决',
    plankNotFound: '未查询到相关板件!请确认是否绘制板件!',
    noPlankName: '未找到板件名称',
    plankDataErr: '板件数据错误，请仔细检查，避免生产损失',
    exportThreeViewErr: '导出孔槽三视图失败,请联系售后处理',
    heighGlossWarning:
      '注意：材质为高光板时，修改材质后板件将无法恢复成高光板！',
    reset: '恢复默认',
    settingHead: {
      title: '设置开料清单表头',
      head: '表头',
      example: '样例',
      tip: ' 操作提示：可左右拖动 表头数据标签 位置 ',
    },
    copyLine: '点击可在下方复制当前行',
    copySuccess: '复制{name}数据成功',
    arranging: '共计{count}张小板正在优化排版中......',
    overSize: {
      title: '板件超尺',
      subTitle: '超尺板件清单',
      overSizeTip: '板件超尺提示',
      nonWorkInfo: '无法加工的板件详情',
      conflictWorkInfo: '冲突的板件详情',
      warning:
        '检测到有小板尺寸过长，导致无法排版！请调整小板尺寸或添加对应尺寸原片',
      checkInfo: '查看板件详情',
      tipContent:
        '系统检查到排版小板中存在超尺板件，下面是特殊板材添加建议，可以手动修改板材长宽后点击表格下方<span class="bold color-0">“一键添加”</span>按钮，快速添加后继续排版，也可以在<span class="bold color-0">原片设置-特殊板材</span>手动添加板件 ',
      add: '一键添加',
      addPlank: '添加原片',
      error: '板件超尺，排版失败',
      successTip: '板件添加成功！正在重新排版',
      warningTip: '请填写板件的长宽！',
    },
    arrangeErr: '板件内部数据存在异常，无法进行排版生产',
    nonDelete: '不可删除',
    addOverSizeTip: '存在超尺，请重新添加',
    addPlankSuccess: '添加特殊大板成功',
    addPlankFail: '添加特殊大板失败',
  },
  arrangedPage: {
    slotErrTip: '当前板件存有贴边加工的槽，不支持重新排版！',
    batchBujianErrTip:
      '当前板件不存在料单原始数据中，可在补件/待排版库中选择重新排版',
    useFolderIsNot:
      '检测到您的文件下载保存位置"{type}"不存在 ! 请检查该文件夹是否被删除，如果使用的是移动U盘请检查U盘连接是否正确',
    useFolderIsNot1: '检测到您并未设置文件下载位置，请先选择目录',
    downloadNC: '下载NC',
    downloadNC1: '下载NC文件',
    reArrange: '重新排版',
    saveToHistory: '保存至历史',
    moreSetting: '更多',
    followExportTag: '下载NC顺带导出标签文件',
    tagTemplateSelect: '标签模板选择',
    showCuttingSequence: '显示切割顺序',
    showPlankSize: '显示小板尺寸',
    sendToFactory: {
      title: '发至车间',
      fileType: '标签文件类型',
    },
    checkData: '正在检查数据完整性...',
    dataLoading: '正在努力加载中......',
    addToBujianStore: '正在添加进补件仓库...',
    updateTaskStatus: '正在更新任务状态...',
    sending: '正在发送至车间',
    waitLoadding: '等待',
    loadding: '正在',
    finish: '完成',
    loadSetting: '加载设置',
    loadPreference: '加载偏好设置',
    dealTemplate: '处理模板',
    initTagData: '初始化标签数据',
    prepareDrawData: '准备标签绘制数据',
    drawDataText: '标签绘制数据',
    genPdfTagFile: '生成PDF标签文件',
    genPicTagFile: '生成标签图片文件',
    uploadFile: '上传文件',
    dealNCFile: '处理NC文件',
    zipFile: '生成本地压缩文件',
    plankCount: '大板数量',
    rollCount: '翻版次数',
    totalRate: '整体优化率',
    rate: '优化率',
    useArea: '已优化面积',
    materialList: '料单',
    saveSurplus: '余料入库',
    workeShop: '采购单',
    holeSlotErr: '检测到板件存在孔槽深度异常!',
    knivesErr: '检测到板件找不到加工刀具!',
    checkDetail: '查看详情',
    deepErr: {
      title: '板件孔槽深度异常',
      errStatistic: '次小板有{count}处异常',
      errText: '异常部分',
      markErrPart: '框出异常部分',
    },
    warningTip:
      ' 双面加工的高光板：先加工 <span class="font-style">高光面</span> , 再加工 <span class="font-style">哑光面</span>;  单面加工的高光板：直接加工 <span class="font-style">哑光面</span>',
    showDetail: {
      tip1: '单击选择单个板件',
      tip2: '查看板件标签',
    },
    arrangeTip: '(已支持异形嵌套排版)',
    plankOpration: '板件操作',
    rotate: '旋转90度',
    notCare: '板件无纹理',
    editRemark: '编辑备注',
    clearRemark: '清空备注',
    recalcIndex: '重新计算下刀点和切割顺序',
    recalcIndexSuccess: '重新计算下刀点和切割顺序成功',
    storageArea: {
      title: '板件暂存区',
      empty: '无板件暂存',
      storageTip: '可将板件拖至此处进行板件暂存',
    },
    subtleArrange: '精细排版',
    addPlank: '添加大板',
    deletePlank: '删除大板',
    deletePlankErr: '当前大板存在小板, 不可删除大板!',
    useSurplus: {
      title: '使用余料',
    },
    dragWarning: '请将板件拖动至大板上!',
    areaWarning: '无法放置板件,留意空余空间大小',
    subtlePage: {
      cut: '余料裁剪',
      cancelCut: '结束裁剪',
      cutPoint: '余料裁剪吸附点',
      storageArea: '板件待排板区',
      cutErr: '只能在大板上进行切割, 不能触碰小板!',
      saveErr: '请先选择一个裁剪的余料',
      cutErr2: '只能在大板上进行切割, 不可超出大板!',
      selectTip: '请选择一个裁剪的余料板件',
    },
    shortCut: '快捷键',
    updownRoll: '上下滑动',
    leftRightRoll: '左右滑动',
    zoomScale: '放大缩小',
    dragCanvas: '拖动页面',
    mouseRoll: '鼠标滚轮',
    mouseRight: '鼠标右键',
    doublePlank: '双击大板',
    keyboard: '键盘',
    picSymble: '图例',
    funished: '成品',
    holeSlotConflict1: '孔槽冲突',
    plankErr: '板件冲突',
    frontSlot: '正面拉槽',
    backSlot: '反面拉槽',
    frontHole: '正面孔位',
    backHole: '反面孔位',
    resetScale: '重置缩放',
    maxLong: '最长',
    tempErr: '添加失败,模板位置不正确或模板过大!',
    sendErr: '发送车间失败，请检查标签模板与板件数据是否正确之后重新发送',
    someTips: '您正在限时试用云排版异形排版功能，请注意规避生产问题！',
    cutOrderErr: '切割顺序不正确，请点击重新计算下刀点和切割顺序！',
    holeSlotConflict: '检测到板件存在孔槽冲突/飞出小板',
    exportSetting: '导出设置',
    specialPaibanErr: '有板件具有特殊工艺，暂不支持重新排版！',
    moreResult: '更多排版结果',
    moreResultTip: '大板中有贴边加工的槽,大板将不会参与重新排版',
    batchBujian: '批量补件',
    disPlaySetting: '显示设置',
    stored: '已入库',
    onlySurplus: '只看余料',
    UnuseSurplus: '余料不参与利用率计算',
    usePlankAdsorption: '板件吸附功能',
    dataDefaultCollapsed: '数据默认收起',
    unit: '块',
    cutSurplus: '切余料',
    frontWarningTip:
      '双面加工的高光板：先加工<span class="font-style">哑光面</span> , 再加工<span class="font-style">高光面</span><span>; &nbsp; 单面加工的高光板：直接加工 </span <span class="font-style">高光面</span>',
    selectTip: '请在下方选择需要补件的小板!',
    plankKeyWordPlaceholder: '请输入板件名称或板号',
    surplusStoreSuccess: '入库成功',
    editCutOrder: '修改切割顺序',
    setCutPoint: '设置下刀点',
    specialPaibanChangeErr: '有板件具有特殊工艺，暂不支持切换排版方案！',
    storedSuccess: '补件成功！选中板件已经放入补件管理!',
    noSurplus: '未查询到可用余料',
    selectErrTip: '余料板件不可补件,请先选择正常小板',
    noRoomIDTip: '该板件没有房间ID,无法进行补件入库,请先选择其他小板',
    noPlank: '未找到板件',
    noPaibanData: '无排版数据，无法进行',
    historyNameErr: '请输入方案名称',
    tempErrTip:
      '检测到您未添加五/六面钻所用模板，从而无法导出五/六面钻加工文件,注意：添加完成后需要重新排版',
    changeDataTip:
      '注意：检测到您的数据有变更是否新建一个历史文件，保存此次变更内容？',
    upgrade: '升级',
    upgradeTip: '，您可以点击下方按钮去升级版本',
    plankOverEdge: '下载NC失败！请检查是否有板件重叠或板件在大板外',
    useSurplusTip: '是否扣减余料',
    noDataExport: '没有可以导出的排版数据',
    noPlankExport: '没有可以导出的小板数据',
    lockTip1: '点击进行解锁，解锁后可以',
    lockTip2: '编辑小板位置和属性',
    plankLockErr: '该大板已被锁定无法放置',
    lockPartEditTip: '板件被锁定，无法编辑',
    glassEquipmentTip: '生产线为玻璃切割机时，板件无法被移动',
    dragErrTip: '无法放置, 请核对板件材质、颜色和厚度属性!',
    subtleSaveTip: '当前已对板件操作但未保存,是否退出?',
    rollTip: '大板上存在通孔/通槽，不建议翻版！',
    glossRollErr: '高光板禁止翻板',
    lockDeleteErr: '板件被锁定，无法删除',
    deleteConfirmTip: '删除操作不可恢复',
    deleteContinueTip: '你还要继续吗？',
    deleteConfirm: '请确认是否删除',
    selectPlankTip: '请先选中板件再进行此操作',
    storeFailTip: '余料入库失败！请检查是否有板件重叠或板件在大板外',
    repeatStoreErr: '当前裁剪的余料板件已经进行过入库',
    surplusStoreFail: '入库失败, 请检查板件是否为矩形或L形板件!',
    plankConfilictErr: '不支持在此处放置点位,将会和其他板件产生冲突',
    surplusErrTip: '不支持在此处放置点位,会产生一个错误的余料',
    changeArrageSuccess: '成功切换排版方案',
    trialProduction: {
      trialProductionSample: '试生产样品柜',
      trialProduced: '我已试生产',
      trialWarning:
        '因您未进行样品柜的生产验证，而造成对自身、他人及云排版软件的全部损失与不利后果将由您个人承担全部责任',
      pleaseUploadTrial: '请上传组装好后的试生产样品柜照片',
      uploadTrial: '上传样品柜',
    },
    awaitPlankStore: {
      store: '板件待排板库',
      notTip: '不再提示未入库',
      justLookCurrent: '仅看当前订单小板',
      notInPushStorage: '未入库',
      beInStorage: '已入库',
      searchPlaceholder: '输入订单搜索待排版库小板',
      plankUploading: '板件保存中',
      oneClickStorage: '一键存入待排板库',
      notUsePlank: '暂无可用小板',
      notPlank: '无板件暂存',
      dragPlankInHere: '可将板件拖至此处进行板件暂存',
      surplusCannotBeStorage: '余料不能入库',
      plankHasBeenDeduction: '小板已被扣减或不存在原始数据，无法入库',
      plankLoadErr: '板件信息加载失败',
      taskTitle: '上传待排版库内板件',
      dataGenErr: '数据生成错误',
      pushSuccess: '入库成功',
      pushErr: '出现未知错误，入库失败',
      awaitBoxExistPlank: '待排版库存在未入库小板，建议入库后再操作',
      checkPlankStatus: '正在校验板件状态...',
    },
    batchSetCut: '批量设置下刀点',
    applyTo: '适用于',
    ncDownloadLifeText: {
      checkData: '正在检测数据',
      checkDone: '数据检测完成',
      checkDataText: '数据检测',
      loadSetting: '正在加载设置',
      settingLoadDone: '设置加载完成',
      loadSettingText: '设置加载',
      downloadNc: '正在下载NC',
      ncDownloadDone: 'NC下载完成',
      downloadText: '下载NC',
      loadPreferenceSetting: '正在加载偏好设置',
      PreferenceSettingLoadDone: '偏好设置加载完成',
      loadPreferenceText: '加载偏好设置',
      dealTemp: '正在处理模板',
      TempDealDone: '模板处理完成',
      dealTempText: '处理模板',
      beBeingGenTagFile: '正在生成{fileType}文件',
      genTagFileDone: '生成{fileType}文件完成',
      genTagFileText: '生成{fileType}文件',
      zip: '正在生成本地压缩文件',
      zipDone: '生成本地压缩文件完成',
      genZipText: '生成压缩文件',
      fileTypeText: '图片',
    },
    surplusCommonTemp: '余料常用模版',
    plankErrTip: '当前用料方案存在小板没有可用大板进行排版!',
    overSizeTip: '大板数量不够或超尺',
    uselessPart: '当前大板上无可用小板',
    splitArranged: '小板数量过多，建议分批次进行排版',
    arrangedErrTip: '排版失败，存在大板数量不足或小板超尺',
    knivesModalTitle: '存在孔槽找不到刀具',
    cannotDownloadByKnivesErr: '无法下载NC，系统检测存在以下孔槽找不到刀具',
    viewPart: '查看问题小板',
    deductionModalTitle: '请选择扣减对象',
    bujianDeduction: '扣减补件小板',
    paibanDeduction: '扣减暂存区小板',
    surplusDeduction: '扣减余料大板',
    unlockPaiban: '解除参与排版余料的锁定',
    maintain: '维持参与排版余料的锁定',
    lockModalText: '存在使用余料大板被其他订单占用，是否重新排版',
    lockNewSModalText: '当前使用余料大板被其他订单占用，是否继续下载NC',
    rePaibanWarn: '余料大板被其他订单占用，可用大板不足，请到开料清单重新排版',
  },
  historyPage: {
    download: '下载本地',
    allType: '全部保存',
    handleSave: '手动保存',
    autoSave: '自动保存',
    allArrange: '全部排版',
    searchPlaceholder: '搜索订单名称 / 排版批次',
    trailProduction: {
      title: '试生产样品柜',
      lastTime: '上次试生产日期',
      recordList: '试生产记录',
      start: '开始试生产',
      date: '时间',
      outputFrom: '输出位置',
      empty: '暂无数据',
      record: '上传的试生产样品柜图片记录',
      tip1: '注意：当前试生产订单使用的是默认生产数据',
      tip2: '注意：当前试生产订单使用的并不是最新的工艺设置参数，可前往试生产房间重新保存！',
      download: '下载加工文件',
    },
    deleteTip: '是否确认删除以下历史记录？',
    startDate: '开始日期',
    endDate: '结束日期',
    lastWeek: '最近一周',
    lastMonth: '最近一月',
    lastSeason: '最近三月',
    toArrangePage: '正在前往排版页面',
    notArrangedTip: '该条记录没有进行过排版, 请先前往开料清单!',
    toMaterialPage: '正在前往开料清单页面',
    lessSelectTip: '请至少选中一条记录再进行此操作',
    downloadNc: '下载加工文件',
  },
  workShopPage: {
    warningTip: '该功能为限时试用，请合理安排您的订单生产！',
    fileManager: '生产文件管理',
    selected: '已选中',
    suplement: '补件',
    size: '尺寸',
    saveToStore: '添加进补件仓库',
    enterArrange: '正在进入排版...',
    deleteTip: '删除该数据后不可恢复，是否确认删除该数据？',
    arrangeTip: '正在解析补件数据,请稍等...',
    store: '补件仓库',
    downloadFile: '下载生产文件',
    notBujianFile: '找不到补件文件',
    productLineDelete: '该生产线已被删除,无法下载NC文件!',
  },
  basePlankManager: {
    saveTip: '注：按回车进行保存',
    offsetSize: '修边尺寸',
    overSizeTip: '尺寸过大时，自动选用更大尺寸规格大板进行排版',
    addTip: '排版时如果板件超尺，弹出自动添加特殊大板弹窗',
    onlyLayoutTooBig: '超尺小板只排在超尺大板',
    settingRemark: '注：原此处功能调整到右侧设置内',
    searchPlaceholder: '搜索板材材质/颜色',
    syncData: '同步柜柜数据',
    syncDataM: '同步柜门数据',
    deletePlank: '删除特殊大板',
    addPlank: '新建特殊板材',
    matCodePlaceholder: '请选择板材',
    thickPlaceHolder: '请选择厚度',
    widthErr: '板材宽度不能为空',
    heightErr: '板材长度不能为空',
    matCodeErr: '板材材质不能为空',
    colorErr: '板材颜色不能为空',
    editPlank: '编辑板材',
    deleteTip: '删除该数据后不可恢复，是否确认删除该数据？',
    plankKnifeSelect: '原片下料刀选择',
    knifeSelectTip: '注： 内嵌排版暂不支持此功能',
    knife: '下料刀具',
    matcodeInput: '请输入板材材质',
    thickInput: '请输入板材厚度',
    default: '系统默认',
    unessential: '此列为:非必填项',
    emptyTip: '注意：不填写此项时匹配原片时将不区分此项数据！',
    defaultUse: '默认选用',
    editMatcode: '编辑材质',
    syncTip: '将获取{fromMc}中数据并覆盖现有云排版中原片管理数据，是否同步？',
    sameErr: '已存在相同的数据',
    cancelDelete: '已取消删除',
    syncErr: '同步失败',
    syncSuccess: '同步成功',
    syncDataTip: '同步{fromMc}数据',
    editErrTip: '存在未填写的信息, 请核对!',
    numberCheck: '{type}只能输入数字!',
    deleteStandardPlate: '删除标准板材',
    createStandardPlate: '新建标准板材',
    editStandardPlate: '编辑标准板材',
    trimmingDimension: '修边尺寸',
  },
  bujianAndStore: {
    bujianStore: '补件仓库',
    awaitStore: '板件待排版库',
    depositAwaitStore: '存入待排版库',
    pushToAwaitStore: '放入待排版库',
    useTwoTableArrange: '使用两个表单排版',
    confirmUseTwoTableTip: '当前选中小板包含补件库和待排版库小板是否继续排版？',
    confirmAddPlankToAwaitStore: '是否确认将所选板件添加到待排版库？',
    pushStoreSuccess: '入库成功',
    dataGenErr: '数据生成错误',
    unknownErr: '出现未知错误，入库失败',
  },
  surplusPage: {
    surplusMaterial: '余料清单',
    surplusStore: '余料库',
    surplusShape: '余料形状',
    batchTransfer: '批量转移',
    createNewSurplusStore: '新建余料库',
    pushStoreSetting: '余料入库设置',
    surplusStoreName: '余料库名',
    surplusStoreName2: '余料库名称',
    addSurplusStore: '添加余料库',
    inventoryCeiling: '库存上限',
    autoTransferTip: '删除后余料库内余料会自动转移到余料清单',
    asIndexPushStore: '按照仓库存放情况顺序入库',
    pushToSurplusMaterial: '存入余料清单',
    surplusStoreTable: {
      number: '编号',
      name: '余料库名称',
      quantity: '已存余料数量(个)',
      capacity: '余料库存上限(个)',
      remark: '备注',
      operation: '操作',
    },
    editSurplusStore: '编辑余料库',
    //
    addSurplus: '添加余料',
    deletSurplus: '删除余料',
    editSurplus: '修改余料',
    surplusNoRoll: '启用余料不翻版加工',
    type: {
      all: '全部形状',
    },
    allMatcode: '全部材质',
    allThick: '全部厚度',
    surplusName: '余料名称',
    rectSurplus: '矩形余料',
    LTypeSurplus: 'L形余料',
    noMatchSup: '未查询到相关数据',
    jumpTo: '跳至',
    page: '页',
    newMatcode: '新建材质',
    newColor: '新建颜色',
    autoCreateColor: '按回车将自动创建色卡',
    nameLimit: '余料名称最多输入10个字',
    nameLimitNameErr: '余料名称不能为空',
    tempLongTip: '板材长度只能输入数字',
    minLongLimit: '短边长度只能输入数字',
    minLongEmptyTip: '短边长度不能为空',
    minLongLimitMax: '短边长度不能超过板材长度',
    widthLimit: '板材宽度只能输入数字',
    minWidthLimit: '短边宽度只能输入数字',
    minWidthEmptyLimit: '短边宽度不能为空',
    minWidthLimitMax: '短边宽度不能超过板材宽度',
    thickLimit: '板材厚度只能输入数字',
    thickEmptyLimit: '板材厚度不能为空',
    countInputLimit: '数量只能为正整数',
    countEmptyLimit: '数量不能为空',
    remarkMaxlimit: '备注最多输入50个字',
    createTime: '添加时间',
    branchName: '所在余料库',
    lockStatus: '锁定状态',
    lockOrder: '锁定订单',
    enableSurplusLock: '启用余料锁定功能',
    isCloseSurplusLock: '功能关闭后所有余料都会解除锁定，是否继续',
    confirm: '确认关闭',
    surplusTransfer: '余料转移',
    targetSurplusStore: '目标余料库',
    enableSurLockTip: '开启后，每次排版都会锁定使用的余料，防止其他订单占用',
  },
  printPage: {
    deleteWarning: '温馨提示',
    print: '打印',
    defaultTemplate: '默认模版（60 x 40）',
    editTemplate: '编辑模板',
    deleteTip: '删除该模板后不可恢复，是否确认删除该模板？',
    tagSetting: '标签设置',
    rememberSetting: '修改后将记住设置',
    genCutingTag: '生成裁剪板件标签',
    genSurplusTag: '生成余料标签',
    genPlankTag: '生成大板标签',
    genPartTag: '生成小板标签',
    tagPosition: '贴标位置',
    tagPositionTip: '(贴标机才需填写下方X/Y坐标)',
    Xposition: 'X坐标',
    Yposition: 'Y坐标',
    genPlankSuffix: '大板标签生成下料文件名后缀',
    arrangeMirror: '标签图镜像',
    drillPlankBase: '五六面钻放板基准',
    suitAuto: '标签排序适应自动上下料：',
    colorLabel: '生成彩色标签',
    up: '向上',
    down: '向下',
    default: '默认',
    printSetting: '打印设置',
    oneToMore: '一张多页',
    perPage: '每张',
    coloumn: '列',
    tagMargin: '标签间隙',
    coloumnMargin: '列间距',
    specialMark: '特殊标记',
    opacity: '透明度',
    suite: '自适应',
    size: '大小',
    lightSide: '见光边',
    locFrontSide: '柜体前边',
    openSide: '开门方向',
    extraTip:
      ' 提示：首次使用直接打印时，请在打印机设置中新增一张使用的该尺寸且长、宽对调的纸张，然后使用新增纸张进行打印（例：原纸张尺寸60×40，新增纸尺寸40×60） ',
    noMore: '没有更多了',
    prepareData: '准备数据中...',
    genTagFile:
      '标签生成中，请勿进行页面切换、最小化浏览器、上下滚动标签等操作！',
    waitGen: '请等待标签加载完成后再进行下载',
    tempLoading: '标签模板加载中,请稍后',
    front: '前',
    open: '开',
    toEdit: '前往编辑',
    simplifyOneCodeTip:
      '注意：此标签模板设置的条形码属性不支持使用“简易条码“功能，请修改条码属性（推荐条码属性：CODE128）',
    plankTemp: '小板标签模板',
    surplusTemp: '余料标签模板',
    tempNamePlaceHolder: '请输入模板名称',
    editTip: '您可以根据自身需求拖动左侧按钮至右边标签纸上来完成标签模板的编辑',
    oneCode: '条码',
    image: '图像',
    editPage: {
      title: '标签模版编辑',
      reset: '恢复默认',
      exit: '退出编辑',
      tagInfo: '标签信息',
      tagName: '标签名称',
      tagSize: '标签尺寸',
      tagData: '标签数据',
      dragTip: '按住鼠标左键拖出数据标签',
      dataTip: '请在右侧属性栏选择数据信息',
      text: '文本',
      dataSource: '数据信息',
      graph: '图形',
      typograpyPic: '排版图',
      barCode: '条形码',
      QRCode: '二维码',
      plankMark: '板件标记',
      holeSlotPic: '孔槽图',
      holeSlotPicZoom: '孔槽图缩放',
      rotateDeg: '旋转角度',
      colorDeep: '颜色深度',
      surplusMark: '余料标识',
      surplusSizePic: '余料尺寸图',
      dataSize: '数据尺寸',
      extraList: '附加清单',
      QRCodeFormat: '二维码格式',
      formatQR: 'QR格式',
      formatDM: 'DM格式',
      locIndex: '柜体序号',
      plankFrontSign: '面板标记',
      genNCSuffix: '生成NC后缀',
      table: '表格',
      inputPlaceholder: '请双击输入文本',
      attribute: '属性',
      fontFamily: '字体类型',
      fontSize: '字体大小',
      fontWeight: '字体加粗',
      autoRow: '自动换行',
      rowMargin: '行间距',
      autoFontSize: '溢出自动调整字体大小',
      statisticsDimension: '统计维度',
      noLimit: '不限',
      sameOrderNo: '同订单',
      sameRoom: '同房间',
      sameWardrobe: '同柜体',
      alignWay: '对齐方式',
      JustifyLeft: '居左对齐',
      JustifyRight: '居右对齐',
      JustifyCenter: '居中对齐',
      otherOpration: '其他操作',
      attributeReset: '参数重置',
      barCodeSize: '条码尺寸',
      installPic: '安装图',
      plankCode2: '板件条码(noMark)',
      frontCode: '正面条码',
      backCode: '反面条码',
      orderRemark: '订单备注',
      NCFileName: '下料文件名',
      NCFileNameTip: '下料文件名请勿包含中文且长度不可超过10位',
      showOpenSide: '显示开门方向',
      limitFixed: '限制小数点位数',
      showFrontHS: '显示正面孔槽',
      showBackHS: '显示反面孔槽',
      showPlankSize: '显示板件尺寸',
      tempNamePlaceholder: '请输入标签名',
      deleteAreaTip: '请选择要删除的区块',
      noHoleSlot: '无孔槽也显示条码',
      rotate: '旋转条码',
      barcodeAtrribute: '条码属性',
      imgSize: '图像尺寸',
      noEdgeDisplay: '封边为0不显示',
      allEdgeNoDisplay: '封边不显示',
      imgRotate: '图片旋转',
      circleD: '圆框直径',
      roomNo: '房间编号',
      roomOrder: '柜体序号（按房间排序）',
      alphabetically: '按字母排序',
      numberOrder: '按数字排序',
      specialMarkup: '特殊标记符号',
    },
    autoSaveToHistory: '已自动保存至历史!',
    firstPrintTip:
      '首次使用直接打印时，请在打印机设置中新增一张使用的该尺寸且长、宽对调的纸张，然后使用新增纸张进行打印',
    tagDownloading: '标签正在下载中...',
  },
  fileManagerPage: {
    notOpeFolderPermission: '未授权操作此目录',
    notFindFolder: '无法在当前客户端检测到此目录',
    productLineUniversal: '所有生产线通用',
    effectCondition: '此设置更改后需重新排版才可生效',
    addPath: '添加路径',
    downloadPositionChangeByType: '生成文件下载位置已更换为{type}',
    isDeleteFolder: '是否确认删除使用过的文件存储位置?',
    netWorkIsNotSafe:
      '您的网络环境似乎不安全，我们无法启用此功能，您可以反馈给我们',
    NotSupportBrowse:
      '您的浏览器暂不支持此功能，请下载最新版<a href="https://www.microsoft.com/zh-cn/edge/download" target="_blank" >Edge</a>浏览器使用',
    notSupportDisk: '不可选用系统级别目录,如【桌面】【C盘】',
    useFolderDownload: '生产文件以解压后的形式下载',
    downloadPosition: '下载文件位置',
    changeUseFolder: '更改',
    folderNameSetting: '文件夹命名设置',
    mainFolder: '一级文件夹',
    exportNameSetting: '导出加工文件按订单号命名',
    fileMax: '文件名最多为',
    char: '字符',
    overTip: '超过部分不显示（标签图压缩包和json文件将跟随一级文件夹命名） ',
    example: '样例',
    engravingFolder: '雕刻机文件夹',
    electronicSawFolder: '电子锯文件夹',
    labellingFolder: '贴标机文件夹',
    sideHoleDrillFolder: '侧孔机文件夹',
    fiveDrillFolder: '五面钻文件夹',
    sixDrillFolder: '六面钻文件夹',
    PTPFolder: 'PTP文件夹',
    labellingTagFolder: '贴标图片文件夹',
    folderMergeSetting: '文件夹合并设置',
    folderMerge: '文件夹合并',
    mergeName: '合并名称',
    subMergeFolder: ' 按照材质颜色厚度合并到子文件夹',
    subFolderName: '子文件夹名称',
    splitChar: '分割符',
    splitTip: '（不支持输入  / : ? < > | . ” * 等字符）',
    folderAutoAdd: '文件夹数自增起始数值',
    resetTip: '(每日零点重置为起始数值)',
    emptyErrTip: '信息不能为空',
    repeatErrTip: '文件名称重复',
    mergeDataTip: '必须存在板件材质颜色厚度长宽或者文件数自增',
  },
  // 更多设置页面
  moreSettings: {
    generalSetting: '通用设置',
    outSyncTip: '云排版原片数据与柜柜不同步时生成提示弹窗',
    secondarySwitchShow: '雕刻机设置中开关控制的二级内容关闭开关后依旧展示',
    checkKnifeCode: '是否检测换刀代码缺失',
    exPaibanTip: '使用异性嵌套排版时生成提示弹窗',
  },

  lineManager: {
    add: '添加生产线',
    engraving: '开料设备',
    holeSlotEquip: '孔槽加工设备',
    workSetting: '加工设置',
    noUse: '未启用',
    warningTip:
      '* 注意：下列功能设置后，后续请不要在柜柜客户端设备对接页面进行保存，避免参数重置！',
    engravingSetting: '开料机设置',
    workWay: '加工方式',
    cutedSpecial: '加工的特殊孔槽：',
    noWork: '此选项对五/六面钻默认模板不生效',
    onlyCut: '仅下料',
    onlyFrontHoleSlot: '仅加工板件正面孔槽',
    onlyHoleSlot: '仅加工板件正反面孔槽',
    sideHoleSetting: '侧孔机设置',
    cutHoleSlot: '加工的孔槽',
    minSize: '短边尺寸小于',
    maxSize: '且长边尺寸小于',
    minSize2: 'mm的板上的所有孔槽和异形部分',
    singleHole: '单孔',
    singleSlot: '单槽',
    diameterRange: '孔直径范围',
    hDeepRange: '孔深度范围',
    widthRange: '槽宽度范围',
    sDeepRange: '槽深度范围',
    to: '到',
    specialHS: '特殊孔槽和异形',
    allowRoll: '允许开料机翻版加工特殊孔槽',
    engravingPath: '开料机上加工板件异形部分',
    drillSetting: '五六面钻设置',
    engravingCuted: '开料机上已加工孔槽在五/六面钻上',
    noGen: '不生成',
    genCut: '生成并加工',
    genNoCut: '生成但不加工',
    cutTip: ' 注：此选项仅对自定义的五六面钻文件生效',
    maxShortSize: '最大短边尺寸',
    maxLongSize: '最大长边尺寸',
    normalSlot: '普通槽',
    specialSlot: '特殊槽',
    combinSetting: '组合设备设置',
    allowShortSize: '允许短边尺寸',
    equipment1: '设备一',
    equipment2: '设备二',
    noSameTip: '注：两个设备不能选择一样',
    workRange: '设备一可加工孔槽范围',
    rangeWarning:
      '注：仅可设置设备一加工范围，非设备一加工范围将全部在设备二上加工，请谨慎操作！',
    deleteWarning:
      '删除该生产线后不可恢复（该生产线下所有设置都将删除），是否确认删除该生产线？',
    top: '顶部',
    inputCheck: '{msg}最小值不能大于最大值',
    lockTip: '生产线已锁定,禁止操作!',
    reNameTip: '默认生产线不可修改名字',
    permissonTip: '身份信息已过期,请重新从柜柜打开此页面!',
    permissonErr: '暂无此权限, 请升级账号版本!',
    permissionOneMacErr:
      '您当前可使用的一体机生产线条数为{idx}条，如需增加，请联系销售人员！',
    newLine: '新建生产线{idx}',
    deleteCheck: '不能全部删除',
    deving: '开发中',
    combinTip: '请点击下方组合设备设置进行设置',
    noUseEquip: '未启用加工设备',
    equipmentSelectTip: '组合设备选择了开料机时，开料设备不能选择电子锯',
    lineLoading: '数据加载中',
    strenchType: '拉直器槽类型',
    //
    aioProcessTip: '您已启用"一体机"设备，加工方式默认为加工板件正反面孔槽',
    aioProcessTip2: '启用一体机后在雕刻机部分加工的特殊孔槽',
    aioProcessTip3: '注意：该功能不兼容启用XY轴互换的雕刻机 请谨慎使用',
    specialHoleTip:
      '注意：使用以上“加工的特殊孔槽”的功能时请将“通孔打孔方式”和“通槽打穿方式”设置为一次打穿',
    specialHoleTip2:
      '注意：使用以上“加工的特殊孔槽”的功能时请将雕刻机“通孔打孔方式”和“通槽打穿方式”设置为一次打穿',
    fiveSixNotTake: '此选项对五/六面钻默认模板不生效',
    onlyFrontSide: '仅加工正面',
    onlyBackSide: '仅加工反面',
    bothFrontBack: '加工正面和反面',
    shareDataErrTip: '此条数据由{operatorPhone}应用,ip:{actionIp}',
    restoreParams: '还原参数',
    loadMore: '加载更多',
  },
  // 雕刻机-开料对接
  cuttingDock: {
    title: '开料对接',
    autoStockCode: '自动上下料代码',
    stationStart: '工位开始',
    stationStartCode1: '工位1开始代码',
    stationStartCode1Placeholder: '输入工位1开始代码',
    stationStartCode2: '工位2开始代码',
    stationStartCode2Placeholder: '输入工位2开始代码',
    stationEnd: '工位结束',
    stationEndCode1: '工位1结束代码',
    stationEndCode1Placeholder: '输入工位1结束代码',
    stationEndCode2: '工位2结束代码',
    stationEndCode2Placeholder: '输入工位2结束代码',
    frontBackStart: '正反面开始',
    frontBackEnd: '正反面结束',
    frontStartCode: '正面开始代码',
    frontStartCodePlaceholder: '输入正面开始代码',
    frontEndCode: '正面结束代码',
    frontEndCodePlaceholder: '输入正面结束代码',
    backStartCode: '反面开始代码',
    backStartCodePlaceholder: '输入反面开始代码',
    backEndCode: '反面结束代码',
    backEndCodePlaceholder: '输入反面结束代码',
    plankStart: '板件翻面开始',
    plankEnd: '板件翻面结束',
    plankRollCode1: '工位1翻面代码',
    plankRollCode1Placeholder: '输入工位1翻面代码',
    plankRollCode2: '工位2翻面代码',
    plankRollCode2Placeholder: '输入工位2翻面代码',
    autoBaitingCode: '自动下料代码',
    autoBaitingCode1: '工位1下料代码',
    autoBaitingCode1Placeholder: '输入工位1下料代码',
    autoBaitingCode2: '工位2下料代码',
    autoBaitingCode2Placeholder: '输入工位2下料代码',
    preStartEquipment: '预启动设备',
    firstKnifePreCode: '首刀预启动代码提前',
    secondKnifePreCode: '第二把刀及后续刀预启动代码提前',
    rowInsert: '行插入',
    pumpPause: '真空泵暂停',
    pumpPauseTip:
      '注意：单工位设备需要将工位1和工位2都填上相同的"自动上料代码" 和"双面加工正面注释代码"',
    pumpPause2Tip:
      '注意：单工位设备需要将工位1和工位2都填上相同的"真空泵暂停代码"',
    pumpPauseCode1: '工位1真空泵暂停代码',
    pumpPauseCode1Placeholder: '请填写工位1的真空泵暂停代码',
    pumpPauseCode2: '工位2真空泵暂停代码',
    pumpPauseCode2Placeholder: '请填写工位2的真空泵暂停代码',
    annotationCode1: '工位1双面加工正面注释代码',
    annotationCode1Tip: '适用于自动上料正面不同指令的设备',
    annotationCode1Placeholder: '输入工位1双面加工正面注释代码',
    annotationCode2: '工位2双面加工正面注释代码',
    annotationCode2Placeholder: '输入工位2双面加工正面注释代码',

    upSpeedTip: '请填写共边加速速度',
    cutThickTip: '请填写共边切割厚度',
    sizeErrTip: '请在贴标机中设置导出排版图的正确尺寸!',
    offEdgeKnifeDiameter: '请输入修边刀具直径',
    slowDownSpeedTip: '切割转角减速输入参数错误',
    permissionErr: '当前为免费版, 无法保存',
    labellingTempErr: '请选择贴标机模板',
    autoRollInputTip: '开启标签自动旋转，需输入有效值',
    offlineTempFormat: '当前贴标机文件格式已下线, 请确认',
    autoStockCode1: '工位1自动上料代码',
    autoStockCode1PlaceHolder: '输入工位1自动上料代码',
    autoStockCode2PlaceHolder: '输入工位2自动上料代码',
    autoStockCode2: '工位2自动上料代码',
    pauseCode: '暂停代码',
    pauseCodePlaceHolder: '输入暂停代码',

    cuttingParams: {
      title: '切割参数',
      holeSlotMainSide: '孔槽集中面',
      holeMainSide: '孔集中面',
      slotMainSide: '槽集中面',
      workPriority:
        '(功能优先级：特殊孔槽加工面自定义>打穿孔槽所在面跟随该小板上未穿孔槽>槽集中面>孔集中面)',
      hingeRollWork: '拉直器和铰链杯孔放到大板反面加工',
      followWork: '打穿孔槽所在面跟随该小板上未穿孔槽',
      autoMatchSide: '按比例分配正反面孔槽',
      backHSRate: '反面孔槽占比',
      matchTip: '（高光板或开启计算异形的排版方案都不会生效此设置）',
      specIalHoleAndSlotSurface: '特殊孔槽加工面自定义',
      specialRuleTip: '请选择特殊孔槽',
      specialSlotsHoles: '特殊孔槽',
      FixedmachSurface: '固定加工面',
      deleteRuleTip: '是否删除该规则',
      blankFront: '大板正面',
      blankBack: '大板反面',
      addRules: '添加规则',
      addRulesTip:
        '(当同一块小板命中多个规则时，生效序号最靠前的规则，可拖拽任意行改变规则顺序)',
      highGlossSetting: '高光板设置',
      heighGlossFront: '排版时小板高光面排在大板',
      sideSlotPut: '靠边板槽放置',
      optimize: '功能优化中',
      sysDefault: '系统默认',
      plankInSide: '大板内侧',
      plankOutSide: '大板外侧',
      specialNotWork: '该功能不对异形板件生效',
      commonSide: '共边切割',
      plankThickRange: '板件厚度小于等于',
      useCommonSide: '时采用共边切割',
      zeroTip: '（排版间隙需手动改为0，加工至已切割边时抬刀跳过该边）',
      useTip: '此功能开启后，将不支持斜下刀加工，请注意！',
      commonSpeed: '共边加速',
      highSpeed: '高速擦铣速度',
      min: '分钟',
      notWorkTip: '（当板材加工满足共边切割时，共边加速则不生效）',
      changeStartPoint: '双工位起始点切换',
      changeStartPointTip:
        '启用双工位起始点切换后，与XY轴互换功能不兼容，请谨慎使用',
      flatOffset: '机台平面偏移',
      xRowChange: 'X轴坐标基准对调',
      bigplankPointChange: '大板反面坐标基准对调',
      reverseXRowChange: 'X坐标对调',
      changebigplankPointTips:
        '启用该功能后与"XY轴互换、双工位起始点切换"功能不兼容，请谨慎使用',
      reverseYRowChange: 'Y坐标对调',
      plankMirrorTip: '在开料机上加工的板件镜像时使用此功能',
      notSameSize: '双工位台面不同尺寸',
      maxSize1: '工位一最大尺寸',
      notSameSizeTip:
        '（启用双工位台面不同尺寸后，与双工位文件合并功能不兼容，请谨慎使用）',
      maxSize2: '工位二最大尺寸',
      autoSelectTip:
        '（双工位设备两个台面尺寸不同时使用此功能将会自动选择对应工位加工大板）',
      bigFlatLimit: '大工位只加工小工位无法加工的大板：',
      surplusSetting: '余料设置',
      surplusPosition: '余料摆放位置',
      cutHoleWay: '切割打孔方式',
      knifeFlat: '对刀基准面',

      flat: '台面',
      plankFlat: '大板表面',
      freeHeightZ: '空闲时Z高度',
      relativeHeight: '相对大板上表面高度',
      cutDeepZ: '切透量Z深度',
      cutDeepH: '通孔打孔方式',
      onceThrough: '一次打穿',
      rollThrough: '翻面两次打穿',
      cutDeepS: '通槽打穿方式',
      plankCutDirection: '小板切割方向',
      sDirection: '顺时针',
      nDirection: '逆时针',
      curveReplenish: '圆弧插补指令切割弧形板',
      curveReplenishTip: '弧形板件切割出现锯齿时可选用该功能',
      noGen: '不生成G17/G18/G19面指令',
      arcInstruction: '圆弧插补指令前缀',
      example: '示例',
      arcExample: 'G17G03 X100 Y100',
      curveCutTip: '圆弧切割用I、J表示',
      coverRate: '铣槽时切割重叠率为：',
      roughTip: '刀具铣槽时槽内部有余留毛刺时使用该功能',
      clearCurve: '铣槽时未拉穿的槽自动清理圆角：',
      curveUseTip: '在铣槽时需要清理圆角选用此功能，注意该功能不对连接件生效',
      slotCutWay: '铣槽走刀方式',
      outToIn: '从外向内铣槽',
      inToOut: '从内向外铣槽',
      zShapProcess: '通槽上下Z字形铣槽',
      zShapProcessTip: '仅针对前后拉透通槽铣槽生效，刀路径重叠率默认为刀半径',
      slotWorkLimit: '(仅在铣槽走刀层数>1时生效)',
      workSpeedOp: '加工速度优化',
      obliqueCut: '切割时斜下刀',
      backOblique: '反面修边斜下刀',
      obliqueSpeed: '斜下刀速度',
      arcSpeed: '曲线加工速度',
      cuttingPreDistance: '下刀提前距离',
      backKnife: '切割收刀减速',
      openKnifeSpeed: '收刀速度',
      cutCornerSlow: '切割转角减速',
      addRow: '新增',
      bettwenDistance: '转角前后距离',
      cuttingSpeed: '切割速度',
      stepCutting: '分层切割',
      splitSlot: '槽切割两次',
      splitPlank: '小板切割两次',
      maxWH: '长或宽小于',
      areaLimit: '且面积小于',
      secondDeep: '第二次切割深度',
      splitPlankThickSetting: '开料分层切割设置',
      saveLayeredCuttingTip: '开料分层切割三次分层切割的总和必须等于板件厚度',
      saveLayeredRepeatTip:
        '开料分层切割不建议同一个材质高光属性厚度设置不同的分层数据，请删除多余数据！',
      splitPlankThick: '不同板厚分层切割',
      movePlankSetting: '防跑板设置',
      newCutSequence: '新版切割算法',
      newCutSequenceText: '默认大板中心真空吸力最强，此块区域小板最后切',
      positionTip: '点击相应区域可以进行选择',
      suctionPosition: '真空强吸力位置',
      strongArea: '强吸力区',
      minSuctionPlank: '最小可吸住板件',
      safeWidth: '宽度',
      safeHeight: '长度',
      safeTip: '吸不住板件会先切',
      scattered_layout: '小板分散排版',
      dispersePaibanTipOne:
        '启用后每张大板上会至少排一张满足最小可吸住尺寸的板件',
      dispersePaibanTipTwo: '2、注意使用后可能会增加大板量',

      totalThickLimit: '三次分层切割的总和必须等于板件厚度',
      offsetFollowStep: '修边也跟随分层切割',

      longPlankTip1:
        '1、启用该功能后必须在"开料对接-真空泵暂停"中设置真空泵暂停代码，否则无法解决长板切割弯曲的问题',
      longPlankTip2:
        '2、开启或关闭此功能后保存历史中的排版记录在下载NC时需进行重新排版',
      secondCutting: '二次切割对象',
      normalPlank: '的常规板',
      normalPlankTip:
        '常规板：柜体中横竖隔板等非柜门的板件和开料清单中直接添加的板件',
      plankLongLimit: '当长板长边超过',
      plankShortLimit: '短边超过',
      cuttingSplit: '分两次切割',
      longExpand: '首次切割放量：长边放量',
      firstExpand: '首次切割放量',
      longExpandTip: '长边放量',
      longLimit: '长边超过',
      cuttingSplitTip: '长板分两次切割条件',
      shortExpand: '短边放量',
      expandTip: '（长/短边尺寸各增加 x mm，异形长板长短边放量皆为长边放量值）',
      pumpOffWay: '真空关闭方式',
      afterOnePlank: '单块长板切割后',
      afterAllPlank: '整张大板切割后',
      numberFixed: '小数保留位数',
      bufferCode: '下刀缓冲指令设置',
      layoutGap: '排版间隙设置',
      layoutGapTip: '注意：排版间隙设置为0可能会出现排版重叠',
      wasteCutting: '废料自动截断',
      zkPointCustomize: 'Z扣斜孔加工旋转基准点自定义',
      sawSetting: '锯切设置',
      cuttingLimit: '截断尺寸大于',
      waste: '的废料',
      wasteSizeLimit: '最小尺寸不得小于100mm',
      topLeft: '左边、上边',
      topLeftD: '左上角',
      bottomLeft: '左边、下边',
      bottomLeftD: '左下角',
      topRight: '右边、上边',
      topRightD: '右上角',
      bottomRight: '右边、下边',
      bottomRightD: '右下角',
      firstTime: '第一次',
      secondTime: '第二次',
      thirdTime: '第三次',
      startCutting: '切割开始',
      thickRange: '厚度 ≥',
      longCut: '长板加工',
      edgeSetting: '修边设置',
      cuttingOrder: '切割顺序：',
      cuttingOrderTip: '此方式存在跑板风险，请谨慎使用',
      plankFirst: '先切小板',
      wasteFirst: '先切废料',
      offTip: '关闭时将跟随切割参数中修边设置',
      edgeCutDirection: '修边方向',
      onlyLong: '只修长边',
      backCutOrder: '反面修边顺序',
      backFirst: '反面最开始修边',
      backSecond: '反面孔槽加工后修边',
      frontReCut: '正面再修边',
      frontReCutTip: '（让定位气缸边更光滑，防止有痕迹）',
      useNewCut: '启用新版修边规则',
      newCutTip: '在双面加工大板的正面会多修一个修边尺寸时使用此功能',
      fixedTip: '注意：保留位数为0或1可能会存在排版重叠',
      cuttingSlow: '下料板件表面缓冲',
      holeSlow: '打孔板件表面缓冲',
      slotSlow: '拉槽/铣孔/铣槽板件表面缓冲',
      slowCode: '缓冲指令使用',
      code: '指令',
      zkRotatePoint: '旋转基准点',
      zkRotateDir: '旋转角度递增方向',
      restSizeLimit: '剩余尺寸小于',
      useSaw: '使用锯片',
      unGenSawPath: '不生成纵向锯片切割路径',
      suitMachine: '适用于全铝焊接板加工',
      plankBack: '大板后侧',
      plankLeft: '大板左侧',
      plankRight: '大板右侧',
      plankFront: '大板前侧',
      frontSide: '前侧',
      backSide: '后侧',
      rightSide: '右侧',
      leftSide: '左侧',
      singleMachineLimit: '单面加工板正面生成修边路径且先修边',
      singleMachineLimitTip: '一体机、锯片切割不生效该功能',
      setBatchPositionWaring:
        '提示：如果需要取消快速修改下刀点模式，请取消勾选右侧修改下刀点按钮',
      setBatchPosition: '批量修改下刀点',
      setBatchPositionAs: '修改下刀方向为:',
      activeBatchPosition: '已经选择{activePartCount}块小板',
    },
    labelingSettings: {
      title: '贴标设置',
      output: '输出自动贴标文件',
      specification: '贴标文件格式',
      codeFrontGenerate: '自动上料代码在贴标代码前面生成',
      templateOffline: '所用模板已下线！',
      rollLabelling: '翻面贴标设置',
      flipSetting: {
        title: '翻面设置',
        engravingFlipProcess: '启用雕刻机翻面加工贴标模式',
        flipTip: '雕刻机需要翻面加工时启用此功能',
      },
      rotateSetting: {
        title: '标签旋转设置',
        rotateTip: '根据需求开启旋转条件，如果同时开启，优先生效选择设定值',
        horizontal: '板材横边≤',
        vertical: '板材竖边≤',
        either: '板材横|竖边≤',
      },
      countertopSetting: {
        title: '台面设置',
        startPoint: '工位起始点',
        upLeft: '左上角',
        upRight: '右上角',
        downLeft: '左下角',
        downRight: '右下角',
        baseExchange: '坐标基准对调',
        exchangeX: 'x轴对调',
        exchangeY: 'y轴对调',
      },
      offset: '贴标坐标偏移',
      offsetTip: '一般贴标坐标是在小板中心，部分设备需要调整',
      label_rotate_offset: '标签旋转后坐标偏移',
      label_rotate_offset_tip: '针对标签旋转前后偏移位置相差较大时，需要调整',
      label_rotate_offset_err_tip:
        '开启标签旋转后坐标偏移后，X和Y轴坐标偏移量必填',
      imgSetting: {
        title: '贴标图片设置',
        suffix: '贴标图片后缀：',
        code: '图片编码方式：',
        codeTip:
          '注：png、jpeg会同步修改排版图编码格式，且内嵌云排版暂不支持此功能',
        imgRatio: '贴标图片分辨率:',
        slowDown: '会降低导出标签图片的效率',
        color: '真彩',
        bit: '黑白',
        resolution: '贴标图片分辨率',
        resolutionPlaceholder: '分辨率',
        labelPool: '数据标签池',
        dragTip: '可拖拽标签池内标签',
        labelImgName: '贴标图片名',
        labelImgNameErrTip: '标签图片名不能为空',
        labelTip1:
          '注意：1、自定义时“板件条码”或“标签序号”必须使用其中一项，否则会出现图片覆盖的问题；',
        labelTip2: '2、贴标机系统模板不生效此功能',
        delimiter: '分隔符',
        inputTip: '不支持输入  / : ? < > | . ” * 等字符',
        numStart: '贴标序号起始数值',
        example: '自定义贴标图片名(示例)',
        divideFolders: '标签图片根据大板分为不同文件夹',
        foldersTip1: '注意: 1、此功能不支持与“开料机文件合并”功能一起使用；',
        foldersTip2: '2、此功能暂未对内嵌云排版生效',
        stickIntoFolders: '贴标文件插入到对应大板的贴标图片文件夹中',
        stickTip1: '注意: 1、此功能需要和“下载NC顺带导出标签”功能配合使用；',
        stickTip2:
          '2、使用的贴标模板中文件生成规则需要选择“生成单个文件”此功能才生效；',
        stickTip3: '3、此功能暂未对内嵌云排版生效',
      },
      paibanImgSetting: {
        title: '排版图设置',
        reparation: '排版图序号自动补偿到',
        figure: '位',
        inputTip: '请输入1-99的数字',
        paibanImg: {
          export: '导出排版图',
          prefix: '排版图名前缀',
          size: '排版图尺寸',
          format: '排版图格式',
          directional: '排版图方向',
          vertical: '竖向',
          horizontal: '横向',
        },
      },
      autolabel: '自动贴标顺序设置',
      customTemp: '仅针对自定义模板生效',
      cuttingLabelOder: '按照开料顺序贴标',
      labelToUp: '按照排版结果从下往上贴标',
      labelToDown: '按照排版结果从上往下贴标',
      labelDodge: '贴标自动避让',
      settingValue: '选择设定值',
      enabelTip: '开启后，当小板满足下列设定值，则标签旋转',
      fixedHori: '选择标签横边与小板固定边平行',
      fixedHoriTip:
        '仅对无纹理小板生效，开启后，贴标时，标签会旋转到标签横边和小板固定边平行',
      tagHori: '标签横边',
    },
    drillSettings: {
      title: '排钻设置',
      enable: '启用排钻包',
      toolTable: {
        use: '启用',
        number2: '工位2排钻编号',
        number: '排钻编号',
        diameter: '钻头直径',
        xOffset: 'X偏移',
        yOffset: 'Y偏移',
        moveZF: 'Z轴快速移动速度',
        feedRate: 'Z轴加工速度',
        moveF: 'XY轴快速移动速度',
        benchmarkDrill: '基准钻',
        addKnife: '添加刀具',
        deleteKnife: '删除刀具',
      },
      prefix: '排钻前缀',
      suffix: '排钻后缀',
      startCode: '排钻启动代码',
      endCode: '排钻结束代码',
      combineKnife: '排钻组合下刀',
      combineTable: {
        prefix: '排钻编号前缀',
        suffix: '排钻编号后缀',
        mainHole: '主孔',
        extraHole: '从孔',
        notes: '组合注释',
        firstUseNotes: '首次启用注释',
        addExtraHole: '添加从孔',
        deleteExtraHole: '删除从孔',
        example: '样例',
      },
      processSetting: {
        title: '加工设置',
        mode: '排钻加工模式',
        processTip: '选用此方式后就不生效排钻组合下刀功能',
        recentHole: '就近打孔（快速换刀）',
        groupHole: '按类打孔（完成一类孔再换刀）',
        drill_benchmark: '组合下刀基准',
        drill_benchmark_Tip: '（注意：选用此方式后就不生效排钻组合下刀功能）',
        mainHole: '以主孔',
        secondaryHole: '以从孔（尾孔）',
      },
      changeBenchDrillTip: '请输入排钻编号再进行此选择',
    },
    NCExportSettings: {
      title: 'NC导出设置',
      tab1: '开料机文件名设置',
      tab2: '开料机文件名合并',
      tab3: '开料机文件中注释',
      tab4: '开料机文件中类型',
      tab5: '开料机文件夹',
      tab6: '加工指令设置',
      tab7: '文件格式设置',
      fileNameSettings: {
        twoStationAutoSort: '双工位自动排序',
        reparation: '大板序号位数补偿到',
        figure: '位',
        labelerBegin: '标签机开头',
        machineCenter: '加工中心开头',
        front: '正面:',
        back: '反面:',
        customizeName: '自定义文件名(格式)：',
        example: '自定义文件名(示例)：',
        emptyTip: '正、反面文件标识不能同时为空',
        labelPool: '数据标签池',
        dragTip: '可拖拽或点击复制标签池内标签到输入框内',
      },
      fileMerge: {
        engravingMerge: '开料机文件合并',
        zfMerge: '正反面合并',
        singleMerge: '单工位合并',
        doubleMerge: '双工位合并',
        fEndCode: '反面结束代码',
        zBeginCode: '正面开始代码',
        displayfbInfo: '文件名显示正反面信息',
        fBeginCode: '反面开始代码',
        zEndCode: '正面结束代码',
      },
      marginalNotes: {
        addPlankInfo: '开料文件开头加入当前大板件信息：',
        plankInfo: '大板信息：',
        addStationCodeFirst: '开料文件开头加入工位代码：',
        addStationCodeSecond: '开料文件换刀前加入工位代码：',
      },
      fileType: {
        zAddtion: 'Z坐标补充：',
        addSemicolon: '每行NC末尾加分号：',
        semicolonTip: '勾选后牛角拉手的加工文件也会生效',
        fileName: '文件名',
        defaultName: '默认文件名',
        dedicatedName: '星辉设备专用文件名',
        xhNotes: '星辉E2-9注释',
        format: '输出雕刻机文件格式',
        example: '文件样例: NC文件',
        stillGenerate: '反面不加工时仍然生成仅含反面结束代码的反面NC文件',
        generateTip: '该功能不支持与文件合并功能一起使用',
        fileNameErrTip: '文件名不能包含下列任何字符: / : * ? "',
      },
      categorize: '根据大板颜色、厚度、材质归为不同文件夹(贴标文件一起)：',
      simplifyG: '加工G指令简写',
      ZToW: '代码从Z轴换为W轴',
      knives: '排钻刀具',
      lineBreakSetting: '换行符格式设置',
      codeFormatSetting: '文件编码格式设置',
      utf8bom: '带bom的UTF-8',
      oneCodeSetting: '板件条码设置',
      simplifyOneCode: '生成简易格式标签条码',
      codeLocation: '代码位置设置',
      generateLocation: '工位结束代码在反面结束代码前面生成',
      emergencySetting: '应急设置',
      show: '显示应急设置',
      emergencyCode: '应急码',
      params: '参数',
      emergencyTip: '可在售后人员配合下临时实现一些小众功能',
      holeSetting: '打孔文件设置',
      holeFileCategorize: '打孔文件按不同订单分文件夹单独放置',
      holeTip: '对内嵌柜柜客户端的云排版不生效',
      specialCodeErr: '不支持输入特殊字符!',
      copySuccess: '复制成功!',
      copyErr: '复制失败, 请尝试拖拽标签!',
      lengthLimit: '只支持输入15位!',
      plankInfo: '板件信息',
      otherInfo: '其他信息',
      uniqueBarCode: '板件条码唯一',
      machineOrder: '下料文件名生成加工序号',
      folderSetting: '同尺寸大板下料文件放在同一个文件夹',
      labelImageINCFolder: '贴标图片跟随贴标文件放在NC文件夹里',
      custom: '定制',
      folderSettingTip: '注： 内嵌排版暂不支持此功能',
      settingMoveTip: '原"{func}"功能已迁移到"{module}"页面中！',
      fileManagerFunction: '打孔文件按不同订单文件分文件夹单独放置',
      gotoSetting: '前往设置',
    },
    aioMachineSetting: {
      openFunction: '对接需开启以下功能',
      openCarving: '开启雕刻机',
      xyReverseFunction: 'XY轴互换功能',
      carvingStartSet: '雕刻机工位起始点设置为',
      rightTop: '右上角',
      carvingEdgeSet: '雕刻机正面再修边值调整为',
      finalEdgeSame: '与总修边值相同',
      beginCode: '程序开始代码',
      endCode: '程序结束代码',
      drillHole: '打孔',
      pullTrough: '拉槽',
      showDataTemp: '显示数据样例',
      dataTemp: '数据样例',
      title: '一体机设置',
      outputFile: '输出一体机加工文件',
      outputFileTips:
        '部分品牌的一体机，与XY互换、文件合并、不打穿异形孔不兼容，请谨慎使用',
      fileType: '文件格式',
      select: '请选择',
      diameter: '修边刀具直径',
      jidongTip: '使用极东一体机请将雕刻机工位起始点设置为右上角，并开启A功能',
      skill: '功能',
      clampHandLength: '夹手长度(L)',
      clampHandWidth: '夹手宽度(W)',
      clampHandMargin1: '夹手1距边(H1)',
      clampHandMargin2: '夹手2距边(H2)',
      duchClampHand: '孔槽避让夹手位置',
      clampHandDirection: '夹手方向',
      left: '左侧',
      right: '右侧',
      top: '上侧',
      bottom: '下侧',
    },
    oneDragTwo: {
      title: '一拖二雕刻机',
      outputMachineFile: '输出一拖二雕刻机加工文件',
      outputMachineFileTips:
        '启用一拖二雕刻机后与开料机文件合并功能不兼容，请谨慎使用',
      fileType: '文件格式',
      select: '请选择',
      machineStart: '工位起始点',
      positionClose: '关闭时将跟随切割参数中工位起始点设置',
      engravingPlus: '速雕雕刻机',
      a: '',
    },
    sawEngraving: {
      title: '锯切雕刻机',
      preLayNav: '锯切雕刻机设置',
      preLayKnifeInput: '锯片横切刀直径',
      enableSaw: '启用锯切雕刻机',
      cutSet: '切割设置',
      sawUsing: '锯片用途',
      trim: '修边',
      cutBlank: '切割下料',
      sawUseCond: '锯片使用条件',
      hasUsing: '有可用路径便使用',
      onlyUse: '时才使用',
      cutMethod: '切割方式',
      oneKnifePull: '一刀拉穿大板',
      onlyOneKnifePull: '时才直接一刀拉穿',
      enableSawThick: '启用锯片厚度排版',
      edgedDir: '已封好不再切的大板边',
      top: '上边',
      bottom: '下边',
      left: '左边',
      right: '右边',
      dockTip:
        '对接焊接板加工需要矩形板四边封边和总修边一样，且手动操作成单列排版',
      tempPaiabanTip:
        '启用锯片切割后不支持直接在排版添加异形板件，如需添加请前往开料清单添加并排版！',
    },
    firstSave:
      '检测到用户是第一次使用云排版，保存设置后请注意文件中的修边方向是否合理，避免不必要的损失',
    // 雕刻机-开料对接-刀库
    knives: {
      xOffset1: 'X坐标偏移',
      yOffset1: 'Y坐标偏移',
      zOffset1: 'Z坐标偏移',
      insert: '插入行',
      delete: '删除行',
      tip: '（注：完成刀库信息的修改或设置的调整后，在柜柜排版生产的用户需要重启柜柜客户端，以应用最新的变更！）',
      number: '编号',
      name: '名称',
      diameter: '刀直径Φ',
      cutting: '开料',
      use: '用途',
      moveZF: 'Z轴快速移动速度',
      cutZF: 'Z轴加工速度',
      moveF: 'XY轴快速移动速度',
      cutF: 'XY轴加工速度',
      startCode: '换刀开始代码',
      stopCode: '换刀结束代码',
      startCode2: '工位2换刀开始代码',
      stopCode2: '工位2换刀结束代码',
      preStartCode: '预启动代码',
      punchHoles: '打孔',
      pullSlots: '拉槽',
      millHoles: '铣孔',
      millSlots: '铣槽',
      sawBlade: '锯片',
      doubleSaw: '启用双工位不同换刀开始代码',
      singleStation: '单工位',
      doubleStation: '双工位',
      sawBladeTip: '该功能不对一拖二雕刻机和一体机生效',
      serialNum: '编号',
      sawWidth: '刀宽度',
      sawType: '锯片类型',
      cutDirection: '切割方向',
      moveSpeed: '空走速度',
      cutSpeed: '加工速度',
      endCode: '换刀结束代码',
      sawDownCode: '下刀代码',
      sawUpCode: '收刀代码',
      abscissa: '横坐标前缀',
      ordinate: '纵坐标前缀',
      sawHor: '横切锯片',
      sawVer: '纵切锯片',
      towardsZero: '向0点坐标切割',
      awayFromZero: '远离0点坐标切割',
      diameterDesc: '刀直径Φ',
      xOffset: '横向坐标偏移',
      yOffset: '纵向坐标偏移',
      zOffset: '升降坐标偏移',
      offsetX: '偏移X',
      offsetY: '偏移Y',
      offsetZ: '偏移Z',
      offsetNo: '偏移号',
      knifeWidth: '刀宽',
      drill: '下刀',
      cut: '加工',
      speed: '转速',
      type: '类型',
      millHoleSlot: '铣孔/槽',
      rapidFeedSpeed: '快速进给速度',
    },
    other: {
      title: '其他',
      tip: '（注意：单工位设备需要将工位1和工位2都填上相同的自动上料代码）',
    },
  },
  // 侧孔机
  sideHoleMachine: {
    coordSwitch: '坐标基准对调',
    updownCoordSwitch: '升降坐标基准对调',
    knifes: {
      number: '编号',
      diameter: '刀宽',
      type: '类型',
      moveSpeed: '快速进给速度',
      doSpeed: '加工速度',
      startCode: '换刀开始代码',
      endCode: '换刀结束代码',
    },
    ymSetting: '易木连接设置',
    flanking: '侧面加工',
    frontProcess: '正面加工',
    oldSetting: '旧版设置',
    equipParamsSetting: '设备参数设置',
    holeSetting: '打孔设置',
    raminoSetting: '拉米诺连接设置',
    mortiseTenonSetting: '榫卯连接设置',
    straight: '直款',
    eccentric: '偏心款',
    coordinateValue: '坐标值',
    codeFormat: '文件编码格式',
    suffixSetting: '文件后缀设置',
    insertRow: '插入行',
    deleteRow: '删除行',
    equipmentParams: {
      equipPauseCode: '设备暂停代码',
      wiggleCode: '摆动代码',
      equipStartCode: '设备启动代码',
      flipPauseCode: '翻面暂停代码',
      equipEndCode: '设备结束代码',
      equipStartCodePlaceholder: '输入设备启动代码',
      flipPauseCodePlaceholder: '输入翻面暂停代码',
      equipEndCodeTip: '输入设备结束代码',
      sideChangeSetting: '侧面换面设置',
      workTip: '默认从左侧①开始沿逆时针方向加工',
      frontTurnCode: '上面开始代码',
      frontTurnEndCode: '上面结束代码',
      frontTurnCodePlaceholder: '输入上面开始代码',
      frontTurnEndCodePlaceholder: '输入上面结束代码',
      leftTurnCode: '左面开始代码',
      leftTurnEndCode: '左面结束代码',
      leftTurnCodePlaceholder: '输入左面开始代码',
      leftTurnEndCodePlaceholder: '输入左面结束代码',
      downTurnCode: '下面开始代码',
      downTurnEndCode: '下面结束代码',
      downTurnCodePlaceholder: '输入下面开始代码',
      downTurnEndCodePlaceholder: '输入下面结束代码',
      rightTurnCode: '右面开始代码',
      rightTurnEndCode: '右面结束代码',
      rightTurnCodePlaceholder: '输入右面开始代码',
      rightTurnEndCodePlaceholder: '输入右面结束代码',
      topFlipCode: '翻面代码 - 上',
      downFlipCode: '翻面代码 - 下',
      leftFlipCode: '翻面代码 - 左',
      rightFlipCode: '翻面代码 - 右',
      cylinderOutCode: '气缸推出代码',
      cylinderbackCode: '气缸收回代码',
      cylinderbackDirective: '气缸收回指令',
      moveDirective: '移动指令',
      moveAxisCode: '移动轴',
      pushAxisCode: '气缸推动轴',
      swingAxisCode: '摆动轴',
      moveCode: '移动代码',
      punchCode: '加工代码',
      free: '空走',
      punchPart: '加工部分',
      cutFaceCode: '加工平面代码',
      horVerCutFace: '横向纵向平面',
      horCutFace: '横向升降平面',
      verCutFace: '纵向升降平面',
    },
    tagPool: '数据标签池',
    dragTip: '可拖拽标签池内标签',
    knives: '加工刀具：',
    directives: '加工指令：',
    slot: '直槽加工',
    cirWork: '圆弧加工',
    cirInter: '圆弧插补',
    cirOption1: '顺时针：G03 逆时针：G02',
    cirOption2: '顺时针：G02 逆时针：G03',
    coordinate: {
      xPointSymbol: '横向坐标：',
      yPointSymbol: '纵向坐标：',
      zPointSymbol: '升降坐标：',
      upright: '正值',
      negative: '负值',
    },
    encodeFormat: {
      format: '文件编码格式：',
      wrap: '换行符格式设置：',
      formatOption1: 'UTF-8格式',
      formatOption2: 'ANSI格式',
    },
    suffix: '文件后缀设定：',
    enableFP: '启用正面加工',
    slotSetting: '拉槽设置',
    takeUpDirective: '收刀指令',
    workSeparately: '正/反面通槽分两段加工',
    secondSlotLong: '第二段槽长度',
    enableOld: '启用旧版侧孔机设置',
    default: '默认设置',
    customizable1: '自定义一',
    customizable2: '自定义二',
    customizable3: '自定义三',
    customizable4: '自定义四',
    knifeSetting: '刀具设置',
    soltTip: '(包括铣孔/槽)',
    specialSetting: '特殊设置',
    ramino: '拉米诺',
    sun: '榫卯',
    please: '敬请期待',
    designatedKnives: '指定用刀',
    designated: '指定',
    workOrder: '加工命令',
    other: '其他',
    nameSetting: '后缀名设置',
    repeatErr: '编号重复，请重新设置',
  },
  // 电子锯
  electronicSaw: {
    importMaterial: '导入原料单',
    formSetting: '电子锯表单设置',
    fileFormat: '输出开料清单文件格式：',
    isSmallFile: '导出小板图片文件：',
    fileGroupRule: '电子锯文件根据颜色、材质、板厚分不同表格：',
    roomsByCategory: '电子锯文件根据房间分不同表格：',
    delimiter: '分隔符：',
    fileName: '开料清单文件名：',
    fineNameTip: '开料清单文件名不能为空！',
    fileNamePlaceholder: '自定义开料清单',
    outputFileEncoding: '输出清单编码格式：',
    utf8: 'UTF-8带BOM',
    cellTab: '生成单元格制表符：',
    texture: '纹理表示：',
    special: '异形表示：',
    notSpecial: '不是异形',
    genSimpleLabelBarcode: '生成简易格式标签条码',
    uniqueBarCode: '板件条码唯一',
    uniqueBarCodeTip: '开启后生成简易格式标签条码功能不生效',
    customeForm: '自定义表单',
    tagPool: '数据标签池',
    tagPoolTips: '可拖拽或点击复制标签池内标签',
    displayHead: '不显示表头',
    addTableHead: '添加表头',
    deleteTableHead: '删除表头',
    generateExample: '生成输出表单样例',
    importExample: '导入表单样例',
    importExampleTip: '导入的表单样例，仅供自定义电子锯参考',
    importExampleErrTip: '还没有导入原料单，请导入原料单',
    outputExample: '输出表单样例',
    outputExampleTip: '自定义表单的输出样例',
    outputExampleErrTip: '还没有生成输出表单样例，请生成输出表单样例',
    defaultTableHead: '默认表头',
    deleteHeadErrTip: '没有可以删除的表头!',
  },
  // 锁定弹窗组件
  lockModal: {
    passwordInput: '请输入密码',
    lockPasswordInput: '请输入解锁密码',
    passwordInputPlaceholder: '请输入四位数密码',
    editPassword: '修改密码',
    confirmPassword: '确认密码',
    setLockPwd: '设置解锁密码',
    pwdFormatErr: '密码格式错误',
    sendMessageTo: '短信验证码将发送至',
    captchaPlaceholder: '请输入验证码',
    sendCaptcha: '发送验证码',
    sendSeccess: '短信发送成功',
    sendFail: '短信发送失败，请稍后重试',
    updateSeccess: '密码设置成功',
    editSuccess: '密码修改成功',
    lockSeccess: '解锁成功',
    lock: '解锁',
    lockSeccessTip: '锁定成功',
    inputErrLimit: '当前错误次数已达限制，请重新设置解锁密码！',
  },
  // 五六面钻
  dock56Drill: {
    equipType: '设备类型',
    equipment: '设备',
    fiveDock: '五面钻',
    sixDock: '六面钻',
    fileType: '生成文件类型',
    templateOffline: '所用模板已下线！',
    showExample: '显示数据样例',
    conNoticeTip: '对接注意事项：',
    conNoticeTip1:
      '1、启用了“XY轴互换”的雕刻机需要使用正反面互换的五/六面钻专用模板',
    conNoticeTip2:
      '2、要在五六面钻上显示或加工板件异形部分需要在模板中配置异形部分的数据',
    conNoticeTip3:
      '3、首次使用五六面钻或更换模板后请<span style="color:#f00">进行柜体试生产</span>，在确认无误后再进行常规生产',
    conNoticeTip4:
      '4、试生产时请仔细检测正反面孔槽的大小、深度、所在面、以及所在位置是否正确，<span style="color:#f00" >避免出现柜体镜像</span>的问题',
    example: '数据样例',
    commonSetting: '通用参数设置',
    throughHoleSetting: '通孔分双面打通',
    throughSlotSetting: '通槽分双面打通',
    nonHolesNotFiles: '矩形板没有孔槽不输出文件：',
    frontFileIdentifier: '正面文件标识：',
    oppositeFileIdentifier: '反面文件标识：',
    oppositeFileIdentifierTips: '生成方式为：原13位板件编码+标识字符',
    arcDescription: '圆弧描述方式',
    linePoints: '线段成型描述',
    arcCommand: '圆弧指令描述',
    slotWidthSplit: '宽槽拆解为多条窄槽加工：',
    slotSplitWidth: '拆解后单条槽宽度：',
    mm: '注：拆解对象为普通矩形槽',
    isNotMergeSlot: '相邻的等宽等深槽拉通处理：',
    slotPunchTip: '此功能仅对使用自定义模板的背板槽和抽屉底板槽生效',
    sizeRange: '相邻尺寸小于等于',
    enableWorningTip: '此功能与前封边摆放方向不能同时开启',
    longDirection: '板件长边摆放方向',
    horizontal: '横向',
    vertical: '纵向',
    slotDirection: '五/六面钻槽或异形放置方向',
    default: '系统默认',
    frontLeft: '前和左',
    frontRight: '前和右',
    backLeft: '后和左',
    backRight: '后和右',
    frontDirection: '前封边摆放方向',
    enableTip: '注：仅对云排版生效',
    fixedOn: '固定在：',
    front: '前边',
    back: '后边',
    left: '左边',
    right: '右边',
    coordinateValue: '坐标值保留位数：',
    decimalPlaces: '位',
    specialParameter: '特殊参数设置',
    output5FDrillSlotKnifeCode: 'XML2铣槽刀具编号',
    output5FDrillShapeKnifeCode: 'XML2铣型刀具编号',
    plankTexture: 'XML2板件纹理',
    offset: 'XML2铣型刀具补偿',
    mprConHoleDir: '五/六面钻MPR文件侧面孔Z坐标基准面',
    plankTop: '板件上表面',
    plankBottom: '板件下表面',
    codeInfo: 'ban格式文件包含Code信息：',
    bomFile: '输出bom格式文件：',
    plankHeight: '板件高度描述：',
    plankNum: '板号描述：',
    backZpoint: '反面槽Z坐标：',
    plankThick: '板厚',
    slotDeep: '槽深',
    outputMprFile: '文件类型',
    oldMPR: '旧版MPR',
    newMPR: '新版MPR',
    mprFileZside: '五/六面钻MPR文件侧面孔Z坐标基准面',
    fileTypeOffline: '当前选择的五六面钻文件类型已下线, 请确认!',
    permissionErr: '身份信息已丢失, 请重新从柜柜中打开此页面!',
    banFileSetting: 'ban格式文件设置',
    banFileCodeInfo: 'ban格式文件包含Code信息：',
    huahuaLine: '桦桦自动线',
  },
  // PTP
  PTP: {
    title: 'PTP',
    outputFileFormat: '输出文件格式',
    outputDefaultFileFormat: '输出默认格式文件',
    outputMPRFormat: '输出MPR格式文件',
    fileFormatSettings: '文件格式设置',
    wrapSettings: '换行符格式设置：',
    enCodeSettings: '文件编码格式设置：',
    utf8bom: '带bom的UTF-8',
    partCoordinate: '小板坐标',
    xPointSymbol: '横向坐标：',
    yPointSymbol: '纵向坐标：',
    zPointSymbol: '升降坐标：',
    upRight: '正值',
    negative: '负值',
    plankMirroring: '板件镜像',
    plankMirrorSetting: '板件镜像设置：',
    xAxis: 'X轴镜像',
    yAxis: 'Y轴镜像',
    mirrorTip: '（板件镜像时使用此功能）',
    knifeTable: {
      type: '类型',
      diameter: '直径Φ',
      offsetX: '偏移X',
      offsetY: '偏移Y',
      offsetZ: '偏移Z',
      offsetNo: '偏移号',
      drillVelocity: '下刀[F]mm/min',
      cutVelocity: '加工[F]mm/min',
      rotationSeep: '转速[S]r/min',
      insertRow: '插入行',
      deleteRow: '删除行',
    },
    labelPool: '数据标签池',
    dragTip: '可拖拽或点击复制标签池内标签到输入框内',
    procedure: '程序',
    beginCode: '程序开始代码：',
    endCode: '程序结束代码：',
    zzdKnifeBeginCode: '主轴刀换刀开始代码：',
    spindleCutter: '主轴刀',
    zzdKnifeEndCode: '主轴刀换刀结束代码：',
    verticalDrill: '垂直钻',
    czzBeginCode: '垂直钻启动代码：',
    czzEndCode: '垂直钻结束代码：',
    czzKnifeBeginCode: '垂直钻换刀开始代码：',
    czzKnifeEndCode: '垂直钻换刀结束代码：',
    sidetrack: '侧钻',
    czBeginCode: '侧钻启动代码：',
    czEndCode: '侧钻结束代码：',
    czKnifeBeginCode: '侧钻换刀开始代码：',
    czKnifeEndCode: '侧钻换刀结束代码：',
    pauseCode: '暂停代码：',
    zSafeHeight: 'Z轴安全高度',
    auxiliaryFunction: '辅助功能',
    xhDedicatedInfo: '生成星辉设备专用信息',
    xhCheckBox: '数据：',
    outputSettings: '输出设置：',
    partExegesis: '文件中生成小板尺寸注释',
    knifeNo: '刀具编号',
    specailKnifeNo: '加工异形刀具编号：',
    slotKnifeNo: '开槽刀具编号：',
    fileNamePlaceholder: '自定义开料清单',
    slotDrill: '拉直器刀',
  },
  // 玻璃切割机
  glassEquip: {
    title: '玻璃切割机',
    fileType: '加工文件类型：',
    sizeReparation: '板材尺寸补偿：',
    cutPreSpace: '板件切割预留间距：',
    divideIntoFolders: '根据玻璃颜色、材质、厚度归为不同文件夹：',
  },
  translate: {
    search: '请输入中文或者官方翻译寻找词条',
    en: '英文',
    kz: '哈萨克语',
    vi: '越南语',
    customTranslate: '点击可添加自定义翻译',
    customText: '自定义翻译',
    text: '中文',
    targetLang: '官方翻译',
  },
  // 预排版设置
  preLayoutSetting: {
    title: '预排版设置',
    titleTip: '可以先预设排版参数，先排版，后选生产线',
    settingTip:
      '在生产线内设置的参数需要和以下预排版参数一致才能输出生产文件，仅对开料机生效',
    emptyKnife: '刀具未填写',
    noModify: '设置没有修改，无需保存',
    reSet: '复原',
    gayPosition: '灰色方块可切换余料摆放位置',
    surplusPosition: '为当前余料摆放位置',
    click: '点击',
    grayStart: '灰色方块可切换工位起始点',
    currentStart: '为当前工位起始点',
    holeSlotSetting: '拉直器、孔槽加工设置',
    knifeDiameter: '开料刀直径',
    currentVersion: '当前版本',
    on: '开启',
    off: '关闭',
    firstShort: '首次切割-短边放量',
    firstLong: '首次切割-长边放量',
    cuttingSplitLong: '长板分两次切割-长边超过',
    cuttingSplitShort: '长板分两次切割-短边超过',
    plankThick: '常规板厚度 ≥ ',
    normalPlank: '常规板',
    confilictTip: '存在设置冲突项：{confilictTotal}个',
    selectLine: '选择生产线',
    selectLinePlaceholder: '请选择生产线',
    lineConfilictTip: '选择生产线和{line}存在冲突',
    preWay: '预排版方案',
    paibanErrTip: '长板加工的【长、短边放量】有变化时，可能导致排版失败',
    paibanTip: '本次排版存在大板材质指定下料刀，需要重新排版',
    reLayout: '以当前生产线重新排版',
    fetchErr: '获取预排版设置失败',
    resetSuccess: '还原成功',
    resetConfirm: '确定复原到历史版本？',
    prolineName: '生产线名称',
    layoutGap: '排版间隙',
    generalThickness: '二次切割对象-常规板厚度',
    cutGeneralPlank: '二次切割对象-常规板',
    cutSingleDoor: '二次切割对象-门板',
    changeProline: '切换生产线',
    backTip: '请返回全局排版选择生产线',
    preLayout: '按预排版方案排版',
    preWayEmpty: '(空)预排版方案',
    specialConfilictTip: '圆弧铣底工艺影响了排版结果，需要重新排版',
  },
  lunbo: {
    tips: '操作小技巧',
    introduction: '功能介绍',
    qtDownloadText:
      '温馨提示：如果出现下载文件不全的情况，请前往云排版网页端进行下载！',
  },
} as const
